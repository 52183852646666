import {getCookie} from "../utils/cookies";
import {
    ACCOUNT_SWITCH_USER_VIEW,
    SUBSCRIPTION_CHECK_SUBSCRIPTION_TYPE,
    SUBSCRIPTION_CHECK_WHITELISTED_API,
    tokenKey
} from "../utils/constants";
import axios from "axios";
import {Service} from 'axios-middleware';
import {appendFilterToExistingUrl, datediff, parseDate} from "../utils/common";
import {matchUrlPattern} from "../utils/util";
import {ALLOW_WHEN_IN_USER_VIEW, NON_USER_FILTER_WHITELIST} from "../routes/Filters";
import {routes} from "../routes/UrlRoutes";
import {SetClientLayoutOrg} from "../redux/Organization/organizationReducer";

// export const base_url = "http://127.0.0.1:8000/api/v1";
// export const base_url = "/api/v1";
export const base_url = `${process.env.REACT_APP_BASE_SERVER_URL}${process.env.REACT_APP_BASE_SERVER_URL_VERSION}`;
const service = new Service(axios);

let latestView = null
let profileData = null
export const apiServiceVisionMiddleware = ({getState}) => (next) => (action) => {
    const {profile} = getState()
    latestView = profile?.viewingAs
    profileData = profile
    return next(action)
}

let localDispatch
export const setDispatch = (dispatch) => {
    localDispatch = dispatch;
};

export const isSubscriptionActive = (callUrl) => {
    const subscriptionMapping = profileData.profile.data?.userassignedsubscriptionmapping
    let leftDaysFromToday = 0;
    if (subscriptionMapping?.user_owned_subscription_mapping) {
        leftDaysFromToday = datediff(
            new Date(),
            parseDate(subscriptionMapping?.user_owned_subscription_mapping["end_date"])
        );
    }
    let allow = true;
    if (SUBSCRIPTION_CHECK_SUBSCRIPTION_TYPE.includes(profileData.profile?.data?.usersubscriptionmapping?.subscription?.name) && !SUBSCRIPTION_CHECK_WHITELISTED_API.includes(callUrl.split("?")[0]) && leftDaysFromToday < process.env.REACT_APP_SUBSCRIPTION_GRACE_DAY) {
        allow = false
    }
    return allow
};

export const isBlockAllowed = (config) => {
    const {url: _url} = config
    if (latestView?.data?.id === ACCOUNT_SWITCH_USER_VIEW) {
        if (config.method !== "get" && !ALLOW_WHEN_IN_USER_VIEW.some(i => matchUrlPattern(_url, i))) {
            return true
        }
    }
    return false
}


service.register({
    onRequest(config) {
        const {url: _url, headers} = config

        const {Authorization, ...other} = headers


        if (!isSubscriptionActive(_url)) {
            throw new Error("No valid subscription");
        }
        if (isBlockAllowed(config)) {
            throw new Error("You can't do the functionality in User view");
        }

        if (latestView.data?.id === ACCOUNT_SWITCH_USER_VIEW && latestView.data?.userId && config.method === "get" && !NON_USER_FILTER_WHITELIST.some(i => matchUrlPattern(_url, i))) {
            let newUrl = appendFilterToExistingUrl(_url, {filter_users: latestView.data?.userId});
            return {...config, url: newUrl}
        } else {
            if (profileData.isClientLayout) {
                return {...config, headers: {...other}};
            } else {
                return {...config, headers: {...other, Authorization: Authorization}};
            }
        }
    },
    onResponse(response) {
        let responseData;
        try {
            if (typeof response.data === 'object') {
                responseData = response.data;
            } else if (typeof response.data === 'string') {
                responseData = JSON.parse(response.data);
            } else {
                return response;
            }
            if (responseData?.data?.organization) {
                localDispatch(SetClientLayoutOrg(responseData.data.organization))
            }

        } catch (error) {
            return response;
        }
        return response;
    }

});

function matchPath(urlToCheck, routes) {
    for (const route of routes) {
        if (route.regex) {
            if (urlToCheck.match(route.regex)) {
                return true;
            }
        } else {
            if (urlToCheck === route.path) {
                return true;
            }
        }
    }
    return false;
}

const apiService = () => {
    const defaultOptions = {
        baseURL: base_url,
        headers: {
            // "Access-Control-Expose-Headers": '*',
            "Content-Type": "application/json",
            // 'Access-Control-Allow-Origin': 'https://144.126.255.85'
        },
    };
    let instance = axios.create(defaultOptions);
    instance.interceptors.request.use(function (config) {
        if (getCookie(tokenKey) !== null && !matchPath(window.location.pathname, [...routes.client, ...routes.public])) {
            config.headers.Authorization = `Bearer ${getCookie(tokenKey)}`;
        }
        return config;
    });
    return instance;
};

export default apiService();


export const getWebsocketUrl = (clientId) => {
    let baseUrl = process.env.REACT_APP_WEBSOCKET_URL
    let token = getCookie(tokenKey)
    return `${baseUrl}?token=${token}&client=${clientId}`
}
