/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 22/07/22
 * Time: 3:36 PM
 * Project: W-CRM
 */
import React, {useEffect, useState} from "react";
import "./style.css";
import Tooltip from "../Tooltip/Tooltip";
import Button from "../Button/Button";
import DotsVertical from "../../icon/DotsVertical";
import {getUUID} from "../../utils/util";

export default function MenuHolder({
                                       menuItems,
                                       isRes = true,
                                       isGrid = false,
                                       showTooltip = true
                                   }) {
    const instanceId = getUUID();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuId, setMenuId] = useState(getUUID());
    useEffect(() => {
        window.addEventListener("click", function (e) {
            try {
                if (!document.getElementById(menuId).contains(e.target)) {
                    setMenuOpen(false);
                }
            } catch (e) {
            }
        });
    }, [menuId, menuItems]);

    return (
        <>
            {isRes ? (
                <>
                    <div className={"menu-items-holder-big-screen"}>
                        {menuItems.map((item) => {
                            if (item.isEnabled) {
                                return (
                                    <Tooltip
                                        toolTip={showTooltip ? item.name : null}
                                        key={getUUID()}>
                                        <Button
                                            className="ml-05"
                                            type="link"
                                            size="large-square"
                                            disabled={item.isDisabled}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                item.action();
                                            }}
                                        >
                                            {item.icon}
                                        </Button>
                                    </Tooltip>
                                );
                            }
                        })}
                    </div>

                    <div className={"menu-items-holder-mobile-screen"} id={menuId}>
                        <div className={"menu-items-holder-mobile-screen-hamburger"}>
                            <Button
                                className="ml-05"
                                type="link"
                                size="large-square"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setMenuOpen((old) => !old);
                                }}
                            >
                                <DotsVertical strokeWidth={2} className="w-2"/>
                            </Button>
                        </div>
                        <div
                            className={
                                "menu-items-holder-mobile-screen-list-holder shadow-wx-lg bg-white"
                            }
                        >
                            {menuOpen && (
                                <ul>
                                    {menuItems.map((item) => {
                                        if (item.isEnabled) {
                                            return (
                                                <li
                                                    className={
                                                        `menu-items-holder-mobile-screen-list-item-slot ${item.isDisabled ? 'disabled-calendar-action' : ''}`
                                                    }
                                                    key={`m-${item.name}-${instanceId}`}
                                                    onClick={(event) => {
                                                        if (!item.isDisabled) {
                                                            event.stopPropagation();
                                                            item.action();
                                                        }
                                                    }}
                                                >
                                                    {item.icon} {item.name}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className={"menu-items-holder-non-res"}>
                    {menuItems.map((item) => {
                        return (
                            <Tooltip
                                toolTip={showTooltip ? item.name : null}
                                key={`m-t-${item.name}-${instanceId}`}
                            >
                                <Button
                                    className="ml-05"
                                    type="link"
                                    size="large-square"
                                    disabled={item.isDisabled}
                                    onClick={(event) => {
                                        if (!item.isDisabled) {
                                            event.stopPropagation();
                                            item.action();
                                        }
                                    }}
                                >
                                    {item.icon}
                                </Button>
                            </Tooltip>
                        );
                    })}
                </div>
            )}
        </>
    );
}
