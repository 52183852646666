/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 6/6/2022
 * Time: 11:42 AM
 * Project: w_crm
 */
import {createSlice, current} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {endPoints} from "../../api/endpoints";
import {formatAxiosErrorMessage, qargsToQstring, updateState,} from "../../utils/common";
// import { Toast } from "../../component/Toast/Toast";

const DocumentInitialState = {
    upload: {
        isLoading: false,
        data: null,
        error: null,
    },
    retrieved: {
        isLoading: false,
        data: [],
        error: null,
    },
    rename: {
        isLoading: false,
        data: null,
        error: null,
    },
    delete: {
        isLoading: false,
        data: null,
        error: null,
    },
    email: {
        isLoading: false,
        data: null,
        error: null,
    },
    fileRequest: {
        isLoading: false,
        data: null,
        error: null,
    },
};

export const DocumentSlice = createSlice({
    name: "document",
    initialState: {
        ...DocumentInitialState,
    },
    reducers: {
        documentUploadRequesting: (state, action) => {
            state.upload.isLoading = true;
        },
        documentUploadSuccess: (state, action) => {
            state.upload.isLoading = false;
            state.upload.error = null;
            state.upload.data = action.payload.data;
        },
        documentUploadError: (state, action) => {
            state.upload.isLoading = false;
            state.upload.error = action.payload;
        },
        documentUploadReset: (state, action) => {
            state.upload = DocumentInitialState.upload;
        },
        documentRetrievedRequesting: (state, action) => {
            state.retrieved.isLoading = true;
        },
        documentRetrievedSuccess: (state, action) => {
            state.retrieved.isLoading = false;
            state.retrieved.error = null;
            state.retrieved.data = action.payload.data;
        },
        documentRetrievedError: (state, action) => {
            state.retrieved.isLoading = false;
            state.retrieved.error = action.payload;
        },
        documentRetrievedReset: (state) => {
            state.retrieved = DocumentInitialState.retrieved;
        },

        documentRenameRequesting: (state, action) => {
            state.rename.isLoading = true;
        },
        documentRenameSuccess: (state, action) => {
            const {retrieved} = current(state);
            const updated = action.payload.data;
            state.rename.isLoading = false;
            state.rename.error = null;
            state.rename.data = updated;
            state.retrieved = updateState(retrieved, updated, true);
        },
        documentRenameError: (state, action) => {
            state.rename.isLoading = false;
            state.rename.error = action.payload;
        },
        documentRenameReset: (state) => {
            state.rename = DocumentInitialState.rename;
        },

        documentDeleteRequesting: (state, action) => {
            state.delete.isLoading = true;
        },
        documentDeleteSuccess: (state, action) => {
            state.delete.isLoading = false;
            state.delete.error = null;
            state.delete.data = action.payload.data;
        },
        documentDeleteError: (state, action) => {
            state.delete.isLoading = false;
            state.delete.error = action.payload;
        },
        documentDeleteReset: (state) => {
            state.delete = DocumentInitialState.delete;
        },

        documentEmailRequesting: (state, action) => {
            state.email.isLoading = true;
        },
        documentEmailSuccess: (state, action) => {
            state.email.isLoading = false;
            state.email.error = null;
            state.email.data = action.payload.data;
        },
        documentEmailError: (state, action) => {
            state.email.isLoading = false;
            state.email.error = action.payload;
        },
        documentEmailReset: (state) => {
            state.email = DocumentInitialState.email;
        },

        documentFileRequestRequesting: (state, action) => {
            state.fileRequest.isLoading = true;
        },
        documentFileRequestSuccess: (state, action) => {
            state.fileRequest.isLoading = false;
            state.fileRequest.error = null;
            state.fileRequest.data = action.payload.data;
        },
        documentFileRequestError: (state, action) => {
            state.fileRequest.isLoading = false;
            state.fileRequest.error = action.payload;
        },
        documentFileRequestReset: (state) => {
            state.fileRequest = DocumentInitialState.fileRequest;
        },

        DocumentMasterReset: () => DocumentInitialState,
    },
});

export const UploadDocumentAPI = (data) => async (dispatch) => {
    dispatch(documentUploadRequesting());
    apiService
        .post(endPoints.document.upload, data)
        .then((response) => {
            dispatch(documentUploadSuccess(response.data));
        })
        .catch((error) => {
            dispatch(documentUploadError(formatAxiosErrorMessage(error)));
        });
};

export const RenameDocumentAPI = (id, data) => async (dispatch) => {
    dispatch(documentRenameRequesting());
    apiService
        .patch(`${endPoints.document.upload}${id}/`, data)
        .then((response) => {
            dispatch(documentRenameSuccess(response.data));
        })
        .catch((error) => {
            dispatch(documentRenameError(formatAxiosErrorMessage(error)));
        });
};

export const DeleteDocumentAPI = (id) => async (dispatch) => {
    dispatch(documentDeleteRequesting());
    apiService
        .delete(`${endPoints.document.upload}${id}/`, {})
        .then((response) => {
            dispatch(
                documentDeleteSuccess(
                    response.data !== undefined ? response.data : "ok"
                )
            );
        })
        .catch((error) => {
            dispatch(documentDeleteError(formatAxiosErrorMessage(error)));
        });
};
export const EmailDocumentAPI = (data) => async (dispatch) => {
    dispatch(documentEmailRequesting());
    apiService
        .post(`${endPoints.document.email}`, data)
        .then((response) => {
            dispatch(documentEmailSuccess(response.data));
        })
        .catch((error) => {
            dispatch(documentEmailError(formatAxiosErrorMessage(error)));
        });
};
export const RetrieveDocumentAPI = (query) => async (dispatch) => {
    dispatch(documentRetrievedRequesting());
    let stringQuery = qargsToQstring(query);
    let responseURL = endPoints.document.upload + stringQuery;
    apiService
        .get(responseURL, {})
        .then((response) => {
            dispatch(documentRetrievedSuccess(response.data));
        })
        .catch((error) =>
            dispatch(documentRetrievedError(formatAxiosErrorMessage(error)))
        );
};

export const FileRequestAPI = (id) => async (dispatch) => {
    dispatch(documentFileRequestRequesting());
    let responseURL = `${endPoints.document.download}/${id}/`;


    apiService
        .get(responseURL, {
            responseType: 'blob',
        })
        .then((response) => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            const contentDisposition = response.headers['content-disposition'];
            const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
            const filename = filenameMatch ? filenameMatch[1] : 'document_filename.extension';
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Set the filename dynamically
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            dispatch(documentFileRequestSuccess("downloaded"));
        })
        .catch((error) =>
            dispatch(documentFileRequestError(formatAxiosErrorMessage(error)))
        );
};

export const {
    documentUploadRequesting,
    documentUploadSuccess,
    documentUploadError,
    documentUploadReset,

    documentRetrievedRequesting,
    documentRetrievedSuccess,
    documentRetrievedError,
    documentRetrievedReset,

    documentRenameRequesting,
    documentRenameSuccess,
    documentRenameError,
    documentRenameReset,

    documentDeleteRequesting,
    documentDeleteSuccess,
    documentDeleteError,
    documentDeleteReset,

    documentEmailRequesting,
    documentEmailSuccess,
    documentEmailError,
    documentEmailReset,

    documentFileRequestRequesting,
    documentFileRequestSuccess,
    documentFileRequestError,
    documentFileRequestReset,

    DocumentMasterReset,
} = DocumentSlice.actions;
export const DocumentState = (state) => state.document;
export default DocumentSlice.reducer;
