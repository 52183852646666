/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/14/2022
 * Time: 9:08 PM
 * Project: w_crm
 */
import {createSlice} from "@reduxjs/toolkit";
import {eraseCookie, getCookie, setCookie} from "../../utils/cookies";
import {refreshKey, tokenKey} from "../../utils/constants";
import apiService from "../../api/apiService";
import {endPoints} from "../../api/endpoints";
import {formatAxiosErrorMessage, isJwtValid} from "../../utils/common";
import {hardRefreshPage} from "../profile/profileReducer";
import {clearLocalStorage} from "../../utils/localStorage";

const initialAuthState = {
    //States used as common for all api calls in Credential Section
    isLoading: false,
    error: null,
    isProcessed: false,

    ipDetails: null,

    isAuthenticated: false,
    notice: {
        type: null,
        message: null,
        isOn: false,
    },

    changePassword: {
        isLoading: false,
        data: null,
        error: null,
    },
    changeEmail: {
        isLoading: false,
        data: null,
        error: null,
    },
    verify_email: {
        isLoading: false,
        data: null,
        error: null,
    },

    resendVerificationEmail: {
        isLoading: false,
        data: null,
        error: null,
    },

};

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        ...initialAuthState,
    },
    reducers: {
        login: (state, action) => {
            let response = action.payload;
            setCookie(tokenKey, response.data.access);
            setCookie(refreshKey, response.data.refresh);
            state.isAuthenticated = true;
            authApiCallSuccess();
        },
        refresh: (state, action) => {
            let response = action.payload;
            setCookie(tokenKey, response.data.access);
            // if (response.data.refresh) {
            //     setCookie(refreshKey, response.data.refresh);
            // }
            state.isAuthenticated = true;
            authApiCallSuccess();
        },
        logout: (state) => {
            eraseCookie(tokenKey);
            eraseCookie(refreshKey);
            clearLocalStorage();
            state.isAuthenticated = false;
        },
        checkAuth: (state, action) => {
            if (getCookie(tokenKey) === null) {
                state.isAuthenticated = false;
            } else {
                state.isAuthenticated = isJwtValid(getCookie(tokenKey));
            }
        },
        setAuthNotice: (state, action) => {
            state.notice.type = action.payload.type;
            state.notice.message = action.payload.message;
            state.notice.isOn = true;
        },
        requestingAuthApiCall: (state) => {
            state.isProcessed = false;
            state.isLoading = true;
            state.error = null;
        },
        authApiCallSuccess: (state) => {
            state.isProcessed = true;
            state.isLoading = false;
        },
        authApiCallError: (state, action) => {
            state.isProcessed = false;
            state.isLoading = false;
            state.error = action.payload;
        },
        resetAuthApiCall: () => initialAuthState,

        setIpDetails: (state, action) => {
            state.ipDetails = action.payload;
        },

        changePasswordRequesting: (state, action) => {
            state.changePassword.isLoading = true;
        },
        changePasswordSuccess: (state, action) => {
            state.changePassword.isLoading = false;
            state.changePassword.error = null;
            state.changePassword.data = true;
        },
        changePasswordError: (state, action) => {
            state.changePassword.isLoading = false;
            state.changePassword.error = action.payload;
        },
        changePasswordReset: (state, action) => {
            state.changePassword = initialAuthState.changePassword;
        },


        resendVerificationEmailRequesting: (state, action) => {
            state.resendVerificationEmail.isLoading = true;
        },
        resendVerificationEmailSuccess: (state, action) => {
            state.resendVerificationEmail.isLoading = false;
            state.resendVerificationEmail.error = null;
            state.resendVerificationEmail.data = true;
        },
        resendVerificationEmailError: (state, action) => {
            state.resendVerificationEmail.isLoading = false;
            state.resendVerificationEmail.error = action.payload;
        },
        resendVerificationEmailReset: (state, action) => {
            state.resendVerificationEmail = initialAuthState.resendVerificationEmail;
        },


        changeEmailRequesting: (state, action) => {
            state.changeEmail.isLoading = true;
        },
        changeEmailSuccess: (state, action) => {
            state.changeEmail.isLoading = false;
            state.changeEmail.error = null;
            state.changeEmail.data = true;
        },
        changeEmailError: (state, action) => {
            state.changeEmail.isLoading = false;
            state.changeEmail.error = action.payload;
        },
        changeEmailReset: (state, action) => {
            state.changeEmail = initialAuthState.changeEmail;
        },

        VerifyEmailRequesting: (state, action) => {
            state.verify_email.isLoading = true;
        },
        VerifyEmailSuccess: (state, action) => {
            state.verify_email.isLoading = false;
            state.verify_email.error = null;
            state.verify_email.data = true;
        },
        VerifyEmailError: (state, action) => {
            state.verify_email.isLoading = false;
            state.verify_email.error = action.payload;
        },
        VerifyEmailReset: (state, action) => {
            state.verify_email = initialAuthState.verify_email;
        },


        AuthMasterReset: () => initialAuthState,
    },
});

export const loginApi = (data) => async (dispatch) => {
    dispatch(requestingAuthApiCall());
    apiService
        .post(endPoints.auth.login, data)
        .then((response) => dispatch(login(response.data)))
        .catch((error) =>
            dispatch(authApiCallError(formatAxiosErrorMessage(error)))
        );
};

export const GoogleLoginApi = (data) => async (dispatch) => {
    dispatch(requestingAuthApiCall());
    let payload = new FormData();
    payload.append("code", data.code);
    if (data.timezone) {
        payload.append("timezone", data.timezone);
    }
    apiService
        .post(endPoints.auth.googleLogin, payload)
        .then((response) => dispatch(login(response.data)))
        .catch((error) =>
            dispatch(authApiCallError(formatAxiosErrorMessage(error)))
        );
};

export const logoutApi = () => async (dispatch) => {
    dispatch(requestingAuthApiCall());
    apiService
        .post(endPoints.auth.logout, {"refresh": getCookie(refreshKey)},)
        // .post(endPoints.auth.logout, {},)
        .then((response) => {
            dispatch(masterLogout())
        })
        .catch((error) => {
                dispatch(authApiCallError(formatAxiosErrorMessage(error)))
                dispatch(masterLogout())
            }
        );
};

export const refreshTokenApi = (data) => async (dispatch) => {
    dispatch(requestingAuthApiCall());
    apiService
        .post(endPoints.auth.refresh_token, {"refresh": getCookie(refreshKey)})
        .then((response) => dispatch(refresh(response.data)))
        .catch((error) =>
            dispatch(authApiCallError(formatAxiosErrorMessage(error)))
        );
};
export const registerApi = (data) => async (dispatch) => {
    dispatch(requestingAuthApiCall());
    apiService
        .post(endPoints.auth.register, data)
        .then(() => dispatch(authApiCallSuccess()))
        .catch((error) =>
            dispatch(authApiCallError(formatAxiosErrorMessage(error)))
        );
};

export const forgotPasswordApi = (data) => async (dispatch) => {
    dispatch(requestingAuthApiCall());
    apiService
        .post(endPoints.auth.forgot_password, data)
        .then((response) => dispatch(authApiCallSuccess()))
        .catch((error) =>
            dispatch(authApiCallError(formatAxiosErrorMessage(error)))
        );
};

export const verifyForgotPasswordApi = (data, uid, token) => async (dispatch) => {
    dispatch(requestingAuthApiCall());
    apiService
        .post(`${endPoints.auth.verify_forgot_password}${uid}/${token}`, data)
        .then(() => dispatch(authApiCallSuccess()))
        .catch((error) =>
            dispatch(authApiCallError(formatAxiosErrorMessage(error)))
        );
};

export const changePasswordApi = (data) => async (dispatch) => {
    dispatch(changePasswordRequesting());
    apiService
        .post(endPoints.auth.change_password, data)
        .then((response) => dispatch(changePasswordSuccess(response.data)))
        .catch((error) =>
            dispatch(changePasswordError(formatAxiosErrorMessage(error)))
        );
};

export const changeEmailApi = (data) => async (dispatch) => {
    dispatch(changeEmailRequesting());
    apiService
        .post(endPoints.auth.change_email, data)
        .then((response) => dispatch(changeEmailSuccess(response.data)))
        .catch((error) =>
            dispatch(changeEmailError(formatAxiosErrorMessage(error)))
        );
};

export const resendVerifyEmailAPI = (email) => async (dispatch) => {
    dispatch(resendVerificationEmailRequesting());
    apiService
        .post(`${endPoints.auth.resend_verify_email}`, {email: email})
        .then((response) => dispatch(resendVerificationEmailSuccess(response.data)))
        .catch((error) =>
            dispatch(resendVerificationEmailError(formatAxiosErrorMessage(error)))
        );
};

export const verifyEmailAPI = (uid) => async (dispatch) => {
    dispatch(VerifyEmailRequesting());
    apiService
        .post(`${endPoints.auth.verify_email}`, {key: uid})
        .then((response) => dispatch(VerifyEmailSuccess(response.data)))
        .catch((error) =>
            dispatch(VerifyEmailError(formatAxiosErrorMessage(error)))
        );
};

export const masterLogout = () => async (dispatch) => {
    dispatch(hardRefreshPage());
};

export const {
    login,
    refresh,
    checkAuth,
    setAuthNotice,
    logout,
    requestingAuthApiCall,
    authApiCallSuccess,
    authApiCallError,
    resetAuthApiCall,
    setIpDetails,

    changePasswordRequesting,
    changePasswordSuccess,
    changePasswordError,
    changePasswordReset,

    AuthMasterReset,

    VerifyEmailRequesting,
    VerifyEmailSuccess,
    VerifyEmailError,
    VerifyEmailReset,


    changeEmailRequesting,
    changeEmailSuccess,
    changeEmailError,
    changeEmailReset,


    resendVerificationEmailRequesting,
    resendVerificationEmailSuccess,
    resendVerificationEmailError,
    resendVerificationEmailReset,
} = authSlice.actions;
export const authState = (state) => state.auth;
export default authSlice.reducer;
