import {adminRole, ownerRole, staffRole} from "../../utils/constants";

/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 05/03/24
 * Time: 9:19 am
 * Project: W-CRM
 */
export const PROFILE_PAGE_ROLE = [adminRole, ownerRole, staffRole]
export const TOP_NAV_VIEW_MODE_SWITCHER = [adminRole, ownerRole]
export const TOP_NAV_DROPDOWN_ORG_SUBSCRIPTION = [adminRole, ownerRole]