/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 07/11/22
 * Time: 12:39 PM
 * Project: W-CRM
 */

import React from "react";

let mobileWidth = 420;
let tabletWidth = 780;
let desktopWidth = 1024;

const reducer = (_, width) => {
  const newState = {};
  newState.mobile = width < mobileWidth;
  newState.tablet = mobileWidth < width && width < tabletWidth;
  newState.desktop = tabletWidth < width;

  return newState;
};

const initialState = {
  mobile: false,
  tablet: false,
  desktop: false,
};

export default () => {
  const [node, ref] = React.useState(null);

  const [matches, dispatch] = React.useReducer(reducer, initialState);

  const observerRef = React.useRef(null);

  if (!observerRef.current) {
    observerRef.current = new ResizeObserver(([entry]) => {
      dispatch(entry.borderBoxSize?.inlineSize ?? entry.contentRect.width);
    });
  }

  React.useEffect(() => {
    const { current: observer } = observerRef;

    if (node) {
      dispatch(node.getBoundingClientRect().width);
      observer.observe(node);
    }

    return () => {
      observer.disconnect();
    };
  }, [node]);

  return [matches, ref];
};
