/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 21/11/23
 * Time: 8:47 am
 * Project: Waxis
 */
import React, {memo} from 'react';
import "./style.css"
import Users from "../../icon/Users";
import CheckFilled from "../../icon/CheckFilled";

export const AccountSwitcherItem = memo(({
                                             icon: Icon = Users,
                                             iconBg = "orange-set",
                                             isSelected,
                                             selectorIcon: Selector,
                                             title,
                                             description,
                                             clickCallback
                                         }) => {
    return (
        <div className={`accountSwitcher-item-container cursor-clickable ${Selector ? 'selected' : 'selectable'}`}
             onClick={clickCallback}>
            <div className={`accountSwitcher-item-icon-holder ${iconBg}`}>
                <Icon strokeWidth={0} className={"w-1-25"}/>
            </div>
            <div className={`accountSwitcher-details-holder ${(isSelected || Selector) ? 'selected' : ''}`}>
                {title &&
                    <p className={"text-small text-bolder noTextOverFlow"}>{title}</p>
                }
                {description &&
                    <p className={"text-small noTextOverFlow"}>{description}</p>
                }
            </div>
            <div className={"accountSwitcher-action-holder"}>
                {isSelected &&
                    <CheckFilled fill={"var(--primary)"} stroke={"white"} className={"w-1-25"} strokeWidth={.7}/>
                }
                {Selector &&
                    <Selector className={"w-1-25"} strokeWidth={2}/>
                }
            </div>
        </div>
    );
})