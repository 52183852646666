/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/14/2022
 * Time: 2:24 PM
 * Project: w_crm
 */
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getCookie} from "../utils/cookies";
import {tokenKey} from "../utils/constants";
import {isJwtValid} from "../utils/common";
import {useSelector} from "react-redux";
import {authState} from "../redux/auth/authReducer";
import {ALLOW_PUBLIC_WITH_VALID_LOGIN} from "./Filters";

export default function PublicLayout({component: Component, ...rest}) {
    let navigate = useNavigate();
    const auth = useSelector(authState);
    const location = useLocation()
    useEffect(() => {
        if (getCookie(tokenKey) !== null) {
            if (isJwtValid(getCookie(tokenKey)) && !ALLOW_PUBLIC_WITH_VALID_LOGIN.some(i => location.pathname.match(i))) {
                navigate("/", {replace: true});
            }
        }
    }, [auth, navigate]);
    return (
        <div className={"public-layout-parent-container"}>
            <div className={"public-layout-parent-holder"}>
                <Component {...rest} />
            </div>
        </div>
    );
}
