/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 30/09/23
 * Time: 12:10 pm
 * Project: W-CRM-Frontend
 */
import React, { memo } from "react";
import "./style.css";
import { isPrivilegeAllowed } from "../../utils/PrivilegeControl/PrivilegeControl";

export const PrivilegeCheck = memo(({ children, allowed = [] }) => {
  return <>{isPrivilegeAllowed(allowed) && <>{children}</>}</>;
});
