/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 07/11/22
 * Time: 6:23 PM
 * Project: W-CRM
 */
import React from "react";
import "./style.css";
import Search from "../../icon/Search";
import Input from "../Input/Input";

export default function SearchTextField({ onChange, placeholder }) {
  return (
    <div className={"search-input-holder"}>
      <Input
        size={"large"}
        trailing={<Search />}
        placeHolder={placeholder}
        onChange={onChange}
      />
      <div className={"search-prefix-icon"}></div>
    </div>
  );
}
