/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 21/11/23
 * Time: 8:43 am
 * Project: Waxis
 */
import React, {memo, useEffect, useRef, useState} from 'react';
import "./style.css"
import {AccountSwitcherItem} from "./AccountSwitcherItem";
import Card from "../Card/Card";
import ChevronDown from "../../icon/ChevronDown";
import {removeDuplicates} from "../../utils/util";
import {
    ACCOUNT_SWITCH_INIT_MODE_SET,
    ACCOUNT_SWITCH_LIST_PERSONAL_SET,
    ACCOUNT_SWITCH_MY_VIEW,
    ACCOUNT_SWITCH_ORG_SET,
    ACCOUNT_SWITCH_USER_SET,
    ACCOUNT_SWITCH_USER_VIEW,
    ACCOUNT_SWITCHER_USER_MODEL,
    DEFAULT_ACCOUNT_SWITCH_PROFILES,
    visionKey
} from "../../utils/constants";
import {UserSelection} from "../Selection/UserSelection";
import Modal from "../Model/Modal";
import {useDispatch, useSelector} from "react-redux";
import {ActivateModel, DeActivateModel, ModelState} from "../../redux/Model/modelReducer";
import {OrganizationState} from "../../redux/Organization/organizationReducer";
import ChevronUp from "../../icon/ChevronUp";
import {profileState, updateUserPreferencesApi, viewingAsUpdate} from "../../redux/profile/profileReducer";
import {eraseCookie, getCookie, setCookie} from "../../utils/cookies";
import Spinner from "../Spinner/Spinner";

export const AccountSwitcher = memo(() => {
    const [isActive, setIsActive] = useState(false)
    const {active} = useSelector(ModelState)
    const dispatch = useDispatch()
    const {usersSelectionList: users} = useSelector(OrganizationState);
    const {profile, updateUserPreferences} = useSelector(profileState);
    const [profiles, setProfiles] = useState(DEFAULT_ACCOUNT_SWITCH_PROFILES)

    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if (selected?.id === ACCOUNT_SWITCH_MY_VIEW) {
            setProfiles(profiles.filter(profile => profile.id !== ACCOUNT_SWITCH_MY_VIEW))
        } else {
            const newProfiles = [...profiles, ACCOUNT_SWITCH_LIST_PERSONAL_SET]
            setProfiles(removeDuplicates(newProfiles))
        }
    }, [selected]);
    useEffect(() => {
        if (profile.data.userpreferences?.flag_view_as_team) {
            setSelected(ACCOUNT_SWITCH_ORG_SET)
        } else {
            const localCookie = getCookie(visionKey)
            if (localCookie) {
                setSelected(JSON.parse(localCookie))
            } else {
                setSelected(ACCOUNT_SWITCH_INIT_MODE_SET)
            }
        }
        eraseCookie(visionKey)
    }, [profile.data]);
    const clearUserSelection = () => {
        const newProfiles = [ACCOUNT_SWITCH_USER_SET, ...profiles]
        setProfiles(removeDuplicates(newProfiles))
        setSelectedUser({data: null, id: null})
    }
    const onItemSelected = (item) => {
        eraseCookie(visionKey)
        if (item.id === ACCOUNT_SWITCH_MY_VIEW) {
            clearUserSelection()
            setSelected(ACCOUNT_SWITCH_INIT_MODE_SET)
            dispatch(viewingAsUpdate(item))
            dispatch(updateUserPreferencesApi({flag_view_as_team: false}, profile.data.userpreferences.id));
        } else if (item.id === ACCOUNT_SWITCH_USER_VIEW) {
            dispatch(ActivateModel(ACCOUNT_SWITCHER_USER_MODEL))
        } else {
            dispatch(updateUserPreferencesApi({flag_view_as_team: true}, profile.data.userpreferences.id));
            // clearUserSelection()
            // setSelected(item)
            // dispatch(viewingAsUpdate(item))
        }
        setIsActive(false)
    }
    const profileFallRef = useRef();

    function handleClick(e) {
        if (profileFallRef.current !== null && !profileFallRef.current.contains(e.target)) {
            setIsActive(false);
        }
    }

    useEffect(() => {
        if (isActive) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }
        return () => document.removeEventListener("click", handleClick);
    }, [isActive]);


    const closeUserSelectModel = () => {
        dispatch(DeActivateModel(ACCOUNT_SWITCHER_USER_MODEL))
    }

    const [selectedUser, setSelectedUser] = useState({id: null, data: null})
    const updateLeadUser = () => {
        const selected = users?.data?.results.filter(i => i.id === selectedUser.id) ?? []
        if (selected.length > 0) {
            setSelectedUser({...selectedUser, data: selected[0]})
            dispatch(DeActivateModel(ACCOUNT_SWITCHER_USER_MODEL))
            const {first_name, last_name, id} = selected[0]
            const newItem = {...ACCOUNT_SWITCH_USER_SET, description: `${first_name} ${last_name}`, userId: id}
            const newProfiles = [newItem, ...profiles]
            setCookie(visionKey, JSON.stringify(newItem))
            setSelected(newItem)
            setProfiles(removeDuplicates(newProfiles))
            dispatch(updateUserPreferencesApi({flag_view_as_team: false}, profile.data.userpreferences.id));
        }
    }
    useEffect(() => {
        dispatch(viewingAsUpdate(selected))
    }, [dispatch, selected]);

    return (<>

        <Modal
            isOpen={active[ACCOUNT_SWITCHER_USER_MODEL]}
            onClose={closeUserSelectModel}
            onCancel={closeUserSelectModel}
            showFooter={true}
            title={"Switch to user view"}
            okButtonText={"Switch"}
            onOk={updateLeadUser}
        >
            <div className={"mt-1"}>
                <UserSelection exclude={[profile.data.id]} callback={(value) => {
                    setSelectedUser({...selectedUser, id: value})
                }}/>
            </div>
        </Modal>
        <div className={"accountSwitcher-container"} ref={profileFallRef}>
            <Spinner isSpinning={updateUserPreferences.isLoading}>
                {selected &&
                    <Card customCss={"accountSwitcher-selected-container"}>
                        <AccountSwitcherItem selectorIcon={isActive ? ChevronUp : ChevronDown}
                                             title={selected.title} description={selected.description} key={selected.id}
                                             icon={selected.icon} iconBg={selected.iconBg}
                                             clickCallback={() => setIsActive(old => !old)}
                        />
                    </Card>
                }
            </Spinner>

            {isActive && <div className={"accountSwitcher-list-container"}>
                <Card customCss={"accountSwitcher-list-holder"}>
                    <div className={"accountSwitcher-list-item-holder"}>
                        {profiles.map(i => <AccountSwitcherItem title={i.title} description={i.description}
                                                                key={i.id}
                                                                icon={i.icon} iconBg={i.iconBg}
                                                                isSelected={i.id === selected.id}
                                                                clickCallback={() => {
                                                                    onItemSelected(i)
                                                                }}
                        />)}
                    </div>
                </Card>
            </div>}
        </div>
    </>);
})