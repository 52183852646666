import React from 'react';

export default function CloseCircle({
  strokeWidth = 3,
  className = 'w-1',
  stroke = 'currentColor',
  fill = 'none',
  ...props
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      fill={fill}
      viewBox='0 0 24 24'
      stroke={stroke}
      {...props}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
