/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 02/09/22
 * Time: 12:30 PM
 * Project: W-CRM
 */
import React, {useEffect, useMemo, useState} from "react";
import "./style.css";
import Model from "../../component/Model/Modal";
import NavigationController from "../NavBar/NavigationController";
import {useDispatch, useSelector} from "react-redux";
import {getMyInviteAPI, myInvitesReset, OrganizationState,} from "../../redux/Organization/organizationReducer";
import {navState} from "../../redux/navigation/navReducer";
import {ActivateModel, DeActivateModel, ModelState,} from "../../redux/Model/modelReducer";
import {PRIVATE_LAYOUT_INVITE_MODEL} from "../../utils/constants";
import {profileState} from "../../redux/profile/profileReducer";
import NavAction from "../NavBar/old/NavAction";
import {MyInvites} from "../../pages/Invites/MyInvites";
import {useLocation} from "react-router-dom";
import {changeTimeFormate} from "../../utils/util";
import {SubscriptionCheck} from "../../component/SubscriptionCheck/SubscriptionCheck";
import {isCULActionAllowed} from "../../utils/PrivilegeControl/PrivilegeControl";
import TriangleExclamation from "../../icon/TriangleExclamation";
import {URL_PROFILE} from "../../routes/url";

export default function PrivateMainContainer({
                                                 component: Component,
                                                 lastSync,
                                                 configuration,
                                                 ...rest
                                             }) {
    const dispatch = useDispatch();
    const {myInvites} = useSelector(OrganizationState);
    const [inviteModel, setInviteModel] = useState(false);
    const {active} = useSelector(ModelState);
    const {profile} = useSelector(profileState);
    useEffect(() => {
        dispatch(getMyInviteAPI({my_invites: true}));
        return () => {
            dispatch(myInvitesReset());
        };
    }, []);
    const location = useLocation();
    useEffect(() => {
        setInviteModel(active[PRIVATE_LAYOUT_INVITE_MODEL]);
    }, [active]);
    useEffect(() => {
        if (
            myInvites?.data?.results?.filter((e) => e.status === "pending").length >
            0 &&
            location.hash !== "#myInvites"
        ) {
            dispatch(ActivateModel(PRIVATE_LAYOUT_INVITE_MODEL));
        } else {
            dispatch(DeActivateModel(PRIVATE_LAYOUT_INVITE_MODEL));
        }
    }, [myInvites]);

    let actionCSS = "private-container-action-holder";
    const nav = useSelector(navState);

    if (nav.actions.length > 0) {
        actionCSS = `${actionCSS} private-layout-action-fixed`;
    } else if (!nav.isEnabled) {
        actionCSS = "";
    }

    const childBodyCss = useMemo(() => {
        let css = "private-layout-child-body";
        if (configuration) {
            if (configuration?.padding === "L") {
                css = `${css} LeftPadding`;
            } else if (configuration?.padding === "R") {
                css = `${css} RightPadding`;
            }
        } else {
            css = `${css} normalPadding`;
        }
        return css;
    }, [configuration]);
    const currentYear = changeTimeFormate(new Date(), "YYYY")

    const NoViewAlertIn = [URL_PROFILE]

    return (
        <div className={"main-page-screen-wider"}>
            <div className="bg-gray full-screen">
                <NavigationController>
                    <Model
                        footerEnabled={false}
                        isOpen={inviteModel}
                        closeOnExternal={true}
                        onClose={() =>
                            dispatch(DeActivateModel(PRIVATE_LAYOUT_INVITE_MODEL))
                        }
                        onCancel={() =>
                            dispatch(DeActivateModel(PRIVATE_LAYOUT_INVITE_MODEL))
                        }
                        title={"Invite Requests"}
                        description={
                            "You have received request(s) to join anyone of these organisations"
                        }
                    >
                        <MyInvites onlyPending={true}/>
                        {/*<MyInvites />*/}
                    </Model>
                    <div className={"private-layout-parent-container"}>
                        {(!isCULActionAllowed() && !NoViewAlertIn.includes(location.pathname)) &&
                            <div className={"notice-container white-text text-bolder"}>
                                <TriangleExclamation/>
                                <p className={"text-small"}>Staff view is enabled in read-only mode only</p>
                            </div>
                        }
                        <div className={actionCSS}>
                            <NavAction actionListData={nav.actions}/>
                        </div>
                        <SubscriptionCheck/>
                        <div className={childBodyCss}>
                            <Component {...rest} key={lastSync}/>
                        </div>

                        <div className={"private-layout-child-body-footer"}>
                            <small>© Workaxis {currentYear}</small>
                        </div>
                    </div>
                </NavigationController>
            </div>
        </div>
    );
}
