/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 20/12/23
 * Time: 1:49 pm
 * Project: Waxis
 */
import {createSlice} from "@reduxjs/toolkit"
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import apiService from "../../api/apiService";
import {endPoints} from "../../api/endpoints";


const AnalyticsInitialState = {
    conversion_ratio: {
        isLoading: false,
        data: null,
        error: null,
    },
    device_traffic: {
        isLoading: false,
        data: null,
        error: null,
    },
    referral: {
        isLoading: false,
        data: null,
        error: null,
    },
    lead_source: {
        isLoading: false,
        data: null,
        error: null,
    }
}

export const AnalyticsSlice = createSlice({
    name: "Analytics",
    initialState: {
        ...AnalyticsInitialState
    }, reducers: {
        FetchConversionRatioRequesting: (state, action) => {
            state.conversion_ratio.isLoading = true
        },
        FetchConversionRatioSuccess: (state, action) => {
            state.conversion_ratio.isLoading = false
            state.conversion_ratio.error = null
            state.conversion_ratio.data = action.payload.data
        },
        FetchConversionRatioError: (state, action) => {
            state.conversion_ratio.isLoading = false
            state.conversion_ratio.error = action.payload
        },
        FetchConversionRatioReset: (state, action) => {
            state.conversion_ratio = AnalyticsInitialState.conversion_ratio
        },


        FetchDeviceTrafficRequesting: (state, action) => {
            state.device_traffic.isLoading = true
        },
        FetchDeviceTrafficSuccess: (state, action) => {
            state.device_traffic.isLoading = false
            state.device_traffic.error = null
            state.device_traffic.data = action.payload.data
        },
        FetchDeviceTrafficError: (state, action) => {
            state.device_traffic.isLoading = false
            state.device_traffic.error = action.payload
        },
        FetchDeviceTrafficReset: (state, action) => {
            state.device_traffic = AnalyticsInitialState.device_traffic
        },


        FetchReferralRequesting: (state, action) => {
            state.referral.isLoading = true
        },
        FetchReferralSuccess: (state, action) => {
            state.referral.isLoading = false
            state.referral.error = null
            state.referral.data = action.payload.data
        },
        FetchReferralError: (state, action) => {
            state.referral.isLoading = false
            state.referral.error = action.payload
        },
        FetchReferralReset: (state, action) => {
            state.referral = AnalyticsInitialState.referral
        },

        FetchLeadSourceRequesting: (state, action) => {
            state.lead_source.isLoading = true
        },
        FetchLeadSourceSuccess: (state, action) => {
            state.lead_source.isLoading = false
            state.lead_source.error = null
            state.lead_source.data = action.payload.data
        },
        FetchLeadSourceError: (state, action) => {
            state.lead_source.isLoading = false
            state.lead_source.error = action.payload
        },
        FetchLeadSourceReset: (state, action) => {
            state.lead_source = AnalyticsInitialState.lead_source
        },
    }
})

export const GetConversionRatioAPI = (query) => async (dispatch) => {
    dispatch(FetchConversionRatioRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.analytics.conversion_ratio}${stringQuery}`, {})
        .then((response) => {
            dispatch(FetchConversionRatioSuccess(response.data));
        })
        .catch((error) => dispatch(FetchConversionRatioError(formatAxiosErrorMessage(error))));
};

export const GetDeviceTrafficAPI = (query) => async (dispatch) => {
    dispatch(FetchDeviceTrafficRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.analytics.device_traffic}${stringQuery}`, {})
        .then((response) => {
            dispatch(FetchDeviceTrafficSuccess(response.data));
        })
        .catch((error) => dispatch(FetchDeviceTrafficError(formatAxiosErrorMessage(error))));
};

export const GetReferralAPI = (query) => async (dispatch) => {
    dispatch(FetchReferralRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.analytics.referral}${stringQuery}`, {})
        .then((response) => {
            dispatch(FetchReferralSuccess(response.data));
        })
        .catch((error) => dispatch(FetchReferralError(formatAxiosErrorMessage(error))));
};

export const GetLeadSourceAPI = (query) => async (dispatch) => {
    dispatch(FetchLeadSourceRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.analytics.lead_source}${stringQuery}`, {})
        .then((response) => {
            dispatch(FetchLeadSourceSuccess(response.data));
        })
        .catch((error) => dispatch(FetchLeadSourceError(formatAxiosErrorMessage(error))));
};

export const {
    FetchConversionRatioRequesting,
    FetchConversionRatioSuccess,
    FetchConversionRatioError,
    FetchConversionRatioReset,

    FetchDeviceTrafficRequesting,
    FetchDeviceTrafficSuccess,
    FetchDeviceTrafficError,
    FetchDeviceTrafficReset,

    FetchReferralRequesting,
    FetchReferralSuccess,
    FetchReferralError,
    FetchReferralReset,

    FetchLeadSourceRequesting,
    FetchLeadSourceSuccess,
    FetchLeadSourceError,
    FetchLeadSourceReset

} = AnalyticsSlice.actions;
export const AnalyticsState = (state) => state.analytics
export default AnalyticsSlice.reducer