/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 17/08/23
 * Time: 10:46 am
 * Project: W-CRM-Frontend
 */
import React, {useEffect, useState} from "react";
import "./style.css";
import {useQuery} from "../utils/util";
import {useDispatch, useSelector} from "react-redux";
import {OrganizationState} from "../redux/Organization/organizationReducer";
import {ANTCRMLOGO, IS_APP_ENTERPRISE} from "../utils/constants";
import {ChangeClientLayoutState} from "../redux/profile/profileReducer";

export default function ClientLayout({component: Component, ...rest}) {
    const urlQuery = useQuery();
    const dispatch = useDispatch()
    const [isEmbed, setIsEmbed] = useState(false)
    const {clientLayoutOrg} = useSelector(OrganizationState);

    useEffect(() => {
        setIsEmbed(urlQuery.get("embed") != null)
    }, [urlQuery]);

    useEffect(() => {
        dispatch(ChangeClientLayoutState(true))
    }, []);


    return (
        <>
            {isEmbed ?
                <div className={"embed-holder"} id={"ant-crm-iframe"}>
                    <Component {...rest} />
                </div> :
                <div className={"clientLayout-container"}>
                    <div className={"clientLayout-child-holder shadow-wx-sm"}>
                        <div className={"clientLayout-holder"} id={"ant-crm-iframe"}>
                            <Component {...rest} />
                        </div>
                    </div>

                    {clientLayoutOrg &&
                        <>
                            <div className={"clientLayout-header-holder"}>
                                {clientLayoutOrg?.profile_pic && <img
                                    alt={clientLayoutOrg?.name}
                                    src={clientLayoutOrg?.profile_pic}
                                />}
                            </div>


                            <div className={"clientLayout-footer-holder"}>
                                <small>{clientLayoutOrg?.name}</small>
                            </div>
                        </>
                        
                    }
                </div>
            }
        </>
    );
}
