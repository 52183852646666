/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 4/19/2022
 * Time: 6:05 PM
 * Project: w_crm
 */
import React from "react";
import "./style.css";
import userObserver from "../../../utils/userObserver";
import Button from "../../../component/Button/Button";
import MenuHolder from "../../../component/MenuHolder/MenuHolder";
import {Breadcrums} from "./BreadCrums";
import {getUUID} from "../../../utils/util";
import {isCULActionAllowed} from "../../../utils/PrivilegeControl/PrivilegeControl";
import TriangleExclamation from "../../../icon/TriangleExclamation";

export default function NavAction({actionListData = []}) {
    // let actionListData = [
    //   {
    //     id: "1",
    //     name: "action a",
    //     icon: Eye,
    //     isEnabled: true,
    //     action: () => {
    //       // console.log("action 1");
    //     },
    //   },
    //   {
    //     id: "2",
    //     name: "action b",
    //     icon: Eye,
    //     isEnabled: true,
    //     action: () => {
    //       // console.log("action 1");
    //     },
    //   },
    //   {
    //     id: "1",
    //     icon: Eye,
    //     name: "action a",
    //     isEnabled: true,
    //     action: () => {
    //       // console.log("action 1");
    //     },
    //   },
    //   {
    //     id: "2",
    //     name: "action b",
    //     icon: Eye,
    //     isEnabled: true,
    //     action: () => {
    //       // console.log("action 1");
    //     },
    //   },
    //   {
    //     id: "1",
    //     name: "action a",
    //     icon: Eye,
    //     isEnabled: true,
    //     action: () => {
    //       // console.log("action 1");
    //     },
    //   },
    //   {
    //     id: "2",
    //     name: "action b",
    //     icon: Eye,
    //     isEnabled: true,
    //     action: () => {
    //       // console.log("action 1");
    //     },
    //   },
    // ];
    const [matches, ref] = userObserver();

    const ControlItems = () => {
        return actionListData.map((i) => {
            const {icon: Icon} = i;
            return {...i, icon: <Icon strokeWidth={2} className="w-1-5"/>};
        });
    };
    return (
        <div className="action-slot" ref={ref}>
            <Breadcrums/>
            {actionListData.length > 0 && actionListData[0].name ? (
                <>
                    {matches.mobile || matches.tablet ? (
                        <MenuHolder menuItems={ControlItems()}/>
                    ) : (
                        <div className={"nav-action-desktop-holder"}>
                            {actionListData.map((item) => {
                                return (
                                    <Button
                                        disabled={!item.isEnabled}
                                        key={`navaction-${item.id}-${getUUID()}`}
                                        onClick={item.action}
                                    >
                                        {item.name}
                                    </Button>
                                );
                            })}
                        </div>
                    )}
                </>
            ) : (
                <>
                    {actionListData.map((item) => {
                        return <React.Fragment key={getUUID()}>{item}</React.Fragment>;
                    })}
                </>
            )}
        </div>
    );
}
