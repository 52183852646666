/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/14/2022
 * Time: 9:36 PM
 * Project: w_crm
 */
import {createSlice} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {endPoints} from "../../api/endpoints";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import {subscriptionDetailsSuccess} from "../subscription/subscriptionReducer";

const initialProfileState = {
    profile: {
        isLoading: false,
        error: null,
        data: null,
    },
    userPreferences: {
        isLoading: false,
        error: null,
        data: [],
    },
    updateUserPreferences: {
        isLoading: false,
        error: null,
        data: null,
    },
    fetchProfile: {
        isLoading: false,
        error: null,
        data: null,
    },
    updateProfile: {
        isLoading: false,
        error: null,
        data: null,
    },
    viewingAs: {
        data: null,
    },
    isClientLayout: false,
    transfer: {
        isLoading: false,
        error: null,
        data: null,
    },
    transferList: {
        isLoading: false,
        error: null,
        data: null,
    },
    unmap: {
        isLoading: false,
        error: null,
        data: null,
    },
    deleteAccount: {
        isLoading: false,
        error: null,
        data: null,
    },
};

export const profileSlice = createSlice({
    name: "profile",
    initialState: {
        ...initialProfileState,
    },
    reducers: {
        requestingProfileApiCall: (state) => {
            state.profile.isLoading = true;
            state.profile.error = null;
        },
        profileApiCallSuccess: (state, action) => {
            state.profile.isLoading = false;
            state.profile.error = null;
            state.profile.data = action.payload.data;
        },
        profileApiCallError: (state, action) => {
            state.profile.isLoading = false;
            state.profile.error = action.payload;
        },
        profileReset: (state, action) => {
            state.profile = initialProfileState.profile;
        },

        userPreferencesRequesting: (state, action) => {
            state.userPreferences.isLoading = true;
            state.userPreferences.isCreated = false;
        },
        userPreferencesSuccess: (state, action) => {
            state.userPreferences.isLoading = false;
            state.userPreferences.error = null;
            state.userPreferences.data = action.payload.data;
        },
        userPreferencesError: (state, action) => {
            state.userPreferences.isLoading = false;
            state.userPreferences.data = [];
            state.userPreferences.error = action.payload;
        },
        userPreferencesReset: (state, action) => {
            state.userPreferences = initialProfileState.userPreferences;
        },

        updateUserPreferencesRequesting: (state, action) => {
            state.updateUserPreferences.isLoading = true;
            state.updateUserPreferences.isCreated = false;
        },
        updateUserPreferencesSuccess: (state, action) => {
            state.updateUserPreferences.isLoading = false;
            state.updateUserPreferences.error = null;
            state.updateUserPreferences.data = action.payload.data;
        },
        updateUserPreferencesError: (state, action) => {
            state.updateUserPreferences.isLoading = false;
            state.updateUserPreferences.data = null;
            state.updateUserPreferences.error = action.payload;
        },
        updateUserPreferencesReset: (state, action) => {
            state.updateUserPreferences = initialProfileState.updateUserPreferences;
        },

        fetchProfileRequesting: (state, action) => {
            state.fetchProfile.isLoading = true;
        },
        fetchProfileSuccess: (state, action) => {
            state.fetchProfile.isLoading = false;
            state.fetchProfile.error = null;
            state.fetchProfile.data = action.payload.data;
        },
        fetchProfileError: (state, action) => {
            state.fetchProfile.isLoading = false;
            state.fetchProfile.data = null;
            state.fetchProfile.error = action.payload;
        },
        fetchProfileReset: (state, action) => {
            state.fetchProfile = initialProfileState.fetchProfile;
        },

        updateProfileRequesting: (state, action) => {
            state.updateProfile.isLoading = true;
            state.updateProfile.isCreated = false;
        },
        updateProfileSuccess: (state, action) => {
            state.updateProfile.isLoading = false;
            state.updateProfile.error = null;
            state.updateProfile.data = action.payload.data;
        },
        updateProfileError: (state, action) => {
            state.updateProfile.isLoading = false;
            state.updateProfile.data = null;
            state.updateProfile.error = action.payload;
        },
        updateProfileReset: (state, action) => {
            state.updateProfile = initialProfileState.updateProfile;
        },


        transferUserRequesting: (state, action) => {
            state.transfer.isLoading = true;
            state.transfer.isCreated = false;
        },
        transferUserSuccess: (state, action) => {
            state.transfer.isLoading = false;
            state.transfer.error = null;
            state.transfer.data = action.payload.data;
        },
        transferUserError: (state, action) => {
            state.transfer.isLoading = false;
            state.transfer.data = null;
            state.transfer.error = action.payload;
        },
        transferUserReset: (state, action) => {
            state.transfer = initialProfileState.transfer;
        },

        transferModuleListRequesting: (state, action) => {
            state.transferList.isLoading = true;
            state.transferList.isCreated = false;
        },
        transferModuleListSuccess: (state, action) => {
            state.transferList.isLoading = false;
            state.transferList.error = null;
            state.transferList.data = action.payload.data;
        },
        transferModuleListError: (state, action) => {
            state.transferList.isLoading = false;
            state.transferList.data = null;
            state.transferList.error = action.payload;
        },
        transferModuleListReset: (state, action) => {
            state.transferList = initialProfileState.transferList;
        },


        unMapUserRequesting: (state, action) => {
            state.unmap.isLoading = true;
        },
        unMapUserSuccess: (state, action) => {
            state.unmap.isLoading = false;
            state.unmap.error = null;
            state.unmap.data = action.payload.data;
        },
        unMapUserError: (state, action) => {
            state.unmap.isLoading = false;
            state.unmap.data = null;
            state.unmap.error = action.payload;
        },
        unMapUserReset: (state, action) => {
            state.unmap = initialProfileState.unmap;
        },

        deleteAccountUserRequesting: (state, action) => {
            state.deleteAccount.isLoading = true;
        },
        deleteAccountUserSuccess: (state, action) => {
            state.deleteAccount.isLoading = false;
            state.deleteAccount.error = null;
            state.deleteAccount.data = action.payload.data;
        },
        deleteAccountUserError: (state, action) => {
            state.deleteAccount.isLoading = false;
            state.deleteAccount.data = null;
            state.deleteAccount.error = action.payload;
        },
        deleteAccountUserReset: (state, action) => {
            state.deleteAccount = initialProfileState.deleteAccount;
        },

        viewingAsUpdate: (state, action) => {
            state.viewingAs.data = action.payload;
        },
        viewingAsReset: (state, action) => {
            state.viewingAs = initialProfileState.viewingAs;
        },

        ChangeClientLayoutState: (state, action) => {
            state.isClientLayout = action.payload;
        },

        ProfileMasterReset: () => initialProfileState,
    },
});

export const getProfileApi = () => async (dispatch) => {
    dispatch(requestingProfileApiCall());
    apiService
        .get(endPoints.auth.profile, {})
        .then((response) => {
            dispatch(profileApiCallSuccess(response.data));
            dispatch(subscriptionDetailsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(profileApiCallError(formatAxiosErrorMessage(error)));
        });
};

export const updateToken = (data) => async (dispatch) => {
    apiService
        .post(endPoints.auth.fcm_token, data)
        .then((response) => {
            // console.log(response.data)
        })
        .catch((error) => {
            dispatch(profileApiCallError(formatAxiosErrorMessage(error)));
        });
};

export const fetchProfileApi = (id) => async (dispatch) => {
    dispatch(fetchProfileRequesting());
    apiService
        .get(endPoints.auth.register + id + "/", {})
        .then((response) => {
            dispatch(fetchProfileSuccess(response.data));
        })
        .catch((error) => {
            dispatch(fetchProfileError(formatAxiosErrorMessage(error)));
        });
};

export const updateProfileApi = (id, data) => async (dispatch) => {
    dispatch(updateProfileRequesting());
    apiService
        .patch(endPoints.auth.register + id + "/", data)
        .then((response) => {
            dispatch(updateProfileSuccess(response.data));
            dispatch(hardRefreshPage());
        })
        .catch((error) => {
            dispatch(updateProfileError(formatAxiosErrorMessage(error)));
        });
};

export const getUserPreferencesApi = (query) => async (dispatch) => {
    dispatch(userPreferencesRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(endPoints.auth.user_preferences + stringQuery, {})
        .then((response) => {
            dispatch(userPreferencesSuccess(response.data));
        })
        .catch((error) => {
            dispatch(userPreferencesError(formatAxiosErrorMessage(error)));
        });
};

export const updateUserPreferencesApi = (data, id) => async (dispatch) => {
    dispatch(updateUserPreferencesRequesting());
    apiService
        .patch(`${endPoints.auth.user_preferences}${id}/`, data)
        .then((response) => {
            dispatch(updateUserPreferencesSuccess(response.data));
            dispatch(hardRefreshPage());
        })
        .catch((error) => {
            dispatch(updateUserPreferencesError(formatAxiosErrorMessage(error)));
        });
};

export const transferUserAPI = (data) => async (dispatch) => {
    dispatch(transferUserRequesting());
    apiService
        .post(`${endPoints.auth.transfer}`, data)
        .then((response) => {
            dispatch(transferUserSuccess(response.data));
            dispatch(hardRefreshPage());
        })
        .catch((error) => {
            dispatch(transferUserError(formatAxiosErrorMessage(error)));
        });
};
export const transferListAPI = () => async (dispatch) => {
    dispatch(transferModuleListRequesting());
    apiService
        .get(`${endPoints.auth.transfer_list}`, {})
        .then((response) => {
            dispatch(transferModuleListSuccess(response.data));
        })
        .catch((error) => {
            dispatch(transferModuleListError(formatAxiosErrorMessage(error)));
        });
};

export const unmapUser = (payload) => async (dispatch) => {
    dispatch(unMapUserRequesting());
    apiService
        .post(`${endPoints.auth.unmapUser}`, payload)
        .then((response) => {
            dispatch(unMapUserSuccess(response.data));
            dispatch(hardRefreshPage());
        })
        .catch((error) => {
            dispatch(unMapUserError(formatAxiosErrorMessage(error)));
        });
};

export const deleteUserAPI = (payload) => async (dispatch) => {
    dispatch(deleteAccountUserRequesting());
    apiService
        .post(`${endPoints.auth.deleteUser}`, payload)
        .then((response) => {
            dispatch(deleteAccountUserSuccess(response.data));
            dispatch(hardRefreshPage());
        })
        .catch((error) => {
            dispatch(deleteAccountUserError(formatAxiosErrorMessage(error)));
        });
};
export const deleteAccount = (payload) => async (dispatch) => {
    dispatch(deleteAccountUserRequesting());
    apiService
        .post(`${endPoints.auth.deleteAccount}`, payload)
        .then((response) => {
            dispatch(deleteAccountUserSuccess(response.data));
        })
        .catch((error) => {
            dispatch(deleteAccountUserError(formatAxiosErrorMessage(error)));
        });
};

export const hardRefreshPage = () => async (dispatch) => {
    window.location.reload();
};

export const {
    profile,
    requestingProfileApiCall,
    profileApiCallSuccess,
    profileApiCallError,
    profileReset,

    userPreferencesRequesting,
    userPreferencesSuccess,
    userPreferencesError,
    userPreferencesReset,

    updateUserPreferencesRequesting,
    updateUserPreferencesSuccess,
    updateUserPreferencesError,
    updateUserPreferencesReset,

    fetchProfileRequesting,
    fetchProfileSuccess,
    fetchProfileError,
    fetchProfileReset,

    updateProfileRequesting,
    updateProfileError,
    updateProfileReset,
    updateProfileSuccess,

    transferUserRequesting,
    transferUserSuccess,
    transferUserError,
    transferUserReset,

    transferModuleListRequesting,
    transferModuleListSuccess,
    transferModuleListError,
    transferModuleListReset,

    unMapUserRequesting,
    unMapUserSuccess,
    unMapUserError,
    unMapUserReset,

    deleteAccountUserRequesting,
    deleteAccountUserSuccess,
    deleteAccountUserError,
    deleteAccountUserReset,

    viewingAsUpdate,
    viewingAsReset,
    ChangeClientLayoutState,
    ProfileMasterReset,
} = profileSlice.actions;
export const profileState = (state) => state.profile;
export default profileSlice.reducer;
