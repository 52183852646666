/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 06/06/23
 * Time: 12:10 pm
 * Project: W-CRM-Frontend
 */
import React, {memo, useEffect, useMemo, useState} from "react";
import "./style.css";
import {getUUID} from "../../utils/util";
import userObserver from "../../utils/userObserver";
import {Desktop, Mobile, Tablet} from "../../utils/constants";
import ListEmptyPlaceHolder from "../../component/ListContainer/ListEmptyPlaceHolder";
import Document from "../../icon/Document";

export const ListViewFix = memo(
    ({
         header = [], data = [], override = {}, onClickCallback = () => {
        }, selected
     }) => {
        const [_header, set_header] = useState(header);
        const [_data, set_data] = useState(data);
        const [matches, ref] = userObserver();

        const responsiveControls = {
            [Desktop]: Desktop,
            [Tablet]: Tablet,
            [Mobile]: Mobile,
            ...override,
        };

        useEffect(() => {
            set_data(data);
        }, [data]);

        const getListColSize = (size) => {
            if (size === "2") {
                return "list-item-w-2";
            } else if (size === "5") {
                return "list-item-w-5";
            } else if (size === "10") {
                return "list-item-w-10";
            } else if (size === "20") {
                return "list-item-w-20";
            } else if (size === "30") {
                return "list-item-w-30";
            } else if (size === "40") {
                return "list-item-w-40";
            } else if (size === "50") {
                return "list-item-w-50";
            } else if (size === "60") {
                return "list-item-w-60";
            } else if (size === "70") {
                return "list-item-w-70";
            } else if (size === "80") {
                return "list-item-w-80";
            } else if (size === "90") {
                return "list-item-w-90";
            } else if (size === "100") {
                return "list-item-w-100";
            }
        };
        const renderTableHeader = useMemo(() => {
            _header.sort((a, b) => {
                if (a.index > b.index) return 1;
                return -1;
            });

            return _header.map((header, index) => {
                let cleanTitle = header.accessor;
                let size = header.size;
                let defaultHeader = "descriptionText fatText";
                if (size) {
                    defaultHeader = `${defaultHeader} ${getListColSize(size)}`;
                }
                let visibleOn = header.visibleOn;
                let canShow = true;
                if (visibleOn) {
                    if (matches.desktop) {
                        canShow = visibleOn.filter((i) => i === Desktop).length > 0;
                    } else if (matches.tablet) {
                        canShow = visibleOn.filter((i) => i === Tablet).length > 0;
                    } else if (matches.mobile) {
                        canShow = visibleOn.filter((i) => i === Mobile).length > 0;
                    } else {
                        canShow = false;
                    }
                }

                if (canShow) {
                    return (
                        <th className={defaultHeader} key={`head_${cleanTitle}_${index}`}>
                            {/*{title}*/}
                        </th>
                    );
                }
                return null;
            });
        }, [_header, matches.desktop, matches.mobile, matches.tablet]);

        const currentScreenMapping = () => {
            const currentScreen = matches.desktop
                ? Desktop
                : matches.tablet
                    ? Tablet
                    : Mobile;
            return responsiveControls[currentScreen];
        };

        const listResponsiveness = () => {
            const screenMapping = {
                [Desktop]: "pc",
                [Tablet]: "mobile",
                [Mobile]: "mobile",
            };

            return `list-col-container ${screenMapping[currentScreenMapping()]}`;
        };

        const renderContent = useMemo(() => {
            return _data.map((row, rowIdx) => {
                let id = getUUID();
                let isSelected = selected === row.id
                let tds = _header.map((header, index) => {
                    let content = row[header.accessor];
                    let title = header.title;
                    let prefix = header.prefix;
                    let cell = header.cell;
                    let visibleOn = header.visibleOn;
                    let canShow = true;
                    if (visibleOn) {
                        if (matches.desktop) {
                            canShow = visibleOn.filter((i) => i === Desktop).length > 0;
                        } else if (matches.tablet) {
                            canShow = visibleOn.filter((i) => i === Tablet).length > 0;
                        } else if (matches.mobile) {
                            canShow = visibleOn.filter((i) => i === Mobile).length > 0;
                        } else {
                            canShow = false;
                        }
                    }
                    let dataManipulation = header.dataManipulation;
                    if (cell) {
                        content = cell(row);
                    }
                    if (dataManipulation) {
                        content = dataManipulation(row);
                    }
                    if (typeof prefix === 'function') {
                        prefix = prefix(row);
                    }
                    if (typeof title === 'function') {
                        title = title(row);
                    }

                    if (canShow) {
                        return (
                            <td
                                className={"clickable"}
                                data-title={title}
                                key={`td_${id}_${header.accessor}${getUUID()}`}
                                onClick={() => onClickCallback(row)}
                            >
                                <div className={"list-view-row-raw-item-holder"}>
                                    {prefix && <div>{prefix}</div>}
                                    {cell !== undefined ? (
                                        <>
                                            {React.isValidElement(title) &&
                                                <span className={"key"}>{title}</span>
                                            }
                                            {/*<span className={"value"}>{content}</span>*/}
                                            {content}
                                        </>
                                    ) : (
                                        <div className={listResponsiveness()}>
                                            <>
                                                <span className={"max-lines key"}>{title}</span>
                                                <span className={"max-lines value"}>
                          {content ?? "-"}
                        </span>
                                            </>
                                        </div>
                                    )}
                                </div>
                            </td>
                        );
                    }
                });
                return (
                    <tr key={id} className={`list-view-row-item-holder ${isSelected ? 'selected' : ''}`}>
                        {tds.map((td) => td)}
                    </tr>
                );
            });
        }, [_data, _header, matches.desktop, matches.mobile, matches.tablet, selected]);

        const getCss = useMemo(() => {
            const screenMapping = {
                [Desktop]: "",
                [Tablet]: "card-mode",
                [Mobile]: "card-mode",
            };
            return `list-view-holder ${screenMapping[currentScreenMapping()]}`;
        }, [matches.desktop, matches.mobile, matches.tablet]);
        return (
            <>
                {data.length > 0 ? (
                    <div className={getCss} ref={ref}>
                        <table className="list-view-table">
                            <thead>
                            <tr className={"list-view-row-head-holder"}>
                                {renderTableHeader.map((item) => item)}
                            </tr>
                            </thead>
                            <tbody className={"list-view-body-holder"}>
                            {renderContent.map((item) => item)}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <ListEmptyPlaceHolder icon={Document} text={"No items to show..."}/>
                )}
            </>
        );
    }
);
