/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 06/09/22
 * Time: 8:56 AM
 * Project: W-CRM
 */
import {createSlice} from "@reduxjs/toolkit";
import {
    ACCOUNT_SWITCHER_USER_MODEL,
    ANNOUNCEMENT_PAGE_DELETE_MODEL,
    APPOINTMENT_NEW_APPOINTMENT_MODEL,
    APPOINTMENT_PAGE_DETAIL_MODEL,
    BIG_CALENDAR_ITEM_VIEW_MODEL,
    BIG_CALENDAR_NEW_EVENT_MODEL,
    BIG_CALENDAR_TASK_DETAILS_DELETE_MODEL,
    BIG_CALENDAR_TASK_DETAILS_EDIT_MODEL,
    CALENDAR_PAGE_TASK_DELETE_CONFIRMATION_MODEL,
    CLIENT_DETAIL_PAGE_EDIT_CLIENT_MODEL,
    CLIENT_DETAILS_PAGE_EMAIL_MODEL,
    CLIENT_DETAILS_PAGE_NEW_TASK_MODEL,
    CLIENT_DETAILS_PAGE_UPDATE_MODEL,
    CLIENT_DETAILS_TASK_DELETE_CONFIRMATION_MODEL,
    CLIENT_PAGE_BOOK_APPOINTMENT_MODEL,
    CLIENT_PAGE_CREATE_NEW_CLIENT_MODEL,
    CLIENT_PAGE_DELETE_MODEL,
    CLIENT_PAGE_EDIT_CLIENT_MODEL,
    CLIENT_PAGE_EXPORT_MODEL,
    CLIENT_PAGE_WHATSAPP_CAMPAIGN_MODEL,
    CLIENT_REASSIGN_MODEL,
    COMMUNICATION_EMAIL_PREVIEW_MODEL,
    COMMUNICATION_EMAIL_REPLAY_MODEL,
    CONTACT_NEW_SCHEDULE_DRAWER,
    CUSTOM_FIELDS_PAGE_CREATE_MODEL,
    DOCUMENT_PAGE_DELETE_MODEL,
    DOCUMENT_PAGE_RENAME_MODEL,
    DOCUMENT_PAGE_SEND_MODEL,
    DOCUMENT_PAGE_UPLOAD_MODEL,
    EFORM_ALL_ASSIGNED_PAGE_ASSIGN_MODEL,
    EFORM_ALL_ASSIGNED_PAGE_DELETE_MODEL,
    EFORM_ALL_ASSIGNED_PAGE_INSIGHTS_MODEL,
    EFORM_ALL_ASSIGNED_PAGE_SEND_INVITE_MODEL,
    EFORM_ASSIGN_PAGE_REVOKE_MODEL,
    EFORM_BUILDER_PAGE_CREATE_MODEL,
    EFORM_LIST_PAGE_PREVIEW_MODEL,
    GROUP_DETAILS_DRAWER,
    GROUPS_CREATE_MODEL,
    GROUPS_DETAIL_MODEL,
    INTEGRATION_WHATSAPP_CHAT_CONFIGURATION_MODEL,
    LEAD_APPOINTMENT_DETAIL_MODEL,
    LEAD_DETAIL_APPOINTMENT_VIEW_MODEL,
    LEAD_DETAIL_DRAWER,
    LEAD_DETAIL_PAGE_NEW_NOTE_MODEL,
    LEAD_DETAIL_REASSIGN_MODEL,
    LEAD_DETAIL_STATUS_UPDATE_MODEL,
    LEAD_EDIT_DETAIL_MODEL,
    LEAD_NEW_SCHEDULE_DRAWER,
    LEAD_PAGE_BOOK_MODEL,
    LEAD_PAGE_DELETE_MODEL,
    LEAD_PAGE_EXPORT_MODEL,
    LEAD_PAGE_NEW_LEAD_MODEL,
    LEAD_PAGE_NEW_NOTE_MODEL,
    LEAD_REASSIGN_MODEL,
    LEAD_STATUS_COLOR_SELECTION_MODEL,
    LEAD_STATUS_DElETE_MODEL,
    LEAD_STATUS_UPDATE_MODEL,
    MY_INVITES_PAGE_ACCEPT_MODEL,
    MY_INVITES_PAGE_DECLINE_MODEL,
    ORG_PAGE_DELETE_MODEL,
    ORGANIZATION_EDIT_MODEL,
    ORGANIZATION_INVITE_MODEL,
    ORGANIZATION_USER_DRAWER,
    OUT_OF_OFFICE_MODEL,
    PRICING_PAGE_CALCULATED_AMOUNT_MODEL,
    PRICING_PAGE_NO_OF_PASSES_MODEL,
    PRIVATE_LAYOUT_INVITE_MODEL,
    PROFILE_PAGE_Account_DELETE_MODEL,
    PROFILE_PAGE_TRANSFER_MODAL,
    PROFILE_PAGE_UNMAP_MODEL,
    PROFILE_PAGE_USER_DELETE_MODEL,
    PROFILE_PICTURE_PREVIEW_MODEL,
    RESPONSE_VIEW_DRAWER,
    SUBSCRIPTION_EXPIRE_ALERT,
    SUBSCRIPTION_EXPIRE_ALERT_DELETE_ORG,
    SUBSCRIPTION_EXPIRE_ALERT_QUITE_ORG,
    SUBSCRIPTION_PAGE_CALCULATED_AMOUNT_MODEL,
    TASK_DETAIL_DRAWER,
    TASK_PAGE_DELETE_MODEL,
    TASK_PAGE_EDIT_TASK_MODEL,
    TASK_PAGE_TASK_DELETE_CONFIRMATION_MODEL,
    WHATSAPP_CHAT_TEMPLATE_CONFIRMATION_MODEL,
    WHATSAPP_CHAT_DELETE_MODEL,
    WHATSAPP_ENQUIRY_PAGE_CONVERT_TO_CLIENT_MODEL,
    WHATSAPP_ENQUIRY_PAGE_DELETE_MODEL,
    WHATSAPP_ENQUIRY_PAGE_DETAILS,
    WHATSAPP_BUTTON_TYPE_MODAL,
    TEMPLATE_PAGE_DELETE_MODEL,
    NEW_TEMPLATE_PAGE_PREVIEW_MODEL,
    LIST_TEMPLATE_PAGE_PREVIEW_MODEL,
    ORGANIZATION_INVITE_STATUS_MODEL,
    SUBSCRIPTION_PAGE_NO_OF_PASSES_MODEL,
    GROUP_PAGE_DELETE_MODEL,
    LEAD_PAGE_CONTACT_CONFIRMATION_MODEL,
    LEAD_DETAILS_PAGE_CONTACT_CONFIRMATION_MODEL,
} from "../../utils/constants";

const ModelInitialState = {
    active: {
        [COMMUNICATION_EMAIL_PREVIEW_MODEL]: false,
        [COMMUNICATION_EMAIL_REPLAY_MODEL]: false,
        [CLIENT_PAGE_CREATE_NEW_CLIENT_MODEL]: false,
        [WHATSAPP_ENQUIRY_PAGE_CONVERT_TO_CLIENT_MODEL]: false,
        [WHATSAPP_ENQUIRY_PAGE_DETAILS]: false,
        [PROFILE_PAGE_TRANSFER_MODAL]: false,
        [CLIENT_PAGE_EXPORT_MODEL]: false,
        [CLIENT_PAGE_WHATSAPP_CAMPAIGN_MODEL]: false,
        [LEAD_PAGE_EXPORT_MODEL]: false,
        [CLIENT_DETAIL_PAGE_EDIT_CLIENT_MODEL]: false,
        [CLIENT_PAGE_EDIT_CLIENT_MODEL]: false,
        [DOCUMENT_PAGE_UPLOAD_MODEL]: false,
        [DOCUMENT_PAGE_DELETE_MODEL]: false,
        [DOCUMENT_PAGE_RENAME_MODEL]: false,
        [CLIENT_PAGE_BOOK_APPOINTMENT_MODEL]: false,
        [DOCUMENT_PAGE_SEND_MODEL]: false,
        [EFORM_ALL_ASSIGNED_PAGE_SEND_INVITE_MODEL]: false,
        [EFORM_ALL_ASSIGNED_PAGE_DELETE_MODEL]: false,
        [EFORM_ASSIGN_PAGE_REVOKE_MODEL]: false,
        [EFORM_ALL_ASSIGNED_PAGE_ASSIGN_MODEL]: false,
        [EFORM_BUILDER_PAGE_CREATE_MODEL]: false,
        [EFORM_LIST_PAGE_PREVIEW_MODEL]: false,
        [GROUPS_DETAIL_MODEL]: false,
        [CUSTOM_FIELDS_PAGE_CREATE_MODEL]: false,
        [GROUPS_CREATE_MODEL]: false,
        [ORGANIZATION_INVITE_MODEL]: false,
        [ORGANIZATION_INVITE_STATUS_MODEL]: false,
        [ORGANIZATION_EDIT_MODEL]: false,
        [PRIVATE_LAYOUT_INVITE_MODEL]: false,
        [LEAD_STATUS_COLOR_SELECTION_MODEL]: false,
        [LEAD_STATUS_DElETE_MODEL]: false,
        [CLIENT_DETAILS_PAGE_EMAIL_MODEL]: false,
        [CLIENT_DETAILS_PAGE_UPDATE_MODEL]: false,
        [PRICING_PAGE_CALCULATED_AMOUNT_MODEL]: false,
        [SUBSCRIPTION_PAGE_CALCULATED_AMOUNT_MODEL]: false,
        [PRICING_PAGE_NO_OF_PASSES_MODEL]: false,
        [SUBSCRIPTION_PAGE_NO_OF_PASSES_MODEL]: false,
        [TASK_PAGE_TASK_DELETE_CONFIRMATION_MODEL]: false,
        [CLIENT_DETAILS_TASK_DELETE_CONFIRMATION_MODEL]: false,
        [EFORM_ALL_ASSIGNED_PAGE_INSIGHTS_MODEL]: false,
        [CLIENT_DETAILS_PAGE_NEW_TASK_MODEL]: false,
        [TASK_PAGE_EDIT_TASK_MODEL]: false,
        [WHATSAPP_CHAT_TEMPLATE_CONFIRMATION_MODEL]: false,
        [INTEGRATION_WHATSAPP_CHAT_CONFIGURATION_MODEL]: false,
        [WHATSAPP_CHAT_DELETE_MODEL]: false,
        [BIG_CALENDAR_ITEM_VIEW_MODEL]: false,
        [APPOINTMENT_PAGE_DETAIL_MODEL]: false,
        [CALENDAR_PAGE_TASK_DELETE_CONFIRMATION_MODEL]: false,
        [BIG_CALENDAR_TASK_DETAILS_EDIT_MODEL]: false,
        [BIG_CALENDAR_TASK_DETAILS_DELETE_MODEL]: false,
        [BIG_CALENDAR_NEW_EVENT_MODEL]: false,
        [APPOINTMENT_NEW_APPOINTMENT_MODEL]: false,
        [LEAD_DETAIL_APPOINTMENT_VIEW_MODEL]: false,
        [LEAD_REASSIGN_MODEL]: false,
        [ACCOUNT_SWITCHER_USER_MODEL]: false,
        [LEAD_DETAIL_REASSIGN_MODEL]: false,
        [CLIENT_REASSIGN_MODEL]: false,
        [LEAD_EDIT_DETAIL_MODEL]: false,
        [LEAD_APPOINTMENT_DETAIL_MODEL]: false,
        [LEAD_PAGE_NEW_LEAD_MODEL]: false,
        [LEAD_STATUS_UPDATE_MODEL]: false,
        [LEAD_NEW_SCHEDULE_DRAWER]: false,
        [CONTACT_NEW_SCHEDULE_DRAWER]: false,
        [LEAD_DETAIL_STATUS_UPDATE_MODEL]: false,
        [LEAD_DETAIL_PAGE_NEW_NOTE_MODEL]: false,
        [LEAD_PAGE_BOOK_MODEL]: false,
        [LEAD_PAGE_NEW_NOTE_MODEL]: false,
        [LEAD_PAGE_DELETE_MODEL]: false,
        [GROUP_PAGE_DELETE_MODEL]: false,
        [WHATSAPP_BUTTON_TYPE_MODAL]: false,
        [LEAD_PAGE_CONTACT_CONFIRMATION_MODEL]: false,
        [LEAD_DETAILS_PAGE_CONTACT_CONFIRMATION_MODEL]: false,
        [WHATSAPP_ENQUIRY_PAGE_DELETE_MODEL]: false,
        [MY_INVITES_PAGE_DECLINE_MODEL]: false,
        [MY_INVITES_PAGE_ACCEPT_MODEL]: false,
        [PROFILE_PAGE_USER_DELETE_MODEL]: false,
        [PROFILE_PAGE_Account_DELETE_MODEL]: false,
        [PROFILE_PAGE_UNMAP_MODEL]: false,
        [ANNOUNCEMENT_PAGE_DELETE_MODEL]: false,
        [ORG_PAGE_DELETE_MODEL]: false,
        [CLIENT_PAGE_DELETE_MODEL]: false,
        [TEMPLATE_PAGE_DELETE_MODEL]: false,
        [LIST_TEMPLATE_PAGE_PREVIEW_MODEL]: false,
        [NEW_TEMPLATE_PAGE_PREVIEW_MODEL]: false,
        [TASK_PAGE_DELETE_MODEL]: false,
        [OUT_OF_OFFICE_MODEL]: false,
        [PROFILE_PICTURE_PREVIEW_MODEL]: false,
        [ORGANIZATION_USER_DRAWER]: false,
        [GROUP_DETAILS_DRAWER]: false,
        [RESPONSE_VIEW_DRAWER]: false,
        [LEAD_DETAIL_DRAWER]: false,
        [TASK_DETAIL_DRAWER]: false,
        [SUBSCRIPTION_EXPIRE_ALERT]: false,
        [SUBSCRIPTION_EXPIRE_ALERT_DELETE_ORG]: false,
        [SUBSCRIPTION_EXPIRE_ALERT_QUITE_ORG]: false,
    },
};

export const ModelSlice = createSlice({
    name: "model",
    initialState: {
        ...ModelInitialState,
    },
    reducers: {
        ActivateModel: (state, action) => {
            state.active[action.payload] = true;
        },
        DeActivateModel: (state, action) => {
            state.active[action.payload] = false;
        },
        ModelMasterReset: () => ModelInitialState,
    },
});

export const {ActivateModel, DeActivateModel, ModelMasterReset} =
    ModelSlice.actions;
export const ModelState = (state) => state.model;
export default ModelSlice.reducer;
