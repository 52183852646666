/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 08/11/22
 * Time: 4:53 PM
 * Project: W-CRM
 */
import React from "react";
import "./style.css";
import Tooltip from "../Tooltip/Tooltip";
import { getUUID } from "../../utils/util";

export default function Card({
  title = null,
  children,
  footer = null,
  childWrap = false,
  customCss = null,
  headerActions = [],
  ...props
}) {
  let cardCSS = "card-holder-body";
  if (!childWrap) {
    cardCSS = `no-card-wrap ${cardCSS}`;
  }
  return (
    <div
      className={`card-parent-holder ${customCss ? customCss : ""}`}
      {...props}
    >
      <div className={"card-header-holder"}>
        {title && <p className={"card-header-title"}>{title}</p>}
        {headerActions.map((i) => {
          const { icon: Icon, tooltip, action } = i;
          return (
            <Tooltip toolTip={tooltip} key={tooltip+getUUID()}>
              <div
                className={"card-action-holder clickable"}
                onClick={() => {
                  if (action) {
                    action();
                  }
                }}
              >
                <Icon className={"w-1-25"} strokeWidth={2.4} />
              </div>
            </Tooltip>
          );
        })}
      </div>

      <div className={cardCSS}>{children}</div>

      {footer && <div className={"card-footer-holder"}>{footer}</div>}
    </div>
  );
}
