/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 26/09/22
 * Time: 9:14 AM
 * Project: W-CRM
 */
import {createSlice} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import {endPoints} from "../../api/endpoints";
import {apiUrlBuilder} from "../../utils/util";

const LeadInitialState = {
    leadGet: {
        isLoading: false,
        data: null,
        error: null,
    },
    leadRetrieve: {
        isLoading: false,
        data: null,
        error: null,
    },
    calendarAppointment: {
        isLoading: false,
        data: null,
        error: null,
    },
    calendarTask: {
        isLoading: false,
        data: null,
        error: null,
    },

    leadStat: {
        isLoading: false,
        data: null,
        error: null,
    },

    leadUsers: {
        isLoading: false,
        data: null,
        error: null,
    },

    leadUpdate: {
        isLoading: false,
        data: null,
        error: null,
    },
    leadDelete: {
        isLoading: false,
        isDeleted: false,
        error: null,
    },
    export: {
        isLoading: false,
        data: false,
        error: null,
    },
    growth: {
        isLoading: false,
        data: null,
        error: null,
    },
    lead_chart: {
        isLoading: false,
        data: null,
        error: null,
    },
};

export const LeadSlice = createSlice({
    name: "lead",
    initialState: {
        ...LeadInitialState,
    },
    reducers: {
        GetLeadRequesting: (state, action) => {
            state.leadGet.isLoading = true;
        },
        GetLeadSuccess: (state, action) => {
            state.leadGet.isLoading = false;
            state.leadGet.error = null;
            state.leadGet.data = action.payload.data;
        },
        GetLeadError: (state, action) => {
            state.leadGet.isLoading = false;
            state.leadGet.error = action.payload;
        },
        GetLeadReset: (state, action) => {
            state.leadGet = LeadInitialState.leadGet;
        },

        CalendarAppointmentRequesting: (state, action) => {
            state.calendarAppointment.isLoading = true;
        },
        CalendarAppointmentSuccess: (state, action) => {
            state.calendarAppointment.isLoading = false;
            state.calendarAppointment.error = null;
            state.calendarAppointment.data = action.payload.data;
        },
        CalendarAppointmentError: (state, action) => {
            state.calendarAppointment.isLoading = false;
            state.calendarAppointment.error = action.payload;
        },
        CalendarAppointmentReset: (state, action) => {
            state.calendarAppointment = LeadInitialState.calendarAppointment;
        },

        CalendarTaskRequesting: (state, action) => {
            state.calendarTask.isLoading = true;
        },
        CalendarTaskSuccess: (state, action) => {
            state.calendarTask.isLoading = false;
            state.calendarTask.error = null;
            state.calendarTask.data = action.payload.data;
        },
        CalendarTaskError: (state, action) => {
            state.calendarTask.isLoading = false;
            state.calendarTask.error = action.payload;
        },
        CalendarTaskReset: (state, action) => {
            state.calendarTask = LeadInitialState.calendarTask;
        },

        LeadStatRequesting: (state, action) => {
            state.leadStat.isLoading = true;
        },
        LeadStatSuccess: (state, action) => {
            state.leadStat.isLoading = false;
            state.leadStat.error = null;
            state.leadStat.data = action.payload.data;
        },
        LeadStatError: (state, action) => {
            state.leadStat.isLoading = false;
            state.leadStat.error = action.payload;
        },
        LeadStatReset: (state, action) => {
            state.leadStat = LeadInitialState.leadStat;
        },

        LeadUsersRequesting: (state, action) => {
            state.leadUsers.isLoading = true;
        },
        LeadUsersSuccess: (state, action) => {
            state.leadUsers.isLoading = false;
            state.leadUsers.error = null;
            state.leadUsers.data = action.payload.data;
        },
        LeadUsersError: (state, action) => {
            state.leadUsers.isLoading = false;
            state.leadUsers.error = action.payload;
        },
        LeadUsersReset: (state, action) => {
            state.leadUsers = LeadInitialState.leadRetrieve;
        },

        LeadUpdateRequesting: (state, action) => {
            state.leadUpdate.isLoading = true;
        },
        LeadUpdateSuccess: (state, action) => {
            state.leadUpdate.isLoading = false;
            state.leadUpdate.error = null;
            state.leadUpdate.data = action.payload.data;
        },
        LeadUpdateError: (state, action) => {
            state.leadUpdate.isLoading = false;
            state.leadUpdate.error = action.payload;
        },
        LeadUpdateReset: (state, action) => {
            state.leadUpdate = LeadInitialState.leadUpdate;
        },

        LeadRetrieveRequesting: (state, action) => {
            state.leadRetrieve.isLoading = true;
        },
        LeadRetrieveSuccess: (state, action) => {
            state.leadRetrieve.isLoading = false;
            state.leadRetrieve.error = null;
            state.leadRetrieve.data = action.payload.data;
        },
        LeadRetrieveError: (state, action) => {
            state.leadRetrieve.isLoading = false;
            state.leadRetrieve.error = action.payload;
        },
        LeadRetrieveReset: (state, action) => {
            state.leadRetrieve = LeadInitialState.leadUpdate;
        },

        LeadDeleteRequesting: (state, action) => {
            state.leadDelete.isLoading = true;
        },
        LeadDeleteSuccess: (state, action) => {
            state.leadDelete.isLoading = false;
            state.leadDelete.error = null;
            state.leadDelete.isDeleted = true;
        },
        LeadDeleteError: (state, action) => {
            state.leadDelete.isLoading = false;
            state.leadDelete.error = action.payload;
        },
        LeadDeleteReset: (state, action) => {
            state.leadDelete = LeadInitialState.leadDelete;
        },

        LeadExportRequesting: (state, action) => {
            state.export.isLoading = true;
        },
        LeadExportSuccess: (state, action) => {
            state.export.isLoading = false;
            state.export.error = null;
            state.export.data = "files";
        },
        LeadExportError: (state, action) => {
            state.export.isLoading = false;
            state.export.error = action.payload;
        },
        LeadExportReset: (state, action) => {
            state.export = LeadInitialState.export;
        },

        LeadGrowthRequesting: (state, action) => {
            state.growth.isLoading = true;
        },
        LeadGrowthSuccess: (state, action) => {
            state.growth.isLoading = false;
            state.growth.error = null;
            state.growth.data = action.payload.data;
        },
        LeadGrowthError: (state, action) => {
            state.growth.isLoading = false;
            state.growth.error = action.payload;
        },
        LeadGrowthReset: (state, action) => {
            state.growth = LeadInitialState.growth;
        },


        LeadChartRequesting: (state, action) => {
            state.lead_chart.isLoading = true;
        },
        LeadChartSuccess: (state, action) => {
            state.lead_chart.isLoading = false;
            state.lead_chart.error = null;
            state.lead_chart.data = action.payload.data;
        },
        LeadChartError: (state, action) => {
            state.lead_chart.isLoading = false;
            state.lead_chart.error = action.payload;
        },
        LeadChartReset: (state, action) => {
            state.lead_chart = LeadInitialState.lead_chart;
        },

        LeadMasterReset: () => LeadInitialState,
    },
});

export const getLeadAPI = (query) => async (dispatch) => {
    dispatch(GetLeadRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.lead.get}${stringQuery}`, {})
        .then((response) => {
            dispatch(GetLeadSuccess(response.data));
        })
        .catch((error) => dispatch(GetLeadError(formatAxiosErrorMessage(error))));
};

export const getCalendarAppointmentAPI = (query) => async (dispatch) => {
    dispatch(CalendarAppointmentRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.lead.calendarAppointment}${stringQuery}`, {})
        .then((response) => {
            dispatch(CalendarAppointmentSuccess(response.data));
        })
        .catch((error) =>
            dispatch(CalendarAppointmentError(formatAxiosErrorMessage(error)))
        );
};

export const getCalendarTaskAPI = (query) => async (dispatch) => {
    dispatch(CalendarTaskRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.lead.calendarTask}${stringQuery}`, {})
        .then((response) => {
            dispatch(CalendarTaskSuccess(response.data));
        })
        .catch((error) =>
            dispatch(CalendarTaskError(formatAxiosErrorMessage(error)))
        );
};

export const getLeadStatAPI = (query) => async (dispatch) => {
    dispatch(LeadStatRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.lead.leadStat}${stringQuery}`, {})
        .then((response) => {
            dispatch(LeadStatSuccess(response.data));
        })
        .catch((error) => dispatch(LeadStatError(formatAxiosErrorMessage(error))));
};

export const getLeadUsersAPI = (query) => async (dispatch) => {
    dispatch(LeadUsersRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.lead.leadUsers}${stringQuery}`, {})
        .then((response) => {
            dispatch(LeadUsersSuccess(response.data));
        })
        .catch((error) => dispatch(LeadUsersError(formatAxiosErrorMessage(error))));
};

export const UpdateLeadAPI = (data) => async (dispatch) => {
    dispatch(LeadUpdateRequesting());
    apiService
        .patch(`${endPoints.lead.get}${data.id}/`, data)
        .then((response) => {
            dispatch(LeadUpdateSuccess(response.data));
            // Toast(toastMessages.leadUpdatedSuccess, "success");
        })
        .catch((error) => {
            // Toast(toastMessages.leadUpdatedFailed, "disappear-error");
            dispatch(LeadUpdateError(formatAxiosErrorMessage(error)));
        });
};

export const RetrieveLeadAPI = (id) => async (dispatch) => {
    dispatch(LeadRetrieveRequesting());
    apiService
        .get(`${endPoints.lead.get}${id}/`, {})
        .then((response) => {
            dispatch(LeadRetrieveSuccess(response.data));
        })
        .catch((error) => {
            dispatch(LeadRetrieveError(formatAxiosErrorMessage(error)));
        });
};

export const DeleteLeadApi = (id) => async (dispatch) => {
    dispatch(LeadDeleteRequesting());
    apiService
        .delete(`${endPoints.lead.get}${id}/`, {})
        .then((response) => {
            dispatch(LeadDeleteSuccess(response.data));
        })
        .catch((error) => {
            dispatch(LeadDeleteError(formatAxiosErrorMessage(error)));
        });
};

export const ExportLeadApi = (query = {}) => async (dispatch) => {
    dispatch(LeadExportRequesting());
    // console.log(query)
    apiService
        .get(apiUrlBuilder(endPoints.lead.export, null, query), {
            responseType: 'blob',
            "Access-Control-Expose-Headers": '*',
        })
        .then((response) => {
            const filenameHeader = response.headers['content-disposition'] || response.headers['Content-Disposition'];
            // console.log(response)
            // console.log(filenameHeader)

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_lead.csv');
            document.body.appendChild(link);
            link.click();
            dispatch(LeadExportSuccess())
        })
        .catch((error) =>
            dispatch(LeadExportError(formatAxiosErrorMessage(error)))
        );
};

export const LeadGrowthAPI = () => async (dispatch) => {
    dispatch(LeadGrowthRequesting());
    apiService
        .get(`${endPoints.lead.growth}`, {})
        .then((response) => {
            dispatch(LeadGrowthSuccess(response.data));
        })
        .catch((error) => {
            dispatch(LeadGrowthError(formatAxiosErrorMessage(error)));
        });
};

export const LeadChartAPI = () => async (dispatch) => {
    dispatch(LeadChartRequesting());
    apiService
        .get(`${endPoints.lead.lead_chart}`, {})
        .then((response) => {
            dispatch(LeadChartSuccess(response.data));
        })
        .catch((error) => {
            dispatch(LeadChartError(formatAxiosErrorMessage(error)));
        });
};

export const {
    GetLeadRequesting,
    GetLeadSuccess,
    GetLeadError,
    GetLeadReset,

    CalendarAppointmentRequesting,
    CalendarTaskRequesting,
    CalendarAppointmentReset,
    CalendarTaskSuccess,
    CalendarAppointmentError,
    CalendarTaskReset,
    CalendarTaskError,
    CalendarAppointmentSuccess,

    LeadStatRequesting,
    LeadStatSuccess,
    LeadStatError,
    LeadStatReset,

    LeadUsersRequesting,
    LeadUsersSuccess,
    LeadUsersError,
    LeadUsersReset,

    LeadUpdateRequesting,
    LeadUpdateSuccess,
    LeadUpdateError,
    LeadUpdateReset,

    LeadRetrieveRequesting,
    LeadRetrieveSuccess,
    LeadRetrieveError,
    LeadRetrieveReset,

    LeadDeleteRequesting,
    LeadDeleteSuccess,
    LeadDeleteError,
    LeadDeleteReset,

    LeadExportRequesting,
    LeadExportSuccess,
    LeadExportError,
    LeadExportReset,

    LeadGrowthRequesting,
    LeadGrowthSuccess,
    LeadGrowthError,
    LeadGrowthReset,

    LeadChartRequesting,
    LeadChartSuccess,
    LeadChartError,
    LeadChartReset,

    LeadMasterReset,
} = LeadSlice.actions;
export const LeadState = (state) => state.lead;
export default LeadSlice.reducer;
