/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 11/05/23
 * Time: 10:59 am
 * Project: W-CRM-Frontend
 */
import React, {memo, useMemo} from "react";
import "./style.css";

export const NameIcon = memo(({char = "!", color, icon, size = "small", alert = false, src = null}) => {
    let colorSet = [
        "blue-set",
        "orange-set",
        "success-set",
        "red-set",
        "violet-set",
    ];
    const nameCSS = useMemo(() => {
        let css = "name-icon-container";
        if (color) {
            css = `${css} ${color}`;
        } else {
            css = `${css} ${colorSet[Math.floor(Math.random() * colorSet.length)]}`;
        }
        if (size === "medium") {
            css = `${css} medium`;
        } else if (size === "large") {
            css = `${css} large`;
        } else {
            css = `${css} small`;
        }

        if (alert) {
            css = `${css} name-alert`;
        }

        return css;
    }, [char]);
    return (
        <div className={nameCSS}>

            {src ?
                <img src={src}
                     style={{width: "100%"}}/>
                :
                <>{icon ? (
                    icon
                ) : (
                    <h1 className={"name-icon-character text-uppercase"}>{char}</h1>
                )}</>
            }


        </div>
    );
});
