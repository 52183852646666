import React from "react";
import UrlRoutes from "./routes/UrlRoutes";
import store from "./redux/store";
import {Provider} from "react-redux";
import {Slide, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {addCssAttribute} from "./utils/util";
import 'react-tooltip/dist/react-tooltip.css'
import {MaintenancePage} from "./pages/MaintenancePage/MaintenancePage";
import {IS_APP_MAINTENANCE} from "./utils/constants";

function App() {
    addCssAttribute("--primary-dark", process.env.REACT_APP_PRIMARY_DARK_COLOR);
    addCssAttribute("--primary", process.env.REACT_APP_PRIMARY_COLOR);
    return (
        <>{IS_APP_MAINTENANCE === 'false' ?
            <>
                <Provider store={store}>
                    <UrlRoutes/>
                </Provider>
                <ToastContainer transition={Slide}/></> :
            <MaintenancePage/>
        }
        </>
    );
}

export default App;
