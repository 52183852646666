/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/14/2022
 * Time: 10:35 AM
 * Project: w_crm
 */
import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import loadingReducer from "./loading/loadingReducer";
import profileReducer from "./profile/profileReducer";
import subscriptionReducer from "./subscription/subscriptionReducer";
import navReducer from "./navigation/navReducer";
import E_formReducer from "./e_form/e_formReducer";
import ClientReducer from "./client/clientReducer";
import DocumentReducer from "./document/documentReducer";
import SyncReducer from "./sync/syncReducer";
import CommunicationReducer from "./Communication/CommunicationReducer";
import OrganizationReducer from "./Organization/organizationReducer";
import GroupReducer from "./Group/groupReducer";
import ModelReducer from "./Model/modelReducer";
import LeadReducer from "./Lead/leadReducer";
import LeadWidgetReducer from "./Application/LeadWidget/LeadWidgetReducer";
import taskReducer from "./Application/Task/taskReducer";
import testReducer from "./client/testReducer";
import {apiServiceVisionMiddleware, setDispatch} from "../api/apiService";
import analyticsReducer from "./Analytics/analyticsReducer";
import announcementReducer from "./Announcement/announcementReducer";
import whatsappReducer from "./Integration/whatsappReducer";

const store = configureStore({
        reducer: {
            loading: loadingReducer,
            auth: authReducer,
            profile: profileReducer,
            subscription: subscriptionReducer,
            navigation: navReducer,
            e_form: E_formReducer,
            client: ClientReducer,
            document: DocumentReducer,
            sync: SyncReducer,
            communication: CommunicationReducer,
            organization: OrganizationReducer,
            group: GroupReducer,
            model: ModelReducer,
            lead: LeadReducer,
            lead_widget: LeadWidgetReducer,
            task: taskReducer,
            test: testReducer,
            analytics: analyticsReducer,
            announcement: announcementReducer,

            // Integration
            whatsapp: whatsappReducer
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false,}).concat(apiServiceVisionMiddleware),
    })
;
setDispatch(store.dispatch);
export default store