/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 2/28/2022
 * Time: 7:30 PM
 * Project: w_crm
 */
import React from "react";
import "./style.css";
import Spinner from "../Spinner/Spinner";

export default function Button({
  children,
  type = "primary",
  htmlType = "button",
  size = "default",
  className = "",
  disabled = false,
  status = "normal",
  isLoading = false,
  ...props
}) {
  const cssClass = () => {
    let css = `no-border button-component d-flex item-center shadow-sm `;

    //Button Type Divisions
    if (type === "chip") {
      css = `${css} pl-1 pr-1 br-18`;
    } else if (type === "primary") {
      css = `${css} button-component-primary-btn br-8`;
    } else if (type === "slotChip") {
      css = `${css} black-outline bg-white`;
    } else if (type === "link") {
      css = `${css} button-component-link-btn`;
    } else if (type === "outline") {
      css = `${css} button-component-outline-btn`;
    } else if (type === "square") {
      css = `${css} button-component-square-btn`;
    }

    if (status === "success") {
      css = `${css} button-component-success-btn`;
    } else if (status === "error") {
      css = `${css} button-component-error-btn`;
    } else if (status === "selected") {
      css = `${css} selected-btn`;
    } else if (status === "non-selected") {
      css = `${css} button-component-non-selected-btn`;
    } else {
      css = `${css} button-component-normal-btn`;
    }

    //Button Size Divisions
    if (size === "default") {
      css = `${css} px-3 py-2`;
    } else if (size === "small") {
      css = `${css} px-2-5 py-1-5`;
    } else if (size === "large") {
      css = `${css} px-3-5 py-2-5`;
    } else if (size === "x-large") {
      css = `${css} pl-5 pr-5 pt-2 pb-2`;
    } else if (size === "large-square") {
      css = `${css} p-05`;
    }

    return `${
      disabled ? "cursor-not-allowed opacity-05" : "cursor-clickable opacity-1"
    } ${css} ${className}`;
  };

  return (
    <button
      {...props}
      disabled={disabled || isLoading}
      type={htmlType}
      className={cssClass()}
    >
      {isLoading && <Spinner showasIcon={true} />}
      <span className={"text-default"}>{children !== undefined && children}</span>
    </button>
  );
}
