import React, {forwardRef} from "react";
import "./style.css";
import Input from "../../../Input/Input";
import Spinner from "../../../Spinner/Spinner";
import Button from "../../../Button/Button";

const SelectList = forwardRef(
    (
        {
            isSearch,
            isLoading,
            search,
            options,
            onItemSelect,
            selected = [],
            direction = "bottom",
            extraActions = [],
            showExtraActions = true,
        },
        ref
    ) => {
        return (
            <ul className={`select-list-holder ${direction}`} ref={ref}>
                {isSearch && (
                    <li className={"select-search-holder"}>
                        <Input
                            placeHolder={"Search..."}
                            onChange={search}
                            // leading={isLoading ? <Spinner showasIcon={true} /> : null}
                        />
                    </li>
                )}
                <Spinner
                    isSpinning={isLoading}
                    childClassName={"selectList-spinner-override"}
                >
                    {options.map((item) => {
                        let OptionSelectedCSS = "";
                        if (selected.includes(item.id)) {
                            OptionSelectedCSS = "selected";
                        }
                        if (item.disabled) {
                            OptionSelectedCSS = `${OptionSelectedCSS} disabled`;
                        }
                        return (
                            <li className={OptionSelectedCSS}
                                key={item.id}
                                onClick={(event) => {
                                    if (!item.disabled) {
                                        event.stopPropagation();
                                        onItemSelect(item.id);
                                    }
                                }}
                            >
                                {item.value}
                                {item.description &&
                                    <p className={"select-list-item-holder text--ultraSmall"}>{item.description}</p>
                                }
                            </li>
                        );
                    })}


                    {showExtraActions && (
                    <>
                        {extraActions?.map(a => {
                            return <li className={"select-search-holder no-gap-list-item"}>
                                {a}
                            </li>
                        })}
                    </>
                )}
                </Spinner>
            </ul>
        );
    }
);

export default SelectList;
