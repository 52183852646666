/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 07/11/22
 * Time: 7:11 PM
 * Project: W-CRM
 */
import React, {useCallback, useEffect, useMemo, useRef, useState,} from "react";
import "./style.css";
import SelectList from "../Select/Select/SelectList/SelectList";
import CloseCircle from "../../icon/CloseCircle";
import Input from "../Input/Input";
import ChevronDown from "../../icon/ChevronDown";
import ChevronUp from "../../icon/ChevronUp";
import {debounce} from "../../utils/common";
import Spinner from "../Spinner/Spinner";

export default function ModernSelect({
                                         isSearch = true,
                                         isLoading = false,
                                         disabled,
                                         placeholder = "Select",
                                         options = [],
                                         preSelected = "",
                                         onSelect = () => {
                                         },
                                         showClear = true,
                                         input_name = "",
                                         inputClassName = "py-2-5",
                                         headerIcon: HeaderIcon = null,
                                         error = null,
                                         showError = false,
                                         searchCallback = null,
                                         listFlowDirection = "bottom",
                                         extraActions = [],
                                         showExtraActions = true,
                                         ...props
                                     }) {
    const [focus, setFocus] = useState(false);
    const [selected, setSelected] = useState(preSelected);

    const [displayOption, setDisplayOption] = useState(options);

    useEffect(() => {
        if (
            options.filter((i) => i.value === preSelected || i.id === preSelected)[0]
        ) {
            setSelected(preSelected);
        } else {
            setSelected(null);
        }
    }, [options, preSelected]);

    useEffect(() => {
        setDisplayOption(options);
    }, [options]);

    const componentRef = useRef();

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        function handleClick(e) {
            if (componentRef && componentRef.current) {
                const ref = componentRef.current;
                if (!ref.contains(e.target)) {
                    setFocus(false);
                }
            }
        }
    }, []);

    const onValueUpdate = useCallback((key) => {
        if (searchCallback) {
            searchCallback(key);
        }
    }, []);

    const delayedValueUpdate = useMemo(
        () => debounce(onValueUpdate, 500),
        [onValueUpdate]
    );

    const optionSearch = (keyword) => {
        if (searchCallback) {
            delayedValueUpdate(keyword);
        } else {
            setInputText(keyword);
            setDisplayOption(
                options.filter((item) =>
                    item.value.toLowerCase().includes(keyword.toLowerCase())
                )
            );
        }
    };

    const [inputText, setInputText] = useState("");


    const clearSelectClick = (event) => {
        event.stopPropagation();

        onSelect("");
        setSelected("");
        setFocus(false);
    };
    return (
        <div
            className={"modern-select-holder"}
            ref={componentRef}
            onClick={() => {
                if (!disabled) {
                    setFocus(true);
                }
            }}
        >
            <Input
                wrapperClassName={"modern-select-input-wrapper"}
                inputClassName={inputClassName}
                error={error}
                showError={showError}
                name={input_name}
                leading={
                    !focus ? isLoading ? <Spinner showasIcon={true}/> : HeaderIcon : null
                }
                preValue={options.filter((i) => i.id === selected)[0]?.value ?? ""}
                onClick={() => {
                    if (!disabled) {
                        setFocus(true);
                    }
                }}
                placeholder={placeholder}
                readOnly={true}
                disabled={disabled}
                trailing={
                    <>
                        {selected?.length > 0 && showClear && (
                            <CloseCircle strokeWidth={2} onClick={clearSelectClick}/>
                        )}
                        {focus ? (
                            <div
                                className={"modernSelect-arrow-holder"}
                                onClick={(e) => {
                                    if (!disabled) {
                                        e.stopPropagation();
                                        setFocus(false);
                                    }
                                }}
                            >
                                <ChevronUp strokeWidth={2}/>
                            </div>
                        ) : (
                            <div
                                className={"modernSelect-arrow-holder"}
                                onClick={(e) => {
                                    if (!disabled) {
                                        e.stopPropagation();
                                        setFocus(true);
                                    }
                                }}
                            >
                                <ChevronDown strokeWidth={2}/>
                            </div>
                        )}
                    </>
                }
                {...props}
            />

            {focus && (
                <SelectList
                    isSearch={isSearch}
                    isLoading={isLoading}
                    search={optionSearch}
                    onItemSelect={(id) => {
                        onSelect(id);
                        setSelected(id);
                        setFocus(false);
                    }}
                    options={displayOption}
                    selected={[selected]}
                    direction={listFlowDirection}
                    extraActions={extraActions}
                    showExtraActions={showExtraActions}
                />
            )}
        </div>
    );
}
