/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 20/09/22
 * Time: 2:35 PM
 * Project: W-CRM
 */
import React from "react";
import "./style.css";
import Question from "../../icon/Question";
import Tooltip from "../Tooltip/Tooltip";

export default function InfoLabel({
  children,
  label = null,
  tooltip = null,
  isOptional = false,
  required = false,
  labelColor,
}) {
  let labelCss = `info-label-text ${labelColor} `;
  return (
    <div className={"info-label-parent-container"}>
      {label && (
        <div className={"info-label-header"}>
          <p className={labelCss}>
            {label} {required && <span className="red-text">*</span>}
          </p>
          {tooltip !== null && (
            <Tooltip toolTip={tooltip}>
              <Question className={"w-1-25 info-label-icon"} strokeWidth={2} />
            </Tooltip>
          )}
          {isOptional && <span>(Optional)</span>}
        </div>
      )}
      {children && <div className={"info-label-body"}>{children}</div>}
    </div>
  );
}
