/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 28/12/23
 * Time: 11:32 am
 * Project: Waxis
 */
import {createSlice, current} from "@reduxjs/toolkit"
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import {removeDuplicates} from "../../utils/util";
import {endPoints} from "../../api/endpoints";


const AnnouncementInitialState = {
    list: {
        isLoading: false,
        data: null,
        error: null,
    },
    create: {
        isLoading: false,
        data: null,
        error: null,
    },
    delete: {
        isLoading: false,
        data: null,
        error: null,
    },
    update: {
        isLoading: false,
        data: null,
        error: null,
    }
}

export const AnnouncementSlice = createSlice({
    name: "announcement",
    initialState: {
        ...AnnouncementInitialState
    }, reducers: {
        FetchListRequesting: (state, action) => {
            state.list.isLoading = true
        },
        FetchListSuccess: (state, action) => {
            const {list} = current(state);
            const newData = action.payload.data;
            if (list?.data?.results) {
                newData.results = removeDuplicates([
                    ...list.data.results,
                    ...newData.results,
                ]);
            }
            state.list.isLoading = false;
            state.list.error = null;
            state.list.data = newData;
        },
        FetchListError: (state, action) => {
            state.list.isLoading = false
            state.list.error = action.payload
        },
        FetchListReset: (state, action) => {
            state.list = AnnouncementInitialState.list
        },


        CreateAppointmentRequesting: (state, action) => {
            state.create.isLoading = true
        },
        CreateAppointmentSuccess: (state, action) => {
            state.create.isLoading = false;
            state.create.error = null;
            state.create.data = action.payload.data;
        },
        CreateAppointmentError: (state, action) => {
            state.create.isLoading = false
            state.create.error = action.payload
        },
        CreateAppointmentReset: (state, action) => {
            state.create = AnnouncementInitialState.create
        },

        DeleteAppointmentRequesting: (state, action) => {
            state.delete.isLoading = true
        },
        DeleteAppointmentSuccess: (state, action) => {
            state.delete.isLoading = false;
            state.delete.error = null;
            state.delete.data = "deleted";
        },
        DeleteAppointmentError: (state, action) => {
            state.delete.isLoading = false
            state.delete.error = action.payload
        },
        DeleteAppointmentReset: (state, action) => {
            state.delete = AnnouncementInitialState.delete
        },

        UpdateAppointmentRequesting: (state, action) => {
            state.update.isLoading = true
        },
        UpdateAppointmentSuccess: (state, action) => {
            state.update.isLoading = false;
            state.update.error = null;
            state.update.data = action.payload;
        },
        UpdateAppointmentError: (state, action) => {
            state.update.isLoading = false
            state.update.error = action.payload
        },
        UpdateAppointmentReset: (state, action) => {
            state.update = AnnouncementInitialState.update
        },
    }
})

export const fetchAnnouncementAPI = (query) => async dispatch => {
    dispatch(FetchListRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.announcement.base}${stringQuery}`, {})
        .then((response) => {
            dispatch(FetchListSuccess(response.data))
        })
        .catch((error) => dispatch(FetchListError(formatAxiosErrorMessage(error))));
}

export const createAnnouncementAPI = (data) => async dispatch => {
    dispatch(CreateAppointmentRequesting());
    apiService
        .post(`${endPoints.announcement.base}`, data)
        .then((response) => {
            dispatch(CreateAppointmentSuccess(response.data))
        })
        .catch((error) => dispatch(CreateAppointmentError(formatAxiosErrorMessage(error))));
}

export const deleteAnnouncementAPI = (id) => async dispatch => {
    dispatch(DeleteAppointmentRequesting());
    apiService
        .delete(`${endPoints.announcement.base}${id}/`, {})
        .then((response) => {
            dispatch(DeleteAppointmentSuccess())
        })
        .catch((error) => dispatch(DeleteAppointmentError(formatAxiosErrorMessage(error))));
}

export const updateAnnouncementAPI = (id, data) => async dispatch => {
    dispatch(UpdateAppointmentRequesting());
    apiService
        .patch(`${endPoints.announcement.base}${id}/`, data)
        .then((response) => {
            dispatch(UpdateAppointmentSuccess(response.data))
        })
        .catch((error) => dispatch(UpdateAppointmentError(formatAxiosErrorMessage(error))));
}

export const {
    FetchListRequesting,
    FetchListSuccess,
    FetchListError,
    FetchListReset,

    CreateAppointmentRequesting,
    CreateAppointmentSuccess,
    CreateAppointmentError,
    CreateAppointmentReset,

    DeleteAppointmentRequesting,
    DeleteAppointmentSuccess,
    DeleteAppointmentError,
    DeleteAppointmentReset,

    UpdateAppointmentRequesting,
    UpdateAppointmentSuccess,
    UpdateAppointmentError,
    UpdateAppointmentReset
} = AnnouncementSlice.actions;
export const AnnouncementState = (state) => state.announcement
export default AnnouncementSlice.reducer