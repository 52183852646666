import {endPoints} from "../api/endpoints";
import {URL_VERIFY_EMAIL} from "./url";

/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 23/11/23
 * Time: 3:25 pm
 * Project: Waxis
 */
export const NON_USER_FILTER_WHITELIST = [endPoints.auth.user_preferences, endPoints.auth.register]
export const ALLOW_WHEN_IN_USER_VIEW = [endPoints.auth.logout, endPoints.auth.user_preferences, endPoints.auth.register, endPoints.auth.change_password]
export const ALLOW_PUBLIC_WITH_VALID_LOGIN = [new RegExp(URL_VERIFY_EMAIL.replace(":level1", "[^/]+"))]