/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 06/07/23
 * Time: 11:43 pm
 * Project: W-CRM-Frontend
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SyncState } from "../redux/sync/syncReducer";
import { authState } from "../redux/auth/authReducer";
import { getCookie } from "../utils/cookies";
import { tokenKey } from "../utils/constants";
import { isJwtValid } from "../utils/common";
import "./style.css";

export default function SharedLayout({ component: Component, ...rest }) {
  const { current: sync } = useSelector(SyncState);
  const [lastSync, setLastSync] = useState(0);
  useEffect(() => {
    if (lastSync !== sync.time) {
      setLastSync(sync.time);
    }
  }, [sync.time, lastSync]);

  const auth = useSelector(authState);
  const [isLogged, setIsLogged] = useState(false);
  useEffect(() => {
    if (getCookie(tokenKey) === null) {
      setIsLogged(false);
    } else {
      if (!isJwtValid(getCookie(tokenKey))) {
        setIsLogged(false);
      } else {
        setIsLogged(true);
      }
    }
  }, [auth.isAuthenticated]);

  return (
    <div className={"sharedLayout-Container"}>
      <Component />
    </div>
  );
}
