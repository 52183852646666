/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 16/08/22
 * Time: 6:40 PM
 * Project: W-CRM
 */
import {createSlice} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import {endPoints} from "../../api/endpoints";

const GroupInitialState = {
    get: {
        isLoading: false,
        data: null,
        error: null,
    },
    create: {
        isLoading: false,
        data: null,
        error: null,
    },
    selected: {
        isLoading: false,
        data: null,
        error: null,
    },
    add: {
        isLoading: false,
        data: null,
        error: null,
    },
    remove: {
        isLoading: false,
        isRemoved: false,
        error: null,
    },
    update: {
        isLoading: false,
        data: null,
        error: null,
    },
    users: {
        isLoading: false,
        data: null,
        error: null,
    },
    delete: {
        isLoading: false,
        data: null,
        error: null,
    },
};

export const GroupSlice = createSlice({
    name: "group",
    initialState: {
        ...GroupInitialState,
    },
    reducers: {
        GetGroupRequesting: (state, action) => {
            state.get.isLoading = true;
            state.get.error = false;
        },
        GetGroupSuccess: (state, action) => {
            state.get.isLoading = false;
            state.get.error = null;
            state.get.data = action.payload.data;
        },
        GetGroupError: (state, action) => {
            state.get.isLoading = false;
            state.get.data = null;
            state.get.error = action.payload;
        },
        GetGroupReset: (state, action) => {
            state.get = GroupInitialState.get;
        },

        CreateGroupRequesting: (state, action) => {
            state.create.isLoading = true;
            state.create.error = false;
        },
        CreateGroupSuccess: (state, action) => {
            state.create.isLoading = false;
            state.create.error = null;
            state.create.data = action.payload.data;
        },
        CreateGroupError: (state, action) => {
            state.create.isLoading = false;
            state.create.data = null;
            state.create.error = action.payload;
        },
        CreateGroupReset: (state, action) => {
            state.create = GroupInitialState.create;
        },

        SelectedGroupRequesting: (state, action) => {
            state.selected.isLoading = true;
            state.selected.error = false;
        },
        SelectedGroupSuccess: (state, action) => {
            state.selected.isLoading = false;
            state.selected.error = null;
            state.selected.data = action.payload.data;
        },
        SelectedGroupError: (state, action) => {
            state.selected.isLoading = false;
            state.selected.data = null;
            state.selected.error = action.payload;
        },
        SelectedGroupReset: (state, action) => {
            state.selected = GroupInitialState.get;
        },

        AddGroupRequesting: (state, action) => {
            state.add.isLoading = true;
            state.add.error = null;
        },
        AddGroupSuccess: (state, action) => {
            state.add.isLoading = false;
            state.add.error = null;
            state.add.data = action.payload.data;
        },
        AddGroupError: (state, action) => {
            state.add.isLoading = false;
            state.add.data = null;
            state.add.error = action.payload;
        },
        AddGroupReset: (state, action) => {
            state.add = GroupInitialState.get;
        },

        RemoveGroupRequesting: (state, action) => {
            state.remove.isLoading = true;
            state.remove.error = null;
        },
        RemoveGroupSuccess: (state, action) => {
            state.remove.isLoading = false;
            state.remove.error = null;
            state.remove.isRemoved = true;
        },
        RemoveGroupError: (state, action) => {
            state.remove.isLoading = false;
            state.remove.isRemoved = false;
            state.remove.error = action.payload;
        },
        RemoveGroupReset: (state, action) => {
            state.remove = GroupInitialState.remove;
        },


        DeleteGroupRequesting: (state, action) => {
            state.delete.isLoading = true;
            state.delete.error = null;
        },
        DeleteGroupSuccess: (state, action) => {
            state.delete.isLoading = false;
            state.delete.error = null;
            state.delete.isDeleted = true;
        },
        DeleteGroupError: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isDeleted = false;
            state.delete.error = action.payload;
        },
        DeleteGroupReset: (state, action) => {
            state.delete = GroupInitialState.delete;
        },

        UpdateGroupRequesting: (state, action) => {
            state.update.isLoading = true;
            state.update.error = false;
        },
        UpdateGroupSuccess: (state, action) => {
            state.update.isLoading = false;
            state.update.error = null;
            state.update.data = action.payload.data;
        },
        UpdateGroupError: (state, action) => {
            state.update.isLoading = false;
            state.update.data = null;
            state.update.error = action.payload;
        },
        UpdateGroupReset: (state, action) => {
            state.update = GroupInitialState.update;
        },

        userGroupRequesting: (state, action) => {
            state.users.isLoading = true;
            state.users.isCreated = false;
        },
        userGroupSuccess: (state, action) => {
            state.users.isLoading = false;
            state.users.error = null;
            state.users.data = action.payload.data;
        },
        userGroupError: (state, action) => {
            state.users.isLoading = false;
            state.users.data = null;
            state.users.error = action.payload;
        },
        userGroupReset: (state, action) => {
            state.users = GroupInitialState.users;
        },

        GroupMasterReset: () => GroupInitialState,
    },
});

export const getGroupsRequest = (query) => async (dispatch) => {
    let stringQuery = qargsToQstring(query);
    dispatch(GetGroupRequesting());
    apiService
        .get(`${endPoints.group.get}` + stringQuery, {})
        .then((response) => {
            dispatch(GetGroupSuccess(response.data));
        })
        .catch((error) => dispatch(GetGroupError(formatAxiosErrorMessage(error))));
};

export const updateUserInGroupRequest = (id, data) => async (dispatch) => {
    dispatch(UpdateGroupRequesting());
    apiService
        .patch(`${endPoints.group.selected}` + id + "/", data)
        .then((response) => {
            dispatch(UpdateGroupSuccess(response.data));
        })
        .catch((error) =>
            dispatch(UpdateGroupError(formatAxiosErrorMessage(error)))
        );
};

export const createNewGroupRequest = (data) => async (dispatch) => {
    dispatch(CreateGroupRequesting());
    apiService
        .post(`${endPoints.group.get}`, data)
        .then((response) => {
            dispatch(CreateGroupSuccess(response.data));
        })
        .catch((error) =>
            dispatch(CreateGroupError(formatAxiosErrorMessage(error)))
        );
};

export const GetSelectedGroup = (query) => async (dispatch) => {
    let stringQuery = qargsToQstring(query);
    dispatch(SelectedGroupRequesting());
    apiService
        .get(`${endPoints.group.selected}${stringQuery}`, {})
        .then((response) => {
            dispatch(SelectedGroupSuccess(response.data));
        })
        .catch((error) =>
            dispatch(SelectedGroupError(formatAxiosErrorMessage(error)))
        );
};

export const UnAssignUserFromGroup = (id) => async (dispatch) => {
    dispatch(RemoveGroupRequesting());
    apiService
        .delete(`${endPoints.group.userMapping}${id}/`, {})
        .then((response) => {
            dispatch(RemoveGroupSuccess(response.data));
        })
        .catch((error) =>
            dispatch(RemoveGroupError(formatAxiosErrorMessage(error)))
        );
};

export const DeleteGroupAPI = (id) => async (dispatch) => {
    dispatch(DeleteGroupRequesting());
    apiService
        .delete(`${endPoints.group.get}${id}/`, {})
        .then((response) => {
            dispatch(DeleteGroupSuccess(response.data));
        })
        .catch((error) =>
            dispatch(DeleteGroupError(formatAxiosErrorMessage(error)))
        );
};

export const AssignUserFromGroup = (data) => async (dispatch) => {
    dispatch(AddGroupRequesting());
    apiService
        .post(`${endPoints.group.userMapping}`, data)
        .then((response) => {
            dispatch(AddGroupSuccess(response.data));
        })
        .catch((error) => dispatch(AddGroupError(formatAxiosErrorMessage(error))));
};

export const getUsersGroupRequest = (query) => async (dispatch) => {
    dispatch(userGroupRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.organization.users}` + stringQuery, {})
        .then((response) => {
            dispatch(userGroupSuccess(response.data));
        })
        .catch((error) => dispatch(userGroupError(formatAxiosErrorMessage(error))));
};

export const {
    GetGroupRequesting,
    GetGroupSuccess,
    GetGroupError,
    GetGroupReset,
    CreateGroupRequesting,
    CreateGroupSuccess,
    CreateGroupError,
    CreateGroupReset,
    SelectedGroupRequesting,
    SelectedGroupSuccess,
    SelectedGroupError,
    SelectedGroupReset,

    AddGroupRequesting,
    AddGroupSuccess,
    AddGroupError,
    AddGroupReset,

    RemoveGroupRequesting,
    RemoveGroupSuccess,
    RemoveGroupError,
    RemoveGroupReset,

    UpdateGroupError,
    UpdateGroupRequesting,
    UpdateGroupReset,
    UpdateGroupSuccess,

    userGroupError,
    userGroupRequesting,
    userGroupReset,
    userGroupSuccess,

    DeleteGroupRequesting,
    DeleteGroupSuccess,
    DeleteGroupError,
    DeleteGroupReset,

    GroupMasterReset,
} = GroupSlice.actions;
export const GroupState = (state) => state.group;
export default GroupSlice.reducer;
