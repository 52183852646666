/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 25/07/22
 * Time: 7:06 PM
 * Project: W-CRM
 */
import {createSlice} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import {endPoints} from "../../api/endpoints";
import {hardRefreshPage} from "../profile/profileReducer";

const OrganizationInitialState = {
    users: {
        isLoading: false,
        data: null,
        error: null,
    },
    usersSelectionList: {
        isLoading: false,
        data: null,
        error: null,
    },
    invite: {
        isLoading: false,
        data: null,
        error: null,
    },
    orgInvites: {
        isLoading: false,
        data: null,
        error: null,
    },
    myInvites: {
        isLoading: false,
        data: [],
        error: null,
    },
    inviteAction: {
        isLoading: false,
        data: null,
        error: null,
    },
    customField: {
        isLoading: false,
        isFetched: false,
        data: null,
        error: null,
    },
    customFieldSave: {
        isLoading: false,
        data: null,
        error: null,
    },
    removeInvite: {
        isLoading: false,
        data: null,
        error: null,
    },
    update: {
        isLoading: false,
        data: null,
        error: null,
    },
    orgDelete: {
        isLoading: false,
        data: null,
        error: null,
    },
    customLeadsList: {
        isLoading: false,
        data: null,
        error: null,
    },
    addCustomLeads: {
        isLoading: false,
        data: null,
        error: null,
    },
    deleteCustomLeads: {
        isLoading: false,
        data: null,
        error: null,
    },
    updateCustomLeads: {
        isLoading: false,
        data: null,
        error: null,
    },
    clientLayoutOrg: null,
};

export const OrganizationSlice = createSlice({
    name: "organization",
    initialState: {
        ...OrganizationInitialState,
    },
    reducers: {
        userRequesting: (state, action) => {
            state.users.isLoading = true;
            state.users.isCreated = false;
        },
        userSuccess: (state, action) => {
            state.users.isLoading = false;
            state.users.error = null;
            state.users.data = action.payload.data;
        },
        userError: (state, action) => {
            state.users.isLoading = false;
            state.users.data = null;
            state.users.error = action.payload;
        },
        userReset: (state, action) => {
            state.users = OrganizationInitialState.users;
        },

        usersSelectionListRequesting: (state, action) => {
            state.usersSelectionList.isLoading = true;
        },
        usersSelectionListSuccess: (state, action) => {
            state.usersSelectionList.isLoading = false;
            state.usersSelectionList.error = null;
            state.usersSelectionList.data = action.payload.data;
        },
        usersSelectionListError: (state, action) => {
            state.usersSelectionList.isLoading = false;
            state.usersSelectionList.data = null;
            state.usersSelectionList.error = action.payload;
        },
        usersSelectionListReset: (state, action) => {
            state.usersSelectionList = OrganizationInitialState.usersSelectionList;
        },

        userInviteRequesting: (state, action) => {
            state.invite.isLoading = true;
        },
        userInviteSuccess: (state, action) => {
            state.invite.isLoading = false;
            state.invite.error = null;
            state.invite.data = action.payload.data;
        },
        userInviteError: (state, action) => {
            state.invite.isLoading = false;
            state.invite.data = null;
            state.invite.error = action.payload;
        },
        userInviteReset: (state, action) => {
            state.invite = OrganizationInitialState.invite;
        },

        myInvitesRequesting: (state, action) => {
            state.myInvites.isLoading = true;
            state.myInvites.isCreated = false;
        },
        myInvitesSuccess: (state, action) => {
            state.myInvites.isLoading = false;
            state.myInvites.error = null;
            state.myInvites.data = action.payload.data;
        },
        myInvitesError: (state, action) => {
            state.myInvites.isLoading = false;
            state.myInvites.data = [];
            state.myInvites.error = action.payload;
        },
        myInvitesReset: (state, action) => {
            state.myInvites = OrganizationInitialState.myInvites;
        },

        inviteActionRequesting: (state, action) => {
            state.inviteAction.isLoading = true;
            state.inviteAction.isCreated = false;
        },
        inviteActionSuccess: (state, action) => {
            state.inviteAction.isLoading = false;
            state.inviteAction.error = null;
            state.inviteAction.data = action.payload.data;
        },
        inviteActionError: (state, action) => {
            state.inviteAction.isLoading = false;
            state.inviteAction.data = null;
            state.inviteAction.error = action.payload;
        },
        inviteActionReset: (state, action) => {
            state.inviteAction = OrganizationInitialState.inviteAction;
        },

        FetchCustomFieldsRequesting: (state, action) => {
            state.customField.isLoading = true;
        },
        FetchCustomFieldsSuccess: (state, action) => {
            state.customField.isLoading = false;
            state.customField.error = null;
            state.customField.data = action.payload.data;
            state.customField.isFetched = true
        },
        FetchCustomFieldsError: (state, action) => {
            state.customField.isLoading = false;
            state.customField.data = null;
            state.customField.isFetched = false
            state.customField.error = action.payload;
        },
        FetchCustomFieldsReset: (state, action) => {
            state.customField = OrganizationInitialState.customField;
        },

        SaveCustomFieldsRequesting: (state, action) => {
            state.customFieldSave.isLoading = true;
            state.customFieldSave.isCreated = false;
        },
        SaveCustomFieldsSuccess: (state, action) => {
            state.customFieldSave.isLoading = false;
            state.customFieldSave.error = null;
            state.customFieldSave.data = action.payload.data;
        },
        SaveCustomFieldsError: (state, action) => {
            state.customFieldSave.isLoading = false;
            state.customFieldSave.data = null;
            state.customFieldSave.error = action.payload;
        },
        SaveCustomFieldsReset: (state, action) => {
            state.customFieldSave = OrganizationInitialState.customFieldSave;
        },

        OrgInvitesRequesting: (state, action) => {
            state.orgInvites.isLoading = true;
        },
        OrgInvitesSuccess: (state, action) => {
            state.orgInvites.isLoading = false;
            state.orgInvites.error = null;
            state.orgInvites.data = action.payload.data;
        },
        OrgInvitesError: (state, action) => {
            state.orgInvites.isLoading = false;
            state.orgInvites.data = null;
            state.orgInvites.error = action.payload;
        },
        OrgInvitesReset: (state, action) => {
            state.orgInvites = OrganizationInitialState.orgInvites;
        },

        RemoveInvitesRequesting: (state, action) => {
            state.removeInvite.isLoading = true;
        },
        RemoveInvitesSuccess: (state, action) => {
            state.removeInvite.isLoading = false;
            state.removeInvite.error = null;
            state.removeInvite.data = action.payload.data;
        },
        RemoveInvitesError: (state, action) => {
            state.removeInvite.isLoading = false;
            state.removeInvite.data = null;
            state.removeInvite.error = action.payload;
        },
        RemoveInvitesReset: (state, action) => {
            state.removeInvite = OrganizationInitialState.removeInvite;
        },

        UpdateOrganizationRequesting: (state, action) => {
            state.update.isLoading = true;
        },
        UpdateOrganizationSuccess: (state, action) => {
            state.update.isLoading = false;
            state.update.error = null;
            state.update.data = action.payload.data;
        },
        UpdateOrganizationError: (state, action) => {
            state.update.isLoading = false;
            state.update.data = null;
            state.update.error = action.payload;
        },
        UpdateOrganizationReset: (state, action) => {
            state.update = OrganizationInitialState.update;
        },

        DeleteOrganizationRequesting: (state, action) => {
            state.orgDelete.isLoading = true;
        },
        DeleteOrganizationSuccess: (state, action) => {
            state.orgDelete.isLoading = false;
            state.orgDelete.error = null;
            state.orgDelete.data = action.payload.data;
        },
        DeleteOrganizationError: (state, action) => {
            state.orgDelete.isLoading = false;
            state.orgDelete.data = null;
            state.orgDelete.error = action.payload;
        },
        DeleteOrganizationReset: (state, action) => {
            state.orgDelete = OrganizationInitialState.orgDelete;
        },

        ListCustomLeadsStatusOrganizationRequesting: (state, action) => {
            state.customLeadsList.isLoading = true;
            state.customLeadsList.error = null;
        },
        ListCustomLeadsStatusOrganizationSuccess: (state, action) => {
            state.customLeadsList.isLoading = false;
            state.customLeadsList.error = null;
            state.customLeadsList.data = action.payload.data;
        },
        ListCustomLeadsStatusOrganizationError: (state, action) => {
            state.customLeadsList.isLoading = false;
            state.customLeadsList.data = null;
            state.customLeadsList.error = action.payload;
        },
        ListCustomLeadsStatusOrganizationReset: (state, action) => {
            state.customLeadsList = OrganizationInitialState.customLeadsList;
        },

        AddCustomLeadsStatusRequesting: (state, action) => {
            state.addCustomLeads.isLoading = true;
            state.addCustomLeads.error = null;
        },
        AddCustomLeadsStatusSuccess: (state, action) => {
            state.addCustomLeads.isLoading = false;
            state.addCustomLeads.error = null;
            state.addCustomLeads.data = action.payload.data;
        },
        AddCustomLeadsStatusError: (state, action) => {
            state.addCustomLeads.isLoading = false;
            state.addCustomLeads.data = null;
            state.addCustomLeads.error = action.payload;
        },
        AddCustomLeadsStatusReset: (state, action) => {
            state.addCustomLeads = OrganizationInitialState.addCustomLeads;
        },

        DeleteCustomLeadsStatusRequesting: (state, action) => {
            state.deleteCustomLeads.isLoading = true;
        },
        DeleteCustomLeadsStatusSuccess: (state, action) => {
            state.deleteCustomLeads.isLoading = false;
            state.deleteCustomLeads.error = null;
            state.deleteCustomLeads.data = action.payload.data;
        },
        DeleteCustomLeadsStatusError: (state, action) => {
            state.deleteCustomLeads.isLoading = false;
            state.deleteCustomLeads.data = null;
            state.deleteCustomLeads.error = action.payload;
        },
        DeleteCustomLeadsStatusReset: (state, action) => {
            state.deleteCustomLeads = OrganizationInitialState.deleteCustomLeads;
        },

        UpdateCustomLeadsStatusRequesting: (state, action) => {
            state.updateCustomLeads.isLoading = true;
        },
        UpdateCustomLeadsStatusSuccess: (state, action) => {
            state.updateCustomLeads.isLoading = false;
            state.updateCustomLeads.error = null;
            state.updateCustomLeads.data = action.payload.data;
        },
        UpdateCustomLeadsStatusError: (state, action) => {
            state.updateCustomLeads.isLoading = false;
            state.updateCustomLeads.data = null;
            state.updateCustomLeads.error = action.payload;
        },
        UpdateCustomLeadsStatusReset: (state, action) => {
            state.updateCustomLeads = OrganizationInitialState.updateCustomLeads;
        },

        SetClientLayoutOrg: (state, action) => {
            state.clientLayoutOrg = action.payload;
        },

        OrganizationMasterReset: () => OrganizationInitialState,
    },
});

export const getUsersRequest = (query) => async (dispatch) => {
    dispatch(userRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.organization.users}${stringQuery}`, {})
        .then((response) => {
            dispatch(userSuccess(response.data));
        })
        .catch((error) => dispatch(userError(formatAxiosErrorMessage(error))));
};
export const getUsersSelectionListRequest = (query) => async (dispatch) => {
    dispatch(usersSelectionListRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.organization.users}${stringQuery}`, {})
        .then((response) => {
            dispatch(usersSelectionListSuccess(response.data));
        })
        .catch((error) => dispatch(usersSelectionListError(formatAxiosErrorMessage(error))));
};

export const sendUsersInviteAPI = (data) => async (dispatch) => {
    dispatch(userInviteRequesting());
    apiService
        .post(endPoints.organization.invite, data)
        .then((response) => {
            dispatch(userInviteSuccess(response.data));
        })
        .catch((error) => {
            dispatch(userInviteError(formatAxiosErrorMessage(error)));
        });
};

export const getMyInviteAPI = (query) => async (dispatch) => {
    dispatch(myInvitesRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.organization.myInvites}${stringQuery}`, {})
        .then((response) => {
            dispatch(myInvitesSuccess(response.data));
        })
        .catch((error) => {
            dispatch(myInvitesError(formatAxiosErrorMessage(error)));
        });
};

export const inviteActionAPI = (data, condition) => async (dispatch) => {
    let url = "";
    if (condition === "accept") {
        url = endPoints.organization.accept;
    } else {
        url = endPoints.organization.decline;
    }
    dispatch(inviteActionRequesting());
    apiService
        .post(url, data)
        .then((response) => {
            dispatch(hardRefreshPage());
            dispatch(inviteActionSuccess(response.data));
        })
        .catch((error) => {
            dispatch(inviteActionError(formatAxiosErrorMessage(error)));
        });
};

export const fetchCustomField = (query) => async (dispatch) => {
    dispatch(FetchCustomFieldsRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(endPoints.organization.customField + stringQuery, {})
        .then((response) => {
            dispatch(FetchCustomFieldsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(FetchCustomFieldsError(formatAxiosErrorMessage(error)));
        });
};
export const saveCustomField = (query, data) => async (dispatch) => {
    dispatch(SaveCustomFieldsRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .post(endPoints.organization.customField + stringQuery, data)
        .then((response) => {
            dispatch(SaveCustomFieldsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(SaveCustomFieldsError(formatAxiosErrorMessage(error)));
        });
};
export const updateCustomField = (id, data) => async (dispatch) => {
    dispatch(SaveCustomFieldsRequesting());
    apiService
        .patch(endPoints.organization.customField + id + "/", data)
        .then((response) => {
            dispatch(SaveCustomFieldsSuccess(response.data));
        })
        .catch((error) => {
            dispatch(SaveCustomFieldsError(formatAxiosErrorMessage(error)));
        });
};

export const getOrgInvitesAPI = (query) => async (dispatch) => {
    dispatch(OrgInvitesRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(endPoints.organization.invites + stringQuery)
        .then((response) => {
            dispatch(OrgInvitesSuccess(response.data));
        })
        .catch((error) => {
            dispatch(OrgInvitesError(formatAxiosErrorMessage(error)));
        });
};

export const removeOrgInvitesAPI = (id) => async (dispatch) => {
    dispatch(RemoveInvitesRequesting());
    apiService
        .delete(endPoints.organization.invites + `${id}/`)
        .then((response) => {
            dispatch(RemoveInvitesSuccess(response.data));
            dispatch(hardRefreshPage());
        })
        .catch((error) => {
            dispatch(RemoveInvitesError(formatAxiosErrorMessage(error)));
        });
};

export const updateOrganizationAPI = (id, data) => async (dispatch) => {
    dispatch(UpdateOrganizationRequesting());
    apiService
        .patch(`${endPoints.organization.get}${id}/`, data)
        .then((response) => {
            dispatch(UpdateOrganizationSuccess(response.data));
            dispatch(hardRefreshPage());
        })
        .catch((error) => {
            dispatch(UpdateOrganizationError(formatAxiosErrorMessage(error)));
        });
};

export const deleteOrganizationAPI = () => async (dispatch) => {
    dispatch(DeleteOrganizationRequesting());
    apiService
        .post(`${endPoints.organization.deleteOrg}`, {})
        .then((response) => {
            dispatch(DeleteOrganizationSuccess(response.data));
        })
        .catch((error) => {
            dispatch(DeleteOrganizationError(formatAxiosErrorMessage(error)));
        });
};
export const listCustomLeadsAPI = () => async (dispatch) => {
    dispatch(ListCustomLeadsStatusOrganizationRequesting());
    apiService
        .get(`${endPoints.organization.customLead}`, {})
        .then((response) => {
            dispatch(ListCustomLeadsStatusOrganizationSuccess(response.data));
        })
        .catch((error) => {
            dispatch(ListCustomLeadsStatusOrganizationError(formatAxiosErrorMessage(error)));
        });
};

export const deleteCustomLeadsAPI = (id, data) => async (dispatch) => {
    dispatch(DeleteCustomLeadsStatusRequesting());
    apiService
        .delete(`${endPoints.organization.customLead}${id}/`, {data: data})
        .then((response) => {
            dispatch(DeleteCustomLeadsStatusSuccess(response.data));
        })
        .catch((error) => {
            dispatch(DeleteCustomLeadsStatusError(formatAxiosErrorMessage(error)));
        });
};

export const updateCustomLeadsAPI = (id, data) => async (dispatch) => {
    dispatch(UpdateCustomLeadsStatusRequesting());
    apiService
        .patch(`${endPoints.organization.customLead}${id}/`, data)
        .then((response) => {
            dispatch(UpdateCustomLeadsStatusSuccess(response.data));
        })
        .catch((error) => {
            dispatch(UpdateCustomLeadsStatusError(formatAxiosErrorMessage(error)));
        });
};

export const updateCustomLeadsPriorityAPI = (data) => async (dispatch) => {
    dispatch(UpdateCustomLeadsStatusRequesting());
    apiService
        .post(`${endPoints.organization.customLeadBulk}`, data)
        .then((response) => {
            dispatch(UpdateCustomLeadsStatusSuccess(response.data));
        })
        .catch((error) => {
            dispatch(UpdateCustomLeadsStatusError(formatAxiosErrorMessage(error)));
        });
};


export const addNewCustomLeadsAPI = (data) => async (dispatch) => {
    dispatch(AddCustomLeadsStatusRequesting());
    apiService
        .post(`${endPoints.organization.customLead}`, data)
        .then((response) => {
            dispatch(AddCustomLeadsStatusSuccess(response.data));
        })
        .catch((error) => {
            dispatch(AddCustomLeadsStatusError(formatAxiosErrorMessage(error)));
        });
};

export const {
    userRequesting,
    userSuccess,
    userError,
    userReset,

    userInviteRequesting,
    userInviteSuccess,
    userInviteError,
    userInviteReset,

    myInvitesRequesting,
    myInvitesSuccess,
    myInvitesError,
    myInvitesReset,

    inviteActionRequesting,
    inviteActionSuccess,
    inviteActionError,
    inviteActionReset,

    FetchCustomFieldsRequesting,
    FetchCustomFieldsSuccess,
    FetchCustomFieldsError,
    FetchCustomFieldsReset,

    SaveCustomFieldsRequesting,
    SaveCustomFieldsSuccess,
    SaveCustomFieldsError,
    SaveCustomFieldsReset,

    OrgInvitesRequesting,
    OrgInvitesSuccess,
    OrgInvitesError,
    OrgInvitesReset,

    RemoveInvitesRequesting,
    RemoveInvitesSuccess,
    RemoveInvitesError,
    RemoveInvitesReset,

    UpdateOrganizationRequesting,
    UpdateOrganizationSuccess,
    UpdateOrganizationError,
    UpdateOrganizationReset,

    usersSelectionListRequesting,
    usersSelectionListSuccess,
    usersSelectionListError,
    usersSelectionListReset,
    DeleteOrganizationRequesting,
    DeleteOrganizationSuccess,
    DeleteOrganizationError,
    DeleteOrganizationReset,

    ListCustomLeadsStatusOrganizationRequesting,
    ListCustomLeadsStatusOrganizationSuccess,
    ListCustomLeadsStatusOrganizationError,
    ListCustomLeadsStatusOrganizationReset,

    AddCustomLeadsStatusRequesting,
    AddCustomLeadsStatusSuccess,
    AddCustomLeadsStatusError,
    AddCustomLeadsStatusReset,

    DeleteCustomLeadsStatusRequesting,
    DeleteCustomLeadsStatusSuccess,
    DeleteCustomLeadsStatusError,
    DeleteCustomLeadsStatusReset,

    UpdateCustomLeadsStatusRequesting,
    UpdateCustomLeadsStatusSuccess,
    UpdateCustomLeadsStatusError,
    UpdateCustomLeadsStatusReset,

    SetClientLayoutOrg,

    OrganizationMasterReset,
} = OrganizationSlice.actions;
export const OrganizationState = (state) => state.organization;
export default OrganizationSlice.reducer;
