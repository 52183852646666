/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 05/10/22
 * Time: 11:29 AM
 * Project: W-CRM
 */
import { createSlice, current } from "@reduxjs/toolkit";
import { formatAxiosErrorMessage, qargsToQstring } from "../../../utils/common";
import apiService from "../../../api/apiService";
import { endPoints } from "../../../api/endpoints";
import {
  removeArrayItem,
  removeArrayObjectItem,
  removeDuplicates,
  replaceArrayObjectItem,
} from "../../../utils/util";

const TaskInitialState = {
  list: {
    isLoading: false,
    data: [],
    error: null,
  },
  create: {
    isLoading: false,
    isSuccess: false,
    data: null,
    error: null,
  },
  put: {
    isLoading: false,
    isSuccess: false,
    data: null,
    error: null,
  },
  delete: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  inProcess: {
    queue: [],
  },
};

export const TaskSlice = createSlice({
  name: "task",
  initialState: {
    ...TaskInitialState,
  },
  reducers: {
    taskListRequesting: (state, action) => {
      state.list.isLoading = true;
    },
    taskListSuccess: (state, action) => {
      const { list } = current(state);
      const newData = action.payload.data;
      if (list?.data?.results) {
        newData.results = removeDuplicates([
          ...list.data.results,
          ...newData.results,
        ]);
      }
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = newData;
    },
    taskListRefreshSuccess: (state, action) => {
      state.list.isLoading = false;
      state.list.error = null;
      state.list.data = action.payload.data;
    },
    taskListError: (state, action) => {
      state.list.isLoading = false;
      state.list.error = action.payload;
    },
    taskListReset: (state, action) => {
      state.list = TaskInitialState.list;
    },

    taskCreateRequesting: (state, action) => {
      state.create.isLoading = true;
      state.create.isSuccess = false;
    },
    taskCreateSuccess: (state, action) => {
      state.create.isSuccess = true;
      state.create.isLoading = false;
      state.create.error = null;
      if (state.list.data.results) {
        state.list.data.results.push(action.payload.data);
      }
    },
    taskCreateError: (state, action) => {
      state.create.isLoading = false;
      state.create.isSuccess = false;
      state.create.error = action.payload;
    },
    taskCreateReset: (state, action) => {
      state.create = TaskInitialState.create;
    },

    taskUpdateRequesting: (state, action) => {
      state.put.isLoading = true;
      state.put.isSuccess = false;
    },
    taskUpdateSuccess: (state, action) => {
      const { list } = current(state);
      state.put.isSuccess = true;
      state.put.isLoading = false;
      state.put.error = null;

      if (state.list.data.results) {
        let temp = [...(list?.data?.results ?? [])];
        state.list.data.results = replaceArrayObjectItem(
          temp,
          action.payload.data
        );
      }
    },
    taskBulkUpdateSuccess: (state, action) => {
      const { list } = current(state);
      state.put.isSuccess = true;
      state.put.isLoading = false;
      state.put.error = null;
      let temp = [...(list?.data?.results ?? [])];
      action.payload.data.forEach((item) => {
        temp = replaceArrayObjectItem(temp, item);
      });
      state.list.data.results = temp;
    },
    taskUpdateError: (state, action) => {
      state.put.isLoading = false;
      state.put.isSuccess = false;
      state.put.error = action.payload;
    },
    taskUpdateReset: (state, action) => {
      state.put = TaskInitialState.put;
    },

    taskDeleteRequesting: (state, action) => {
      state.delete.isLoading = true;
      state.delete.isSuccess = false;
    },
    taskDeleteSuccess: (state, action) => {
      state.delete.isSuccess = true;
      state.delete.isLoading = false;
      state.delete.error = null;
      if (state.list.data.results) {
        state.list.data.results = removeArrayObjectItem(
          state.list.data.results,
          action.payload
        );
      }
    },
    taskBulkDeleteSuccess: (state, action) => {
      state.delete.isSuccess = true;
      state.delete.isLoading = false;
      state.delete.error = null;
      action.payload.data.forEach((item) => {
        state.list.data.results = removeArrayObjectItem(
          state.list.data.results,
          item
        );
      });
    },
    taskDeleteError: (state, action) => {
      state.delete.isLoading = false;
      state.delete.isSuccess = false;
      state.delete.error = action.payload;
    },
    taskDeleteReset: (state, action) => {
      state.delete = TaskInitialState.delete;
    },

    taskQueueAdd: (state, action) => {
      if (!state.inProcess.queue.includes(action.payload)) {
        state.inProcess.queue.push(action.payload);
      }
    },
    taskQueueRemove: (state, action) => {
      state.inProcess.queue = removeArrayItem(
        state.inProcess.queue,
        action.payload
      );
    },
    taskQueueReset: (state, action) => {
      state.inProcess = TaskInitialState.inProcess;
    },
    taskMasterReset: () => TaskInitialState,
  },
});

export const getTaskListAPI = (query) => async (dispatch) => {
  dispatch(taskListRequesting());
  let stringQuery = qargsToQstring(query);
  apiService
    .get(`${endPoints.task.list}${stringQuery}`, {})
    .then((response) => {
      if (query.offset === 0) {
        dispatch(taskListRefreshSuccess(response.data));
      } else {
        dispatch(taskListSuccess(response.data));
      }
    })
    .catch((error) => {
      dispatch(taskListError(formatAxiosErrorMessage(error)));
    });
};

export const createNewTaskAPI = (data) => async (dispatch) => {
  dispatch(taskCreateRequesting());
  apiService
    .post(endPoints.task.list, data)
    .then((response) => {
      dispatch(taskCreateSuccess(response.data));
    })
    .catch((error) => {
      dispatch(taskCreateError(formatAxiosErrorMessage(error)));
    });
};

export const updateTaskNewTaskAPI = (id, data) => async (dispatch) => {
  dispatch(taskUpdateRequesting());
  dispatch(taskQueueAdd(id));
  apiService
    .patch(`${endPoints.task.list}${id}/`, data)
    .then((response) => {
      dispatch(taskUpdateSuccess(response.data));
      dispatch(taskQueueRemove(id));
    })
    .catch((error) => {
      dispatch(taskUpdateError(formatAxiosErrorMessage(error)));
    });
};

export const deleteTaskNewTaskAPI = (id) => async (dispatch) => {
  dispatch(taskDeleteRequesting());
  apiService
    .delete(`${endPoints.task.list}${id}/`, {})
    .then((response) => {
      dispatch(taskDeleteSuccess(id));
    })
    .catch((error) => {
      dispatch(taskDeleteError(formatAxiosErrorMessage(error)));
    });
};

export const deleteBulkTaskAPI = (data) => async (dispatch) => {
  dispatch(taskDeleteRequesting());
  apiService
    .delete(`${endPoints.task.bulkDelete}`, { data })
    .then((response) => {
      dispatch(taskBulkDeleteSuccess(response.data));
    })
    .catch((error) => {
      dispatch(taskDeleteError(formatAxiosErrorMessage(error)));
    });
};

export const updateBulkTaskAPI = (data) => async (dispatch) => {
  dispatch(taskUpdateRequesting());

  data.tasks.forEach((item) => {
    dispatch(taskQueueAdd(item));
  });
  apiService
    .put(`${endPoints.task.bulkCompletion}`, data)
    .then((response) => {
      dispatch(taskBulkUpdateSuccess(response.data));
      response.data.data.forEach((item) => {
        dispatch(taskQueueRemove(item.id));
      });
    })
    .catch((error) => {
      dispatch(taskUpdateError(formatAxiosErrorMessage(error)));
    });
};

export const {
  taskListRequesting,
  taskListSuccess,
  taskListRefreshSuccess,
  taskListError,
  taskListReset,

  taskCreateRequesting,
  taskCreateSuccess,
  taskCreateError,
  taskCreateReset,

  taskUpdateRequesting,
  taskUpdateSuccess,
  taskUpdateError,
  taskUpdateReset,

  taskDeleteRequesting,
  taskDeleteSuccess,
  taskDeleteError,
  taskDeleteReset,

  taskQueueAdd,
  taskQueueRemove,
  taskQueueReset,

  taskBulkDeleteSuccess,

  taskBulkUpdateSuccess,

  taskMasterReset,
} = TaskSlice.actions;
export const TaskState = (state) => state.task;
export default TaskSlice.reducer;
