/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 21/11/23
 * Time: 1:13 pm
 * Project: Waxis
 */
import React from 'react';

export default function ArrowPathIcon(
    {
        strokeWidth = 3,
        className = "w-1",
        stroke = 'currentColor',
        fill = "none"
    }
) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" strokeWidth={strokeWidth} className={className} fill={fill}
             viewBox="0 0 24 24" stroke={stroke}>

            <path fillRule="evenodd"
                  d="M12 5.25c1.213 0 2.415.046 3.605.135a3.256 3.256 0 013.01 3.01c.044.583.077 1.17.1 1.759L17.03 8.47a.75.75 0 10-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 00-1.06-1.06l-1.752 1.751c-.023-.65-.06-1.296-.108-1.939a4.756 4.756 0 00-4.392-4.392 49.422 49.422 0 00-7.436 0A4.756 4.756 0 003.89 8.282c-.017.224-.033.447-.046.672a.75.75 0 101.497.092c.013-.217.028-.434.044-.651a3.256 3.256 0 013.01-3.01c1.19-.09 2.392-.135 3.605-.135zm-6.97 6.22a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.752-1.751c.023.65.06 1.296.108 1.939a4.756 4.756 0 004.392 4.392 49.413 49.413 0 007.436 0 4.756 4.756 0 004.392-4.392c.017-.223.032-.447.046-.672a.75.75 0 00-1.497-.092c-.013.217-.028.434-.044.651a3.256 3.256 0 01-3.01 3.01 47.953 47.953 0 01-7.21 0 3.256 3.256 0 01-3.01-3.01 47.759 47.759 0 01-.1-1.759L6.97 15.53a.75.75 0 001.06-1.06l-3-3z"
                  clipRule="evenodd"/>

        </svg>
    );
}