/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 23/01/24
 * Time: 11:29 am
 * Project: W-CRM
 */
export function setLocalStorage(name, value) {
    localStorage.setItem(name, value)
}

export function getLocalStorage(name) {
    return localStorage.getItem(name)
}

export function eraseLocalStorage(name) {
    localStorage.removeItem(name)
}

export function clearLocalStorage() {
    localStorage.clear()
}

