import React from "react";
import "./style.css";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Spinner({
  children,
  showasIcon = false,
  className = "",
  childClassName = "",
  isSpinning = false,
  ...props
}) {
  return showasIcon ? (
    <svg
      className={`spin-icon ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
      />
    </svg>
  ) : (
    <>
      <div className={`section-spin-container ${childClassName}`}>
        {isSpinning && (
          <div className="section-spin">
            <svg
              className={`section-spin-icon ${className}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              {...props}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </div>
        )}
        <div
          className={
            isSpinning
              ? "section-spin-children"
              : "section-spin-children-non-spin"
          }
        >
          {children}
        </div>
      </div>
    </>
  );
}
