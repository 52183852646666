/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 28/10/23
 * Time: 3:45 pm
 * Project: W-CRM-Frontend
 */
import { createSlice } from "@reduxjs/toolkit";

const testInitialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const generateRSERActions =
  (actionType, key, initialState) => (state, action) => {
    if (actionType === "Requesting") {
      state[key].isLoading = true;
    } else if (actionType === "Success") {
      state[key].isLoading = false;
      state[key].error = null;
      state[key].data = action.payload.data;
    } else if (actionType === "Error") {
      state[key].isLoading = false;
      state[key].error = action.payload;
    } else if (actionType === "Reset") {
      state[key] = initialState[key];
    }
  };

const generateBasicActions = (key, initialState) => {
  const actionNames = ["Requesting", "Success", "Error", "Reset"];
  const actions = {};
  actionNames.forEach((i) => {});
  for (const actionName of actionNames) {
    actions[key + actionName] = generateRSERActions(
      actionName,
      key,
      initialState
    );
  }

  return actions;
};

export const TestSlice = createSlice({
  name: "test",
  initialState: {
    ...testInitialState,
  },
  reducers: {
    // getRequesting: (state, action) => {
    //   state.get.isLoading = true;
    // },
    // getSuccess: (state, action) => {
    //   state.get.isLoading = false;
    //   state.get.error = null;
    //   state.get.data = action.payload.data;
    // },
    // getError: (state, action) => {
    //   state.get.isLoading = false;
    //   state.get.error = action.payload;
    // },
    // getReset: (state, action) => {
    //   state.get = testInitialState.get;
    // },

    ...generateBasicActions("get", testInitialState),
  },
});

export const getRequestTestAPI = () => async (dispatch) => {
  dispatch(getRequesting());
  setTimeout(() => {
    dispatch(getSuccess({ data: "meow is dead" }));
  }, 2000);
  setTimeout(() => {
    dispatch(getReset());
  }, 2000);
};
export const { getRequesting, getSuccess, getError, getReset } =
  TestSlice.actions;
export const TestState = (state) => state.test;
export default TestSlice.reducer;
