/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/14/2022
 * Time: 2:03 PM
 * Project: w_crm
 */

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authState, checkAuth, refreshTokenApi} from "../redux/auth/authReducer";
import {ChangeClientLayoutState, getProfileApi, profileState, updateToken,} from "../redux/profile/profileReducer";
import {eraseCookie, getCookie} from "../utils/cookies";
import {isJwtValid} from "../utils/common";
import {refreshKey, tokenKey} from "../utils/constants";
import {SyncState} from "../redux/sync/syncReducer";
import PrivateMainContainer from "../layouts/PrivateMainContainer/PrivateMainContainer";
import Spinner from "../component/Spinner/Spinner";
import {getFirebaseToken, getMessageInstance} from "../firebase/firebase";
import {onMessage} from "firebase/messaging";

export default function PrivateLayout({
                                          component: Component,
                                          configuration,
                                          ...rest
                                      }) {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    dispatch(checkAuth());
    const {profile} = useSelector(profileState);
    const {current: sync} = useSelector(SyncState);
    const [lastSync, setLastSync] = useState(0);

    useEffect(() => {
        if (lastSync !== sync.time) {
            setLastSync(sync.time);
            handleGetFirebaseToken()
        }
    }, [sync.time, lastSync]);

    const {isAuthenticated, isLoading} = useSelector(authState);

    const [refreshRetry, setRefreshRetry] = useState(0);

    useEffect(() => {
        dispatch(ChangeClientLayoutState(false))
    }, []);

    useEffect(() => {
        const accessToken = getCookie(tokenKey);
        if (!accessToken || !isJwtValid(accessToken)) {
            const refreshToken = getCookie(refreshKey);
            if (!refreshToken || !isJwtValid(refreshToken)) {
                // dispatch(masterLogout());
                navigate('/login', {replace: true});
            } else {
                try {
                    if (refreshToken < 3) {
                        setRefreshRetry(old => old + 1)
                        dispatch(refreshTokenApi({refresh: refreshToken}))
                    } else {
                        eraseCookie(refreshKey)
                    }
                } catch (error) {
                    // dispatch(masterLogout());
                    navigate('/login', {replace: true});
                }
            }
        } else if (!profile.data) {
            dispatch(getProfileApi());
            // initFB()
            handleGetFirebaseToken()
        }
    }, [isAuthenticated, dispatch, navigate, isLoading]);


    // FCM starts -------------------
    useEffect(() => {
        onMessage(getMessageInstance(), {
            next: (payload) => {
                const {data: {destination, title}} = payload;
                const notification = new Notification(title);
                if (destination) {
                    notification.onclick = function () {
                        navigate(`/contact/${destination}#whatsapp`)
                        notification.close()
                    };
                }
            },
            error: (error) => {
            },
            complete: () => {
            }
        });
    }, []);


    const handleGetFirebaseToken = () => {
        // registerServiceWorker()
        getFirebaseToken()
            .then((firebaseToken) => {
                dispatch(updateToken({token: firebaseToken}))
            })
            .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
    }


    const initFB = () => {
        // console.log("init")
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '348366554900749',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v18.0'
            });
        };
    }

    // FCM end -------------------


    return (
        <>
            <Spinner isSpinning={profile.isLoading || isLoading}>
                {profile.data && (
                    <PrivateMainContainer
                        lastSync={lastSync}
                        component={Component}
                        configuration={configuration}
                    />
                )}
            </Spinner>
        </>
    );
}
