/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/7/2022
 * Time: 3:00 PM
 * Project: w_crm
 */

import React from "react";

export default function Close({
  strokeWidth = 3,
  className = "w-1",
  stroke = "currentColor",
  fill = "none",
  props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      stroke={stroke}
      {...props}
    >
      {/*<path strokeLinecap="round" strokeLinejoin="round"*/}
      {/*      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" strokeWidth={strokeWidth}/>*/}

      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
