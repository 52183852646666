import React, {forwardRef, useEffect, useMemo, useRef, useState} from "react";
import Exclamation from "../../icon/Exclamation";
import "./style.css";
import {changeTimeFormate, getUUID} from "../../utils/util";
import DatePicker from "react-datepicker";
import {DATE_DISPLAY_FORMAT, DATE_FORMAT, DEFAULT_DATETIME_FORMAT, FLAT_TIME_FORMAT} from "../../utils/constants";
import CloseCircle from "../../icon/CloseCircle";

export default function Input({
                                  placeHolder = ["", ""],
                                  name = "",
                                  type = "text",
                                  id = getUUID(),
                                  leading = null, // Component
                                  trailing = null, // Component
                                  error = null,
                                  showError = true,
                                  label = null,
                                  labelClassName = "",
                                  inputClassName = "",
                                  wrapperClassName = "",
                                  maxLength = null,
                                  onChange = null,
                                  handleChange = null,
                                  readOnly = false,
                                  onFocus = null,
                                  required = false,
                                  preValue = null,
                                  rows = 5,
                                  disabled = false,
                                  onClick = () => {
                                  },
                                  onLeadingClick = () => {
                                  },
                                  onTrailingClick = () => {
                                  },
                                  focus = false,
                                  size = "default",
                                  showCounter = true,
                                  ...props
                              }) {
    let labelCss = `input-component-default-label ${labelClassName} full-parent-width text-start mb-05`;
    let wrapperCss = `d-flex direction-column ${wrapperClassName}`;
    let inputCss = `input-field-holder ${inputClassName}`;
    let leadingCss = `d-flex absolute pl-05 ${labelClassName}`;
    let trailingCss = `d-flex absolute r-0 pr-05 ${labelClassName} cursor-clickable`;

    if (size === "default") {
        inputCss = `${inputCss} px-3 py-2`;
    } else if (size === "small") {
        inputCss = `${inputCss} px-2-5 py-1-5`;
    } else if (size === "large") {
        inputCss = `${inputCss} px-3-5 py-2-5`;
    }

    const [inputValue, setInputValue] = useState("");

    const [activePlaceHolder, setActivePlaceHolder] = useState(() => {
        if (typeof placeHolder === "string") {
            return placeHolder;
        } else {
            return placeHolder[0];
        }
    });

    const valueChanged = (event) => {
        if (handleChange != null) {
            handleChange(event);
        }
        if (type === "file") {
            let currentValue = event.target.value;
            setInputValue(currentValue);
            onChange(event);
        } else if (type === "datetime-local" || type === "date") {
            let currentValue = event;
            if (preValue != null) {
                setInputValue(currentValue);
            }
            if (onChange != null) {
                onChange(currentValue);
            }
        } else {
            let currentValue = event.target.value
            if (maxLength && currentValue.length > maxLength) {
                currentValue = currentValue.substring(0, maxLength)
            }
            if (preValue != null) {
                setInputValue(currentValue);
            }
            if (onChange != null) {
                onChange(currentValue);
            }
        }
    };

    if (error != null) {
        inputCss = `${inputCss.replace("bg-", "removed")} bg-red input-component-border-red`;
    }

    if (leading != null && trailing != null) {
        inputCss = `${inputCss} px-7`;
    } else if (leading != null) {
        inputCss = `${inputCss} pl-7`;
    } else if (trailing != null) {
        inputCss = `${inputCss} pr-7`;
    }
    const ref = useRef(null);
    const setFocus = (condition) => {
        if (typeof placeHolder !== "string") {
            if (condition) {
                setActivePlaceHolder(placeHolder[1]);
            } else {
                setActivePlaceHolder(placeHolder[0]);
            }
        }
        if (onFocus) {
            onFocus(condition);
        }
    };

    if (preValue == null) {
        preValue = inputValue;
    }

    const focusOnInput = () => {
        document.getElementById(id).focus();
    };
    const getErrorCSS = useMemo(() => {
        let css = "b-0 pl-05 pb-020 pt-02 input-error-holder";
        if (error) {
            css = `${css} active`;
        }
        return css;
    }, [error]);

    const DateTimeInput = forwardRef(({value, onClick}, ref) => (<Input
        size={"large"}
        type={"text"}
        wrapperClassName={"filter-date-picker-input-wrapper"}
        placeHolder={placeHolder}
        name={name}
        readOnly={true}
        onClick={(e) => {
            e.stopPropagation()
            onClick(e)
        }}
        showError={showError}
        showCounter={showCounter}
        error={error}
        trailing={!required && preValue ? <CloseCircle strokeWidth={2} onClick={(event) => {
            event.stopPropagation();
            valueChanged(null)
        }}/> : <></>}
        preValue={preValue ? changeTimeFormate(preValue, DEFAULT_DATETIME_FORMAT) : ""}
    />));

    const DateInput = forwardRef(({value, onClick}, ref) => (<Input
        size={"large"}
        type={"text"}
        wrapperClassName={"filter-date-picker-input-wrapper"}
        placeHolder={placeHolder}
        trailing={!required && preValue ? <CloseCircle strokeWidth={2} onClick={(event) => {
            event.stopPropagation();
            valueChanged(null)
        }}/> : <></>}
        name={name}
        onClick={(e) => {
            e.stopPropagation()
            onClick(e)
        }}
        readOnly={true}
        showError={showError}
        showCounter={showCounter}
        error={error}
        preValue={preValue ? changeTimeFormate(preValue, DATE_DISPLAY_FORMAT) : ""}
    />));
    const [startDate, setStartDate] = useState(null);
    useEffect(() => {
        if (preValue) {
            setStartDate(new Date(preValue))
        }
    }, [preValue]);
    const handlePaste = (event) => {
        let {value, selectionStart, selectionEnd} = event.target;
        let pastedValue = event.clipboardData.getData("text");
        let pre = value.substring(0, selectionStart);
        let post = value.substring(selectionEnd, value.length);
        value = (pre + pastedValue + post).trim();
        valueChanged({target: {value: value}})
    };

    useEffect(() => {
        if (focus) {
            ref.current.focus()
        }
    }, [focus]);

    const getInput = () => {
        if (type === "datetime-local") {
            return <DatePicker
                selected={startDate}
                customInput={<DateTimeInput/>}
                showPopperArrow={false}
                showTimeSelect
                timeIntervals={15}
                onChange={(value) => {
                    if (value) {
                        valueChanged(changeTimeFormate(value, FLAT_TIME_FORMAT))
                    }
                }}
                minDate={new Date()}
                todayButton="Select Today"
            />
        } else if (type === "date") {
            return <DatePicker
                selected={startDate}
                customInput={<DateInput/>}
                showPopperArrow={false}
                timeIntervals={15}
                onChange={(value) => {
                    if (value) {
                        valueChanged(changeTimeFormate(value, DATE_FORMAT))
                    }
                }}
                minDate={new Date()}
                todayButton="Select Today"
            />
        } else if (type === "textarea") {
            return <textarea
                id={id}
                name={name}
                className={inputCss}
                placeholder={activePlaceHolder}
                rows={rows}
                // onPaste={handlePaste}
                onFocus={() => {
                    setFocus(true);
                }}
                onBlur={() => {
                    setFocus(false);
                }}
                onChange={valueChanged}
                value={preValue}
            />
        } else {
            return <input
                id={id}
                name={name}
                type={type}
                className={inputCss}
                disabled={disabled}
                readOnly={readOnly}
                placeholder={activePlaceHolder}
                {...props}
                onFocus={() => {
                    setFocus(true);
                }}
                onBlur={() => {
                    setFocus(false);
                }}
                // onPaste={handlePaste}
                onChange={valueChanged}
                value={preValue}
                ref={ref}
            />
        }
    }

    return (<div className={wrapperCss} onClick={onClick}>
        {label && (<label className={labelCss} htmlFor={id}>
            {label} {required && <span className="red-text">*</span>}
        </label>)}

        <div className="relative d-flex item-center">
            {leading && <div className={leadingCss} onClick={focusOnInput}>{leading}</div>}

            {getInput()}

            {trailing && (<div className={trailingCss} onClick={onTrailingClick}>{trailing}</div>)}

            {maxLength && showCounter && (<div className="absolute r-0 b-0 pb-020 text-11 opacity-05 pr-05">
                {inputValue.length}/{maxLength}
            </div>)}
        </div>
        {(showError && type !== "datetime-local") && (<div className={getErrorCSS}>
            <div>
                <Exclamation className="w-0-8 red-text" strokeWidth={2}/>
            </div>
            <p className="text-12 red-text">{error}</p>
        </div>)}
    </div>);
}
