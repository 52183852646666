import store from "../../redux/store";
import {ACCOUNT_SWITCH_USER_VIEW} from "../constants";

/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 11/07/23
 * Time: 1:04 pm
 * Project: W-CRM-Frontend
 */
export const isPrivilegeAllowed = (allowed = []) => {
    const state = store.getState();
    return allowed.includes(
        state.profile.profile?.data?.userorgrolemapping?.role?.name
    );
};

export const isPlanAllowed = (allowed = []) => {
    const state = store.getState();
    return allowed.includes(
        state.profile.profile.data?.usersubscriptionmapping?.subscription?.name
    );
};

export const isCULActionAllowed = () => {
    const state = store.getState();
    return state.profile?.viewingAs?.data?.id !== ACCOUNT_SWITCH_USER_VIEW;
};