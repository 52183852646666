/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 25/08/22
 * Time: 2:39 PM
 * Project: W-CRM
 */
import React, {useEffect, useState} from "react";
import "./style.css";
import NavigationVerticalItem from "./Components/NavigationVerticalItem";
import {routes} from "../../routes/UrlRoutes";
import {useLocation, useNavigate} from "react-router-dom";
import TopNavigation from "./Components/TopNavigation";
import {generateBreadCrumbs} from "../../utils/common";
import {isPrivilegeAllowed} from "../../utils/PrivilegeControl/PrivilegeControl";

export default function NavigationController({children}) {
    const [navExpand, setNavExpand] = useState(true);
    const [mobileNavExpand, setMobileNavExpand] = useState(false);
    let brand_logo_pc =
        "https://ik.imagekit.io/kittydev/Workaxis/Logo/workaxis_dark_5CP_R5uXN.svg";
    let brand_logo_mobile =
        "https://ik.imagekit.io/kittydev/Workaxis/Logo/w_dark_TWqhf3EFi.svg";
    let navigate = useNavigate();
    let location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        setBreadcrumbs(generateBreadCrumbs(location.pathname));
    }, [location.pathname]);

    return (
        <div className={"navigation-parent-container"}>
            <div className={"navigation-top-nav-container"}>
                <TopNavigation
                    mobileNavExpand={mobileNavExpand}
                    mobileNavCallback={() => {
                        setMobileNavExpand((old) => !old);
                    }}
                />
            </div>
            <div className={"navigation-body-container"}>
                <div
                    className={
                        "navigation-left-nav-container " + (navExpand ? "" : "collapsed")
                    }
                >
                    <div className={"navigation-vertical-item-container"}>
                        {routes.private
                            .filter((value) => {
                                let isSelected = value.showOnNav !== false;
                                if (value.notAllowed && isSelected) {
                                    isSelected = !isPrivilegeAllowed(value.notAllowed)
                                }
                                return isSelected
                            })
                            .map((value) => {
                                const _isActive = breadcrumbs.some(
                                    (i) => i.actualPath === value.path
                                );
                                return (
                                    <NavigationVerticalItem
                                        nav={value}
                                        key={value.path}
                                        isActive={
                                            _isActive ||
                                            (value.path === "/" && breadcrumbs.length === 0)
                                        }
                                        onSelect={() => {
                                            navigate(value.path);
                                        }}
                                    />
                                );
                            })}
                    </div>
                </div>

                <div
                    className={"navigation-vertical-toggle-holder clickable"}
                    onClick={() => setNavExpand((old) => !old)}
                >
                    <div className={"wx-icons"}>arrow_forward_ios</div>
                </div>

                <div
                    className={
                        "navigation-right-nav-container onlyNavMobile " +
                        (mobileNavExpand ? "" : "collapsed")
                    }
                >
                    <div className={"mobile-navigation-holder"}>
                        <div className={"mobile-navigation-header-holder"}>
                            <div
                                className={"mobile-navigation-toggle-holder clickable"}
                                onClick={() => {
                                    setMobileNavExpand((old) => !old);
                                }}
                            >
                                {mobileNavExpand ? (
                                    <div className={"wx-icons"}>close</div>
                                ) : (
                                    <div className={"wx-icons"}>menu</div>
                                )}
                            </div>
                            <img
                                src={brand_logo_mobile}
                                alt="workaxis-logo"
                                className={"mobile-navigation-brand-icon"}
                            />
                        </div>
                        <div className={"mobile-navigation-item-holder"}>
                            {routes.private.map((nav) => {
                                const {icon: Icon} = nav;
                                const isActive = breadcrumbs.some(
                                    (i) => i.actualPath === nav.path
                                );
                                let css = "mobile-navigation-item clickable";
                                if (isActive) {
                                    css = `${css} active`;
                                }
                                if (nav.showOnNav) {
                                    return (
                                        <div
                                            className={css}
                                            onClick={() => {
                                                navigate(nav.path);
                                                setMobileNavExpand(false);
                                            }}
                                            key={nav.name}
                                        >
                                            <Icon
                                                className={"mobile-nav-icon"}
                                                strokeWidth={isActive ? 2.5 : 2}
                                            />
                                            <span className={"text"}>{nav.name}</span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className={"navigation-page-container"}>{children}</div>
            </div>
        </div>
    );
}
