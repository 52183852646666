/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 06/10/23
 * Time: 9:46 am
 * Project: W-CRM-Frontend
 */
import React, {memo, useEffect, useState} from "react";
import "./style.css";
import {Link, useLocation} from "react-router-dom";
import {URL_HOME} from "../../../routes/url";
import {routes} from "../../../routes/UrlRoutes";
import ChevronRight from "../../../icon/ChevronRight";
import {generateBreadCrumbs} from "../../../utils/common";
import {isCULActionAllowed} from "../../../utils/PrivilegeControl/PrivilegeControl";
import TriangleExclamation from "../../../icon/TriangleExclamation";

export const Breadcrums = memo(() => {
    const location = useLocation();
    const [breadList, setBreadList] = useState([]);

    const getPathRoute = (path) => {
        return routes.private.filter((i) => i.path === path);
    };

    useEffect(() => {
        const breadcrumbs = [];
        const path = location.pathname;
        if (path !== URL_HOME) {
            const homeBread = getPathRoute(URL_HOME)[0];
            if (homeBread)
                breadcrumbs.push({
                    label: homeBread.name,
                    icon: homeBread.icon,
                    actualPath: homeBread.path,
                    path: homeBread.path,
                });
        }
        setBreadList([...breadcrumbs, ...generateBreadCrumbs(location.pathname)]);
    }, [location.pathname]);

    return (
        <div className={"bread-notice-container"}>
            {location.pathname !== URL_HOME ? (
                <div className={`breadcrumb-container default`}>
                    {breadList.map((i, idx) => {
                        const {icon: Icon, actualPath, path} = i;
                        let clickablePath = actualPath;
                        if (actualPath.includes(":")) {
                            clickablePath = path;
                        }
                        let NextIcon = ChevronRight;
                        if (idx !== 0) {
                            return (
                                <div className={"breadcrumb-item-holder"} key={i.label}>
                                    <NextIcon className={"breadcrumb-icon"}/>
                                    <Link
                                        style={
                                            idx === breadList.length - 1
                                                ? {pointerEvents: "none"}
                                                : null
                                        }
                                        to={clickablePath}
                                    >
                                        <span className={"text-default"}>{i.label}</span>
                                    </Link>
                                </div>
                            );
                        } else {
                            return (
                                <div className={"breadcrumb-item-holder"} key={i.label}>
                                    {Icon && <Icon className={"breadcrumb-icon"}/>}
                                    <Link to={clickablePath}><span className={"text-default"}>{i.label}</span></Link>
                                </div>
                            );
                        }
                    })}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
});
