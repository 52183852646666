/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 11/07/22
 * Time: 10:00 AM
 * Project: W-CRM
 */
import { createSlice } from "@reduxjs/toolkit";

const SyncInitialState = {
  current: {
    time: 0,
  },
};

export const SyncSlice = createSlice({
  name: "sync",
  initialState: {
    ...SyncInitialState,
  },
  reducers: {
    syncNow: (state, action) => {
      state.current.time = action.payload;
    },
    SyncMasterReset: () => SyncInitialState,
  },
});
export const { syncNow, SyncMasterReset } = SyncSlice.actions;
export const SyncState = (state) => state.sync;
export default SyncSlice.reducer;
