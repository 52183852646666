/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 17/07/22
 * Time: 6:43 PM
 * Project: W-CRM
 */
import { createSlice, current } from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import { formatAxiosErrorMessage, qargsToQstring } from "../../utils/common";
import { endPoints } from "../../api/endpoints";

const CommunicationInitialData = {
  email: {
    isLoading: false,
    data: null,
    error: null,
  },
  sendEmail: {
    isLoading: false,
    data: null,
    error: null,
  },
};

export const CommunicationSlice = createSlice({
  name: "communication",
  initialState: {
    ...CommunicationInitialData,
  },
  reducers: {
    emailCommunicationRequesting: (state, action) => {
      state.email.isLoading = true;
      state.email.isCreated = false;
    },
    emailCommunicationSuccess: (state, action) => {
      const { email } = current(state);
      const newData = action.payload.data;
      if (email?.data?.results) {
        newData.results = [...email.data.results, ...newData.results];
      }
      state.email.isLoading = false;
      state.email.error = null;
      state.email.data = newData;
    },
    emailCommunicationRefreshSuccess: (state, action) => {
      state.email.isLoading = false;
      state.email.error = null;
      state.email.data = action.payload.data;
    },
    emailCommunicationError: (state, action) => {
      state.email.isLoading = false;
      state.email.isCreated = false;
      state.email.error = action.payload;
    },
    emailCommunicationReset: (state, action) => {
      state.email = CommunicationInitialData.email;
    },

    sendEmailRequesting: (state, action) => {
      state.sendEmail.isLoading = true;
      state.sendEmail.error = null;
      state.sendEmail.data = null;
    },
    sendEmailSuccess: (state, action) => {
      state.sendEmail.isLoading = false;
      state.sendEmail.error = null;
      state.sendEmail.data = action.payload.data;
    },
    sendEmailError: (state, action) => {
      state.sendEmail.isLoading = false;
      state.sendEmail.data = null;
      state.sendEmail.error = action.payload;
    },
    sendEmailReset: (state, action) => {
      state.sendEmail = CommunicationInitialData.sendEmail;
    },
    CommunicationMasterReset: () => CommunicationInitialData,
  },
});

export const getEmailAPI = (query) => async (dispatch) => {
  let stringQuery = qargsToQstring(query);
  dispatch(emailCommunicationRequesting());
  let responseURL = endPoints.communication.getEmailConversation;
  apiService
    .get(`${responseURL}` + stringQuery, {})
    .then((response) => {
      if (query.offset === 0) {
        dispatch(emailCommunicationRefreshSuccess(response.data));
      } else {
        dispatch(emailCommunicationSuccess(response.data));
      }
    })
    .catch((error) =>
      dispatch(emailCommunicationError(formatAxiosErrorMessage(error)))
    );
};

export const sendEmailAPI = (data) => async (dispatch) => {
  dispatch(sendEmailRequesting());
  apiService
    .post(endPoints.communication.getEmailConversation, data)
    .then((response) => {
      dispatch(sendEmailSuccess(response.data));
    })
    .catch((error) => {
      dispatch(sendEmailError(formatAxiosErrorMessage(error)));
    });
};
export const {
  emailCommunicationRequesting,
  emailCommunicationSuccess,
  emailCommunicationRefreshSuccess,
  emailCommunicationError,
  emailCommunicationReset,

  sendEmailRequesting,
  sendEmailSuccess,
  sendEmailError,
  sendEmailReset,

  CommunicationMasterReset,
} = CommunicationSlice.actions;
export const CommunicationState = (state) => state.communication;
export default CommunicationSlice.reducer;
