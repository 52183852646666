import Close from "../../icon/Close";
import ReactDOM from "react-dom";
import "./style.css";
import Button from "../Button/Button";

export default function Modal({
                                  isOpen = false,
                                  title = "",
                                  titleIcon = null,
                                  onClose = null,
                                  hideClose = false,
                                  hideCancel = false,
                                  hideOk = false,
                                  closeOnExternal = false,
                                  size = "default", //supported values - default, large, xlarge
                                  showFooter = false,
                                  onCancel = null, //will be used only if showfooter is true - function to call on clicking cancel button
                                  onOk = null, //will be used only if showfooter is true - function to call on clicking ok button
                                  cancelButtonText = "Cancel", //will be used only if showfooter is true
                                  okButtonText = "Ok", //will be used only if showfooter is true
                                  isLoading = false,
                                  isDisabled = false,
                                  blur = false,
                                  children,
                              }) {


    // if (!isOpen) return null;


    return ReactDOM.createPortal(
        <div className={`modal-section ${isOpen ? "active" : ""} ${blur ? "blur" : ""}`}>
            <div className="modal">
                <div
                    className="overlay"
                    onClick={() => closeOnExternal && onClose()}
                ></div>

                {isOpen &&
                    <div
                        className={`content ${isOpen ? "active" : ""} ${
                            size === "xlarge"
                                ? "content-xlarge"
                                : size === "large"
                                    ? "content-large"
                                    : "content-default"
                        }`}
                    >
                        <div className="modal-header">
                            <div className="model-title-section">
                                {titleIcon}
                                <div className="model-title">{title}</div>
                            </div>

                            {!hideClose && (
                                <div className="close">
                                    <Button type="link" size="fit-content" onClick={onClose}>
                                        <span className="sr-only">Close</span>
                                        <Close className="close-icon" aria-hidden="true"/>
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="modal-body">
                            <div>{children}</div>
                        </div>
                        {showFooter && (
                            <div className="modal-footer">
                                {!hideOk && (
                                    <Button
                                        isLoading={isLoading}
                                        disabled={isLoading || isDisabled}
                                        type="primary"
                                        onClick={onOk}
                                    >
                                        {okButtonText}
                                    </Button>
                                )}
                                {!hideCancel && (
                                    <Button type="primary" onClick={onCancel}>
                                        {cancelButtonText}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                }

            </div>
        </div>,
        document.getElementById("modal-root")
    );
}