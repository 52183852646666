/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 07/06/23
 * Time: 11:43 am
 * Project: W-CRM-Frontend
 */
import React from "react";

export default function HomeIcon({
  strokeWidth = 3,
  className = "w-1",
  stroke = "currentColor",
  fill = "none",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={strokeWidth}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      stroke={stroke}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
      />
    </svg>
  );
}
