/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 24/08/22
 * Time: 11:01 AM
 * Project: smallcal
 */
import React from "react";
import "../style.css";

export default function NavigationVerticalItem({
  nav,
  isActive = false,
  onSelect = () => {},
}) {
  const { name, icon: Icon } = nav;
  let holderCSS = "br-12 navigation-vertical-item-holder clickable";
  if (isActive) {
    holderCSS = `${holderCSS} active`;
  }
  return (
    <div className={holderCSS} onClick={onSelect}>
      <Icon className="wx-icons nav-icon-holder" strokeWidth={2} />
      <div className={"vertical-nav-icon-label-holder"}>
        <p className={"vertical-nav-icon-label"}>{name}</p>
      </div>
      {/* <div className={"navigation-vertical-item-data-holder"}>
        <p className={"navigation-vertical-item-data-title"}>{name}</p>
        {isActive && (
          <div className={"navigation-vertical-item-active-indicator"} />
        )}
      </div> */}
    </div>
  );
}
