import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import "./css/main.css";
import "./css/colors.css";
import "./css/responsive.css";
import "./css/sub.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {SuspenseLoading} from "./component/LoadingSpinner/suspense/SuspenseLoading";

ReactDOM.render(
    // <React.StrictMode>
    <Suspense fallback={<SuspenseLoading/>}>
        <BrowserRouter>
            <DevSupport
                ComponentPreviews={ComponentPreviews}
                useInitialHook={useInitial}
            >
                <App/>
            </DevSupport>
        </BrowserRouter>
    </Suspense>,
    // </React.StrictMode>,
    document.getElementById("root")
);
