/**
 * Created by Kittxdev www.kittxdev.com
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@kittxdev.com
 * Date: 16/08/24
 * Time: 11:56:01
 * Project: W-CRM
 */
import React, {memo} from 'react';
import "./style.css"

export const SuspenseLoading = memo(() => {
    return (
        <div className={"suspense-fill-parent"}>
            <div className="loader"></div>
        </div>
    );
})