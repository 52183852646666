/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 10/11/23
 * Time: 4:59 pm
 * Project: W-CRM-Frontend
 */
import React, {memo, useEffect, useState} from "react";
import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {
    getMyInviteAPI,
    inviteActionAPI,
    inviteActionReset,
    OrganizationState,
} from "../../redux/Organization/organizationReducer";
import {profileState} from "../../redux/profile/profileReducer";
import {
    Desktop,
    Mobile,
    MY_INVITES_PAGE_ACCEPT_MODEL,
    MY_INVITES_PAGE_DECLINE_MODEL,
    Tablet,
} from "../../utils/constants";
import {ListViewFix} from "../Lead/ListViewFix";
import {ActivateModel, DeActivateModel, ModelState,} from "../../redux/Model/modelReducer";
import {NameIcon} from "../../component/NameIcon/NameIcon";
import MenuHolder from "../../component/MenuHolder/MenuHolder";
import PlayGround from "../../playground/PlayGround";
import Check from "../../icon/Check";
import Close from "../../icon/Close";
import BuildingOffice from "../../icon/BuildingOffice";
import Spinner from "../../component/Spinner/Spinner";
import Alert from "../../component/Alert/Alert";
import Modal from "../../component/Model/Modal";
import Tooltip from "../../component/Tooltip/Tooltip";

export const MyInvites = memo(({onlyPending = false}) => {
    const {profile} = useSelector(profileState);

    const {myInvites: list, inviteAction} = useSelector(OrganizationState);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();

    const refreshList = () => {
        dispatch(getMyInviteAPI({my_invites: true}));
    };
    useEffect(() => {
        refreshList();
    }, [currentPage]);

    const [selectedInvite, setSelectedInvite] = useState(null);

    const ControlItems = (item) => {
        return [
            {
                name: "Accept",
                icon: <Check strokeWidth={2} className="w-1-5"/>,
                action: () => {
                    setSelectedInvite(item);
                    dispatch(ActivateModel(MY_INVITES_PAGE_ACCEPT_MODEL));
                },
                isEnabled: item.status === "pending",
            },
            {
                name: "Decline",
                icon: <Close strokeWidth={2} className="w-1-5"/>,
                action: () => {
                    setSelectedInvite(item);
                    dispatch(ActivateModel(MY_INVITES_PAGE_DECLINE_MODEL));
                },
                isEnabled: item.status === "pending",
            },
        ];
    };
    const getListItem = () => {
        const listCols = [];
        if (!onlyPending) {
            listCols.push(
                {
                    prefix: (
                        <NameIcon
                            icon={<BuildingOffice className={"w-2"} strokeWidth={2}/>}
                            color={"orange-set"}
                        />
                    ),
                    title: "Org name",
                    accessor: "name",
                    visibleOn: [Desktop],
                    size: "20",
                    index: 1,
                    dataManipulation: (row) => {
                        let {name} = row.organization;
                        return name;
                    },
                },
                {
                    title: "Org name",
                    visibleOn: [Tablet, Mobile],
                    accessor: "name",
                    size: "20",
                    index: 1,
                    dataManipulation: (row) => {
                        let {name} = row.organization;
                        return name;
                    },
                },
                {
                    title: " ",
                    accessor: "actions",
                    size: "10",
                    index: 6,
                    visibleOn: [Desktop],
                    cell: (row) => {
                        return (
                            <div className={"list-action-holder"}>
                                <MenuHolder menuItems={ControlItems(row)}/>
                            </div>
                        );
                    },
                },
                {
                    title: "Status",
                    accessor: "status",
                    size: "10",
                    index: 4,
                },
                {
                    title: (
                        <NameIcon
                            icon={<BuildingOffice className={"w-2"} strokeWidth={2}/>}
                            color={"orange-set"}
                        />
                    ),
                    accessor: "nothing",
                    size: "5",
                    visibleOn: [Tablet, Mobile],
                    index: 0,
                    cell: (row) => {
                        return (
                            <div className={"list-action-holder"}>
                                <MenuHolder menuItems={ControlItems(row)}/>
                            </div>
                        );
                    },
                }
            );
        } else {
            listCols.push(
                {
                    prefix: (
                        <NameIcon
                            icon={<BuildingOffice className={"w-2"} strokeWidth={2}/>}
                            color={"orange-set"}
                        />
                    ),
                    title: "Org name",
                    accessor: "name",
                    size: "20",
                    index: 1,
                    cell: (row) => {
                        let {name} = row.organization;
                        return <div className={"w-100"}>
                            <p>Org name</p>
                            <Tooltip toolTip={name}>
                                <p className={"max-lines value"}>{name}</p>
                            </Tooltip>
                        </div>
                    },
                },
                {
                    title: " ",
                    accessor: "actions",
                    size: "10",
                    index: 6,

                    cell: (row) => {
                        return (
                            <div className={"list-action-holder"}>
                                <MenuHolder menuItems={ControlItems(row)}/>
                            </div>
                        );
                    },
                }
            );
        }
        return listCols;
    };

    const {active} = useSelector(ModelState);

    const closeConfirmations = () => {
        dispatch(DeActivateModel(MY_INVITES_PAGE_DECLINE_MODEL));
        dispatch(DeActivateModel(MY_INVITES_PAGE_ACCEPT_MODEL));
        dispatch(inviteActionReset());
        setSelectedInvite(null);
    };

    useEffect(() => {
        if (inviteAction.data) {
            closeConfirmations();
        }
    }, [inviteAction.data]);

    return (
        <>
            <Modal
                closeOnExternal={true}
                isOpen={active[MY_INVITES_PAGE_ACCEPT_MODEL]}
                onClose={closeConfirmations}
                onCancel={closeConfirmations}
                okButtonText={"Accept"}
                title={"Accept invite"}
                description={""}
                size={"alert"}
                titleIcon={<Check strokeWidth={2} className={"w-2"}/>}
                showFooter={true}
                onOk={() =>
                    dispatch(
                        inviteActionAPI(
                            {organization_id: selectedInvite.organization.id},
                            "accept"
                        )
                    )
                }
                isLoading={inviteAction.isLoading}
            >
                {inviteAction.error && (
                    <Alert
                        type={"error"}
                        description={inviteAction.error}
                        title={"Unable to accept the invite."}
                    />
                )}
                <div className={"mt-1"}>
                    <Spinner isSpinning={inviteAction.isLoading}>
                        <div>
                            <p className={"text-sub-heading"}>
                                Are you sure, you want to accept the invite from <span
                                className={"text-bold"}>&nbsp;{selectedInvite?.organization?.name}&nbsp;</span>?
                            </p>
                            <p className={"text-sub-heading"}>
                                Please note that upon accepting this invitation, we will migrate some of your existing
                                data, including contacts, e-forms, documents, and appointments, into the organization.
                                Additionally, the WhatsApp configuration will be deleted. Accepting this invite will
                                automatically decline all other invitations.
                            </p>
                        </div>
                    </Spinner>
                </div>
            </Modal>

            <Modal
                closeOnExternal={true}
                isOpen={active[MY_INVITES_PAGE_DECLINE_MODEL]}
                onClose={closeConfirmations}
                onCancel={closeConfirmations}
                okButtonText={"Decline"}
                title={"Decline invite"}
                description={""}
                size={"alert"}
                titleIcon={<Close strokeWidth={2} className={"w-2"}/>}
                showFooter={true}
                onOk={() =>
                    dispatch(
                        inviteActionAPI(
                            {organization_id: selectedInvite.organization.id},
                            "decline"
                        )
                    )
                }
                isLoading={inviteAction.isLoading}
            >
                {inviteAction.error && (
                    <Alert
                        type={"error"}
                        description={inviteAction.error}
                        title={"Unable to decline the invite."}
                    />
                )}
                <div className={"mt-1"}>
                    <Spinner isSpinning={inviteAction.isLoading}>
                        <div>
                            <p className={"text-sub-heading"}>
                                Are you sure, you want to decline the invite from{" "} ?
                                <span className={"text-bold"}>
                  {selectedInvite?.organization?.name}
                </span>
                                .This process is not reversible.
                            </p>
                        </div>
                    </Spinner>
                </div>
            </Modal>

            <Spinner isSpinning={inviteAction.isLoading || list.isLoading}>
                <PlayGround
                    isControl={false}
                    isPagination={true}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    totalCount={list.data !== null ? list.data.count : 0}
                    isLoading={list.isLoading}
                >
                    <ListViewFix
                        data={list?.data?.results ?? []}
                        override={
                            !onlyPending
                                ? {}
                                : {
                                    [Desktop]: Desktop,
                                    [Tablet]: Desktop,
                                    [Mobile]: Desktop,
                                }
                        }
                        header={getListItem()}
                    />
                </PlayGround>
            </Spinner>
        </>
    );
});
