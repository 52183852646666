/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 23/10/23
 * Time: 3:22 pm
 * Project: W-CRM-Frontend
 */
import React, {useEffect, useImperativeHandle, useMemo, useState} from "react";
import "./style.css";
import ModernSelect from "../ModernSelect/ModernSelect";
import InfoLabel from "../InfoLabel/InfoLabel";
import {useDispatch, useSelector} from "react-redux";
import {
    getUsersSelectionListRequest,
    OrganizationState,
    usersSelectionListReset,
} from "../../redux/Organization/organizationReducer";

export const UserSelection = React.forwardRef(({selectedUser, label = "user", callback, exclude = []}, ref) => {
    const dispatch = useDispatch();
    const {usersSelectionList: users} = useSelector(OrganizationState);

    const getUsersList = useMemo(() => {
        const results = users?.data?.results || [];
        const selectedUserAlreadyExists = results.some((i) => i.id === selectedUser?.id);

        const mapped = results.filter(i => !exclude.includes(i.id)).map((i) => ({
            id: i.id,
            value: `${i.first_name} ${i.last_name}`,
        }));
        if (selectedUser && !selectedUserAlreadyExists) {
            mapped.push({
                id: selectedUser.id,
                value: `${selectedUser.first_name} ${selectedUser.last_name}`,
            });
        }
        return mapped;
    }, [selectedUser, users?.data]);

    const userSearch = (key) => {
        dispatch(getUsersSelectionListRequest({q: key}));
    };
    useEffect(() => {
        dispatch(getUsersSelectionListRequest({}));
        return () => {
            dispatch(usersSelectionListReset());
        };
    }, [dispatch]);

    const [selected, setSelected] = useState(null)
    const clearSelection = () => {
        setSelected(null)
    }

    useImperativeHandle(ref, () => ({
        clearSelection() {
            return clearSelection();
        },
    }));

    return (
        <InfoLabel label={label}>
            {/*<Spinner isSpinning={users.isLoading}>*/}
            <ModernSelect
                // preSelected={selectedUser ? selectedUser.id : null}
                preSelected={selected}
                isLoading={users.isLoading}
                isSearch={true}
                options={getUsersList}
                searchCallback={userSearch}
                onSelect={(val) => {
                    setSelected(val)
                    callback(val)
                }}
                disabled={users.isLoading}
            />
            {/*</Spinner>*/}
        </InfoLabel>
    );

})