/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 5/17/2022
 * Time: 12:11 PM
 * Project: w_crm
 */
import {createSlice} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {endPoints} from "../../api/endpoints";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import {apiUrlBuilder} from "../../utils/util";

const ClientInitialState = {
    list: {
        isLoading: false,
        data: null,
        error: null,
    },
    selection: {
        isLoading: false,
        data: null,
        error: null,
    },
    update: {
        isLoading: false,
        data: null,
        error: null,
    },
    create: {
        isLoading: false,
        data: null,
        error: null,
        isCreated: false,
    },
    fetch: {
        isLoading: false,
        data: null,
        error: null,
    },
    delete: {
        isLoading: false,
        data: null,
        error: null,
    },
    export: {
        isLoading: false,
        data: null,
        error: null,
    },
    tagList: {
        isLoading: false,
        data: null,
        error: null,
    },
};

export const ClientSlice = createSlice({
    name: "client",
    initialState: {
        ...ClientInitialState,
    },
    reducers: {
        createClientRequesting: (state, action) => {
            state.create.isLoading = true;
            state.create.isCreated = false;
        },
        createClientSuccess: (state, action) => {
            state.create.isLoading = false;
            state.create.error = null;
            state.create.data = action.payload.data;
            state.create.isCreated = true;
        },
        createClientError: (state, action) => {
            state.create.isLoading = false;
            state.create.isCreated = false;
            state.create.error = action.payload;
        },
        createClientReset: (state, action) => {
            state.create = ClientInitialState.create;
        },

        clientListRequesting: (state, action) => {
            state.list.isLoading = true;
        },
        clientListSuccess: (state, action) => {
            state.list.isLoading = false;
            state.list.error = null;
            state.list.data = action.payload.data;
        },
        clientListError: (state, action) => {
            state.list.isLoading = false;
            state.list.error = action.payload;
        },
        clientListReset: (state, action) => {
            state.list = ClientInitialState.list;
        },


        clientSelectionListRequesting: (state, action) => {
            state.selection.isLoading = true;
        },
        clientSelectionListSuccess: (state, action) => {
            state.selection.isLoading = false;
            state.selection.error = null;
            state.selection.data = action.payload.data;
        },
        clientSelectionListError: (state, action) => {
            state.selection.isLoading = false;
            state.selection.error = action.payload;
        },
        clientSelectionListReset: (state, action) => {
            state.selection = ClientInitialState.selection;
        },

        clientDeleteRequesting: (state, action) => {
            state.delete.isLoading = true;
        },
        clientDeleteSuccess: (state, action) => {
            state.delete.isLoading = false;
            state.delete.error = null;
            state.delete.data = true;
        },
        clientDeleteError: (state, action) => {
            state.delete.isLoading = false;
            state.delete.error = action.payload;
        },
        clientDeleteReset: (state, action) => {
            state.delete = ClientInitialState.delete;
        },

        clientUpdateRequesting: (state, action) => {
            state.update.isLoading = true;
        },
        clientUpdateSuccess: (state, action) => {
            state.update.isLoading = false;
            state.update.error = null;
            state.update.data = action.payload.data;
        },
        clientUpdateError: (state, action) => {
            state.update.isLoading = false;
            state.update.error = action.payload;
        },
        clientUpdateReset: (state, action) => {
            state.update = ClientInitialState.update;
        },

        clientFetchRequesting: (state, action) => {
            state.fetch.isLoading = true;
        },
        clientFetchSuccess: (state, action) => {
            state.fetch.isLoading = false;
            state.fetch.error = null;
            state.fetch.data = action.payload.data;
        },
        clientFetchError: (state, action) => {
            state.fetch.isLoading = false;
            state.fetch.error = action.payload;
        },
        clientFetchReset: (state, action) => {
            state.fetch = ClientInitialState.fetch;
        },

        clientExportRequesting: (state, action) => {
            state.export.isLoading = true;
        },
        clientExportSuccess: (state, action) => {
            state.export.isLoading = false;
            state.export.error = null;
            state.export.data = "file";
        },
        clientExportError: (state, action) => {
            state.export.isLoading = false;
            state.export.error = action.payload;
        },
        clientExportReset: (state, action) => {
            state.export = ClientInitialState.export;
        },


        clientTagListRequesting: (state, action) => {
            state.tagList.isLoading = true;
        },
        clientTagListSuccess: (state, action) => {
            state.tagList.isLoading = false;
            state.tagList.error = null;
            state.tagList.data = action.payload.data;
        },
        clientTagListError: (state, action) => {
            state.tagList.isLoading = false;
            state.tagList.error = action.payload;
        },
        clientTagListReset: (state, action) => {
            state.tagList = ClientInitialState.tagList;
        },

        ClientMasterReset: () => ClientInitialState,
    },
});

export const GetClientListApi = (query = {}) => async (dispatch) => {
    dispatch(clientListRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.client.list, null, query), {})
        .then((response) => {
            dispatch(clientListSuccess(response.data));
        })
        .catch((error) =>
            dispatch(clientListError(formatAxiosErrorMessage(error)))
        );
};

export const GetClientSelectionListApi = (query = {}) => async (dispatch) => {
    dispatch(clientSelectionListRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.client.list, null, query), {})
        .then((response) => {
            dispatch(clientSelectionListSuccess(response.data));
        })
        .catch((error) =>
            dispatch(clientSelectionListError(formatAxiosErrorMessage(error)))
        );
};

export const DeleteClientDataApi = (urlData) => async (dispatch) => {
    dispatch(clientDeleteRequesting());
    apiService
        .delete(apiUrlBuilder(endPoints.client.detail, urlData), {})
        .then((response) => {
            dispatch(clientDeleteSuccess());
        })
        .catch((error) =>
            dispatch(clientDeleteError(formatAxiosErrorMessage(error)))
        );
};

export const CreateClientDataApi = (data) => async (dispatch) => {
    dispatch(createClientRequesting());
    apiService
        .post(endPoints.client.list, data)
        .then((response) => {
            dispatch(createClientSuccess(response.data));
        })
        .catch((error) => {
            dispatch(createClientError(formatAxiosErrorMessage(error)));
        });
};

export const UpdateClientDataApi = (urlData, data) => async (dispatch) => {
    dispatch(clientUpdateRequesting());
    apiService
        .patch(apiUrlBuilder(endPoints.client.detail, urlData), data)
        .then((response) => {
            dispatch(clientUpdateSuccess(response.data));
        })
        .catch((error) => {
            dispatch(clientUpdateError(formatAxiosErrorMessage(error)));
        });
};

export const UpdateLeadDataApi = (urlData, data) => async (dispatch) => {
    dispatch(clientUpdateRequesting());
    apiService
        .patch(apiUrlBuilder(endPoints.client.lead, urlData), data)
        .then((response) => {
            dispatch(clientUpdateSuccess(response.data));
        })
        .catch((error) => {
            dispatch(clientUpdateError(formatAxiosErrorMessage(error)));
        });
};

export const FetchClientDataApi = (urlData) => async (dispatch) => {
    dispatch(clientFetchRequesting());
    apiService
        .get(apiUrlBuilder(endPoints.client.detail, urlData), {})
        .then((response) => {
            dispatch(clientFetchSuccess(response.data));
        })
        .catch((error) => {
            dispatch(clientFetchError(formatAxiosErrorMessage(error)));
        });
};

export const FetchClientTagListApi = (urlData) => async (dispatch) => {
    let stringQuery = qargsToQstring(urlData);
    dispatch(clientTagListRequesting());
    apiService
        .get(endPoints.client.tags + stringQuery, {})
        .then((response) => {
            dispatch(clientTagListSuccess(response.data));
        })
        .catch((error) => {
            dispatch(clientTagListError(formatAxiosErrorMessage(error)));
        });
};

export const ExportClientListApi = (query = {}) => async (dispatch) => {
    dispatch(clientExportRequesting());
    // console.log(query)
    apiService
        .get(apiUrlBuilder(endPoints.client.export, null, query), {
            responseType: 'blob',
            "Access-Control-Expose-Headers": '*',
        })
        .then((response) => {
            const filenameHeader = response.headers['content-disposition'] || response.headers['Content-Disposition'];
            // console.log(response)
            // console.log(filenameHeader)

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_contact.csv');
            document.body.appendChild(link);
            link.click();
            dispatch(clientExportSuccess())
        })
        .catch((error) => {
                // console.log(error)
                dispatch(clientExportError(formatAxiosErrorMessage(error)))
            }
        );
};
export const {
    clientListRequesting,
    clientListSuccess,
    clientListError,
    clientListReset,

    clientSelectionListRequesting,
    clientSelectionListSuccess,
    clientSelectionListError,
    clientSelectionListReset,

    createClientRequesting,
    createClientSuccess,
    createClientError,
    createClientReset,

    clientUpdateRequesting,
    clientUpdateSuccess,
    clientUpdateError,
    clientUpdateReset,

    clientFetchError,
    clientFetchRequesting,
    clientFetchReset,
    clientFetchSuccess,

    clientDeleteRequesting,
    clientDeleteSuccess,
    clientDeleteError,
    clientDeleteReset,

    clientExportRequesting,
    clientExportSuccess,
    clientExportError,
    clientExportReset,

    clientTagListRequesting,
    clientTagListSuccess,
    clientTagListError,
    clientTagListReset,


    ClientMasterReset,
} = ClientSlice.actions;
export const ClientState = (state) => state.client;
export default ClientSlice.reducer;
