/**
 * Created by workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 21/05/24
 * Time: 3:29 PM
 * Project: W-CRM
 */
import React from "react";

export default function WhatsappRawIcon({
                                            strokeWidth = 3,
                                            className = "w-1",
                                            stroke = "currentColor",
                                            fill = "none",
                                        }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill={stroke}
            className={className}
            strokeWidth={strokeWidth}
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    d="M17.758 13.79c-.047-.02-1.797-.884-2.106-.997-.129-.043-.265-.09-.41-.09-.234 0-.433.121-.586.352-.176.258-.707.879-.867 1.062-.023.024-.05.055-.07.055-.016 0-.285-.113-.367-.149-1.88-.816-3.301-2.777-3.497-3.105-.03-.05-.03-.07-.03-.07a.64.64 0 01.1-.121c.098-.094.2-.22.302-.34.046-.055.093-.114.14-.168.145-.168.211-.297.285-.45l.04-.078a.826.826 0 00-.024-.77c-.043-.081-.781-1.866-.86-2.054-.19-.449-.437-.66-.785-.66l-.136.004c-.164.008-1.059.125-1.453.375-.422.266-1.13 1.11-1.13 2.59 0 1.336.844 2.597 1.212 3.074.007.012.023.04.046.074 1.395 2.031 3.13 3.54 4.891 4.242 1.695.68 2.496.758 2.953.758.192 0 .344-.015.48-.031l.087-.008c.586-.05 1.87-.719 2.164-1.531.23-.64.289-1.34.136-1.594-.105-.172-.285-.258-.515-.37zm0 0"></path>
                <path
                    d="M12.215 0C5.715 0 .425 5.25.425 11.7c0 2.085.56 4.128 1.618 5.917L.016 23.59a.315.315 0 00.296.41.263.263 0 00.094-.016l6.227-1.976c1.703.91 3.633 1.39 5.582 1.39C18.71 23.398 24 18.148 24 11.7S18.71 0 12.215 0zm0 20.96a9.36 9.36 0 01-5.14-1.53.296.296 0 00-.173-.051.385.385 0 00-.093.012l-3.118.992 1.004-2.969a.299.299 0 00-.043-.285 9.152 9.152 0 01-1.777-5.43c0-5.105 4.191-9.261 9.34-9.261 5.144 0 9.336 4.156 9.336 9.261 0 5.106-4.192 9.262-9.336 9.262zm0 0"></path>
            </g>
        </svg>
    );
}