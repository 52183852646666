import { createSlice } from "@reduxjs/toolkit";

/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 4/20/2022
 * Time: 7:59 AM
 * Project: w_crm
 */

const initialNavState = {
  actions: [],
  isEnabled: true,
};
export const navSlice = createSlice({
  name: "navigation",
  initialState: {
    ...initialNavState,
  },
  reducers: {
    setNavAction: (state, action) => {
      state.actions = action.payload;
    },
    disableNavAction: (state) => {
      state.isEnabled = false;
    },
    resetNavAction: () => initialNavState,
  },
});

export const { setNavAction, resetNavAction, disableNavAction } =
  navSlice.actions;
export const navState = (state) => state.navigation;
export default navSlice.reducer;
