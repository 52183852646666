/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 5/18/2022
 * Time: 1:21 PM
 * Project: w_crm
 */
import React from "react";
import "./style.css";

export default function ListEmptyPlaceHolder({icon: Icon, text, action}) {
    return (
        <div className={"emptyLostHolder"}>
            <Icon className={"w-4"} strokeWidth={1}/>
            <h1 className="text-default text-bold">{text}</h1>
            {action &&
                <div className={"pt-2"}>
                    {action}
                </div>
            }
        </div>
    );
}
